import * as yup from 'yup';
import { StringSchema } from 'yup';
import { FieldError, UseFormMethods } from 'react-hook-form';
import { parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
// Constants
import { WEB_URL_REGEXP } from 'constants/auth';
// Helpers
import { parseToPlainText } from 'helpers/textEditor';

type FormErrorsAndState<T> = Pick<UseFormMethods<T>, 'errors' | 'formState'>;

interface StringRequiredValidation {
  maxChars?: number;
  requiredLabel?: string;
}

const DEFAULT_MAX_CHARS_LENGTH = 256;

export const getMaxLengthErrMessage = (
  fieldName: string,
  maxChars = DEFAULT_MAX_CHARS_LENGTH
): string => `"${fieldName}" field is too long (max ${maxChars} char(s))`;

export const yupStringValidation = (fieldName: string): StringSchema => {
  return yup.string().max(256, getMaxLengthErrMessage(fieldName));
};

export const yupStringRequiredValidation = (
  fieldName: string,
  options?: StringRequiredValidation
): StringSchema => {
  const {
    maxChars = DEFAULT_MAX_CHARS_LENGTH,
    requiredLabel = `"${fieldName}" is required field.`,
  } = options || {};

  return yup
    .string()
    .trim() // ensuring that strings with only whitespaces are considered empty
    .max(maxChars, getMaxLengthErrMessage(fieldName, maxChars))
    .required(requiredLabel);
};

export const yupUrlStringValidation = (
  fieldName: string,
  options?: StringRequiredValidation
): StringSchema => {
  const { maxChars = DEFAULT_MAX_CHARS_LENGTH } = options || {};

  return yup
    .string()
    .test(
      'if-valid-url',
      `A "${fieldName}" link is badly formed or contains invalid characters.`,
      (val) => (val ? WEB_URL_REGEXP.test(val) : true)
    )
    .max(maxChars, getMaxLengthErrMessage(fieldName));
};

export const yupEditorStringRequiredValidation = (
  fieldName: string,
  requiredLabel?: string
): StringSchema => {
  return yup
    .string()
    .test('notEmpty', `"${fieldName}" is required field.`, (value) => {
      const parsedText = parseToPlainText(value);

      return !!parsedText;
    })
    .required(requiredLabel || `"${fieldName}" is required field.`);
};

export const yupEmailValidation = (): StringSchema => {
  return yup
    .string()
    .max(256, getMaxLengthErrMessage('Email'))
    .email('You should provide valid email address');
};

export const yupEmailRequiredValidation = (
  requiredLabel?: string
): StringSchema => {
  return yup
    .string()
    .trim()
    .max(256, getMaxLengthErrMessage('Email'))
    .email('You should provide valid email address')
    .required(requiredLabel || '"Email" is required field.');
};

export const yupInternationalPhoneValidation = (
  isOptional = false
): StringSchema => {
  if (isOptional) {
    return yup.string().test('phone', 'Invalid phone number', (value) => {
      if (!value) {
        return true;
      }

      const validate = validatePhoneNumberLength(value || '');
      if (validate === undefined) {
        const validatePhoneNumberResult = parsePhoneNumber(value || '');

        if (validatePhoneNumberResult && validatePhoneNumberResult.isValid()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

  return yup
    .string()
    .test('phone', 'Invalid phone number', (value) => {
      // check below will leave this test but right after go into the required() test which will display the message for empty field
      if (!value) {
        return true;
      }

      // function below returns a value if there is an issue(such as "TOO_SHORT") and returns undefined if there is no issue length-wise
      const validate = validatePhoneNumberLength(value || '');
      if (validate === undefined) {
        // function below returns a phone number object that contains all the number's information
        const validatePhoneNumberResult = parsePhoneNumber(value || '');

        // check below returns validity of the number tested
        if (validatePhoneNumberResult && validatePhoneNumberResult.isValid()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
    .required('"Phone number" is a required field.');
};

export const isReady = <T>(
  name: keyof T,
  { errors, formState }: FormErrorsAndState<T>
): boolean => {
  const fieldError = errors?.[name] as FieldError;

  return Boolean(formState.dirtyFields[name] && !fieldError?.message);
};

export const createEditStreamValidationSchema = (isEdit?: boolean) => {
  return yup.object().shape({
    scheduleDate: yup
      .string()
      .typeError('Date is required')
      .required('Date is required'),
    name: yup
      .string()
      .max(200, 'Max 200 characters')
      .required('Title is required'),
    description: yup
      .string()
      .max(400, 'Max 400 characters')
      .required('Description is required'),
    timeZone: yup
      .object()
      .shape({
        label: yup.string(),
        tzCode: yup.string(),
        offset: yup.number(),
      })
      .typeError('Time Zone is required')
      .required('Time Zone is required'),
    isHidden: yup.boolean(),
    previewImage: yup.string().required('Poster is required'),
    image: yup.mixed(),
    ...(!isEdit && {
      isFree: yup.boolean(),
      requestedPrice: yup.string().when(['isFree'], {
        is: false,
        then: yup
          .string()
          .required('Price is required')
          .matches(
            /^(?:0\.\d{0,2}[1-9]|(?!0)\d{1,}(?:\.\d{0,2}[0-9])?)$/,
            'Only numbers are allowed'
          ),
        otherwise: yup.string(),
      }),
    }),
  });
};
