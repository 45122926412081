import React, { Dispatch, SetStateAction } from 'react';
import cn from 'classnames';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Button from 'ui3/Button/Button';
// Components
import { UploadImage } from '../../UploadMultipleImages';
// Styles
import styles from './ImageItem.module.scss';

type ImageItemProps = {
  index: number;
  image: UploadImage;
  setMainImageIndex: Dispatch<SetStateAction<number>>;
  mainImageIndex: number | null;
  onImageRemove: (index: number) => void;
};

const ImageItem = ({
  index,
  image,
  setMainImageIndex,
  mainImageIndex,
  onImageRemove,
}: ImageItemProps): JSX.Element => {
  const isMainButton = mainImageIndex === index;

  const handleSetMain = () => {
    if (!isMainButton) {
      setMainImageIndex(index);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.imageFrame}>
        <img
          src={image['data_url']}
          alt={`item-${index}`}
          className={styles.image}
        />

        <button
          className={styles.removeButton}
          onClick={() => onImageRemove(index)}
        >
          <MillionsIcon name="trash" size={28} />
        </button>
        <p className={cn({ [styles.isMainText]: isMainButton })}>
          {isMainButton ? 'main image' : ''}
        </p>
      </div>
      <div className={styles.setMainButtonContainer}>
        <Button disabled={isMainButton} onClick={handleSetMain} size="small">
          Set as main
        </Button>
      </div>
    </div>
  );
};

export default ImageItem;
