import cn from 'classnames';
import Link from 'next/link';
// Ui
import MillionsIcon, { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
import Loader from 'ui3/Loader/Loader';
// Styles
import styles from './LinkButton.module.scss';

export type ButtonVariant = 'primary' | 'secondary' | 'success' | 'error';
export type ButtonSize = 'large' | 'medium' | 'small';
export type ButtonIcon = IconNames;

export type BasicLinkButtonProps = {
  loading?: boolean;
  size?: ButtonSize;
  subVariant?: 'outlined' | 'text';
  variant?: ButtonVariant;
  icon?: ButtonIcon;
  link: string;
  iconPosition?: 'left' | 'right';
  iconSize?: number;
};

export type LinkButtonProps = React.ComponentPropsWithoutRef<'a'> &
  BasicLinkButtonProps;

const LinkButton = ({
  children,
  variant = 'primary',
  subVariant,
  size = 'large',
  loading,
  className,
  icon,
  link,
  iconPosition = 'left',
  iconSize = 24,
  ...rest
}: LinkButtonProps) => {
  const isInternalLink = link.startsWith('/');
  const prefixIcon = iconPosition === 'left';

  return isInternalLink ? (
    <Link href={link}>
      <a
        className={cn(
          styles.button,
          styles[variant],
          {
            [styles.outlined]: subVariant === 'outlined',
            [styles.text]: subVariant === 'text',
          },
          styles[size],
          className
        )}
        {...rest}
      >
        <span className={styles.buttonContent}>
          {icon && prefixIcon && (
            <MillionsIcon name={icon} className={styles.icon} size={iconSize} />
          )}
          {children && <>{children}</>}
          {icon && !prefixIcon && (
            <MillionsIcon name={icon} className={styles.icon} size={iconSize} />
          )}
        </span>
      </a>
    </Link>
  ) : (
    <a
      href={link}
      className={cn(
        styles.button,
        styles[variant],
        {
          [styles.outlined]: subVariant === 'outlined',
          [styles.text]: subVariant === 'text',
        },
        styles[size],
        className
      )}
      {...rest}
    >
      {loading && <Loader loading={loading} size="small" />}

      <span className={styles.buttonContent}>
        {icon && prefixIcon && (
          <MillionsIcon name={icon} className={styles.icon} size={iconSize} />
        )}
        {children && <>{children}</>}
        {icon && !prefixIcon && (
          <MillionsIcon name={icon} className={styles.icon} size={iconSize} />
        )}
      </span>
    </a>
  );
};

export default LinkButton;
