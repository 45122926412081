import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import router from 'next/router';
// Api
import { DESIGN_COLOR_PALETTES, DESIGN_TYPES } from 'api/designLab/queries';
import {
  CREATE_MERCH_DESIGN,
  CREATE_MERCH_DESIGN_WITH_LOGO,
  CREATE_PRESIGNED_URL_FOR_EXAMPLE_IMAGES,
} from 'api/designLab/mutations';
// Types
import {
  DesignRequestType,
  DesignStyleSettingsInput,
  MerchType,
} from 'api/graphql-global-types';
import {
  DesignTypes,
  DesignTypes_designTypes_designSamples,
} from 'api/designLab/types/DesignTypes';
import { DesignColorPalettes } from 'api/designLab/types/DesignColorPalettes';
import {
  CreateMerchDesign,
  CreateMerchDesignVariables,
} from 'api/designLab/types/CreateMerchDesign';
import {
  CreateMerchDesignWithLogo,
  CreateMerchDesignWithLogoVariables,
} from 'api/designLab/types/CreateMerchDesignWithLogo';
import {
  CreatePresignedUrlForExampleImages,
  CreatePresignedUrlForExampleImages_createPresignedUrlForExampleImages,
  CreatePresignedUrlForExampleImagesVariables,
} from 'api/designLab/types/CreatePresignedUrlForExampleImages';
// Helpers
import { ImageToUpload } from 'helpers/mediaPost';
// Hooks
import { useOnboardingContext } from 'hooks';
// Constants
import { DASHBOARD } from 'constants/routes';
// Components
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import { showToast } from 'components/common/Toast/Toast';
import TypesOfRequestForm from 'components/DesignLab/DLConstructorMerch/TypesOfRequestForm/TypesOfRequestForm';
import TypesOfLogoForm from 'components/DesignLab/DLConstructorMerch/TypesOfLogoForm/TypesOfLogoForm';
import PreferredDesignsForm from 'components/DesignLab/DLConstructorMerch/PreferredDesignsForm/PreferredDesignsForm';
import DetailsForm from 'components/DesignLab/DLConstructorMerch/DetailsForm/DetailsForm';
import PreferredColorsForm from 'components/DesignLab/DLConstructorMerch/PreferredColorsForm/PreferredColorsForm';
import ExtraInformationForm from 'components/DesignLab/DLConstructorMerch/ExtraInformationForm/ExtraInformationForm';
import DLMerchRequestSuccess from 'components/DesignLab/DLConstructorMerch/DLMerchRequestSuccess/DLMerchRequestSuccess';

export type Details = Omit<DesignStyleSettingsInput, 'id'>;

type DLValues = {
  typesOfLogo: number[];
  designs: number[];
  details: Details;
  colors: number[];
  designCustomColor: string;
  designRequestType: string;
  extra: Partial<{
    name: string;
    extraInfo: string;
    socialMediaURL: string;
    storeExampleImages?: (FileWithPreview | string)[] | null;
    merchTypes: MerchType[] | null;
    storeLogos?: (FileWithPreview | string)[] | null;
  }>;
};

export type DLStateValues = Partial<DLValues>;

type RequestMerchSetupProps = {
  onClose?: () => void;
};

const RequestMerchSetup = ({ onClose }: RequestMerchSetupProps) => {
  const {
    showRequestMerchModal,
    setEcommerceSetupDone,
    setRequestMerchDone,
    setShowRequestMerchModal,
  } = useOnboardingContext();

  const [step, setStep] = useState<number>(1);
  const [values, setValues] = useState<DLValues>({
    typesOfLogo: [],
    designs: [],
    details: {
      playfulSophisticated: 3,
      matureYouthful: 3,
      classicModern: 3,
      feminineMasculine: 3,
      geometricOrganic: 3,
      abstractLiteral: 3,
      economicalLuxurious: 3,
    },
    colors: [],
    designCustomColor: '',
    designRequestType: DesignRequestType.MerchCreation,
    extra: {
      name: '',
      extraInfo: '',
      socialMediaURL: '',
      storeExampleImages: [],
      storeLogos: [],
      merchTypes: [],
    },
  });

  const {
    typesOfLogo,
    designs,
    details,
    colors,
    designCustomColor,
    designRequestType,
    extra,
  } = values;

  const isLogoDesignRequest =
    designRequestType === DesignRequestType.MerchCreationWithLogo;

  const { data: designTypesData } = useQuery<DesignTypes>(DESIGN_TYPES, {
    fetchPolicy: 'cache-and-network',
    skip: typeof window === 'undefined',
  });

  const { data: designColorPalettesData } = useQuery<DesignColorPalettes>(
    DESIGN_COLOR_PALETTES,
    {
      fetchPolicy: 'cache-and-network',
      skip: typeof window === 'undefined',
    }
  );

  const [createMerchDesign, { loading: createRequestLoading }] = useMutation<
    CreateMerchDesign,
    CreateMerchDesignVariables
  >(CREATE_MERCH_DESIGN);

  const [
    createMerchDesignWithLogo,
    { loading: createRequestnWithLogoLoading },
  ] = useMutation<
    CreateMerchDesignWithLogo,
    CreateMerchDesignWithLogoVariables
  >(CREATE_MERCH_DESIGN_WITH_LOGO);

  const [createPresignedUrlForExampleImages] = useMutation<
    CreatePresignedUrlForExampleImages,
    CreatePresignedUrlForExampleImagesVariables
  >(CREATE_PRESIGNED_URL_FOR_EXAMPLE_IMAGES);

  const designTypes = designTypesData?.designTypes || [];
  const selectedDesignTypes = designTypes.filter((item) =>
    typesOfLogo?.includes(item.id)
  );
  const designSamples = selectedDesignTypes.reduce((acc, value) => {
    if (value.designSamples) {
      return acc.concat(value.designSamples);
    }

    return acc;
  }, [] as DesignTypes_designTypes_designSamples[]);
  const designColorPalettes =
    designColorPalettesData?.designColorPalettes || [];

  const getImagesToUpload = (
    preSignedUrls: CreatePresignedUrlForExampleImages_createPresignedUrlForExampleImages[],
    images: File[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach((file, index) => {
      const preSignedUrl = preSignedUrls?.[index];
      const imageExtension = file
        ? file.name.split('.')[file.name.split('.').length - 1]
        : '';

      const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

      res.push({
        fields: preSignedUrl?.fields || '',
        url: preSignedUrl?.url || '',
        name: imageName,
        type: file?.type || '',
        file: file || '',
      });
    });

    return res;
  };

  const uploadImages = useCallback(
    async (
      preSignedUrls: CreatePresignedUrlForExampleImages_createPresignedUrlForExampleImages[],
      images: File[]
    ): Promise<string[]> => {
      const imagesToUpload = getImagesToUpload(preSignedUrls, images);

      for (const image of imagesToUpload) {
        const { fields, url, name, type, file } = image;
        const formData = new FormData();

        Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
          formData.append(key, value as string);
        });

        formData.append('key', name);
        formData.append('Content-Type', type);
        formData.append('file', file);

        await fetch(url, {
          method: 'POST',
          body: formData,
        });
      }

      return imagesToUpload.map(({ name }) => name);
    },
    []
  );

  const createLogoRequest = useCallback(async () => {
    try {
      if (isLogoDesignRequest) {
        const {
          data: preSignedUrlsData,
        } = await createPresignedUrlForExampleImages({
          variables: {
            input: {
              numberOfImages: extra?.storeLogos?.length || 0,
            },
          },
        });

        const preSignedUrls =
          preSignedUrlsData?.createPresignedUrlForExampleImages || [];

        const imagesUrls = await uploadImages(
          preSignedUrls,
          extra.storeLogos as any[]
        );

        try {
          await createMerchDesignWithLogo({
            variables: {
              input: {
                merchTypes: extra.merchTypes || [],
                designExtraInfo: extra.extraInfo,
                socialMediaURL: extra.socialMediaURL,
                storeLogos: imagesUrls.map((item) => ({
                  key: item,
                })),
                storeExampleImages: [],
              },
            },
          });

          showToast({
            message: 'Request successfully created',
          });

          setStep(7);
        } catch (error) {
          showToast({
            message: error?.message,
            type: 'error',
          });
        }
      } else {
        const {
          data: preSignedUrlsData,
        } = await createPresignedUrlForExampleImages({
          variables: {
            input: {
              numberOfImages: extra?.storeExampleImages?.length || 0,
            },
          },
        });

        const preSignedUrls =
          preSignedUrlsData?.createPresignedUrlForExampleImages || [];
        const imagesUrls = await uploadImages(
          preSignedUrls,
          extra.storeExampleImages as any[]
        );

        try {
          await createMerchDesign({
            variables: {
              input: {
                designColorPaletteIds: colors,
                designName: extra.name || '',
                designSampleIds: designs,
                designStyleSettingsInput: details,
                designTypeIds: typesOfLogo,
                merchTypes: extra.merchTypes || [],
                designExtraInfo: extra.extraInfo,
                designCustomColor: designCustomColor || null,
                socialMediaURL: extra.socialMediaURL,
                storeExampleImages: imagesUrls.map((item) => ({
                  key: item,
                })),
              },
            },
          });

          showToast({
            message: 'Request successfully created',
          });

          setStep(7);
        } catch (error) {
          showToast({
            message: error?.message,
            type: 'error',
          });
        }
      }
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  }, [
    colors,
    createMerchDesign,
    createMerchDesignWithLogo,
    createPresignedUrlForExampleImages,
    designCustomColor,
    designs,
    details,
    extra,
    isLogoDesignRequest,
    typesOfLogo,
    uploadImages,
  ]);

  const handleClose = useCallback(() => {
    setShowRequestMerchModal(false);

    if (onClose) {
      onClose();
    }
  }, [setShowRequestMerchModal, onClose]);

  const handleFinish = useCallback(() => {
    handleClose();
    setRequestMerchDone(true);
    setEcommerceSetupDone(true);

    router.push(DASHBOARD);
  }, [handleClose, setEcommerceSetupDone, setRequestMerchDone]);

  const handleNextStepClick = useCallback(() => {
    if (step === 1 && isLogoDesignRequest) {
      setStep((prev) => prev + 4);
    }
    // keep only available designs
    if (step === 2) {
      const onlyAvailableDesigns = designs.filter((item) =>
        designSamples.some((design) => design.id === item)
      );

      setValues((prev) => ({
        ...prev,
        designs: onlyAvailableDesigns,
      }));
    }

    if (step < 6) {
      setStep((prev) => prev + 1);
      window.scrollTo({ top: 0, left: 0 });
    }

    if (step === 6) {
      createLogoRequest();
    }

    if (step === 7) {
      handleFinish();
    }
  }, [
    createLogoRequest,
    designSamples,
    designs,
    handleFinish,
    isLogoDesignRequest,
    step,
  ]);

  const handleValuesChange = (values: DLValues) => {
    setValues((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const handlePrevStepClick = useCallback(() => {
    if (
      step === 6 &&
      designRequestType === DesignRequestType.MerchCreationWithLogo
    ) {
      setStep(2);
    }
    if (step === 1) {
      handleClose();
    } else {
      setStep((prev) => prev - 1);
    }
    window.scrollTo({ top: 0, left: 0 });
  }, [designRequestType, handleClose, step]);

  const checkIsNextButtonEnabled = (): boolean => {
    switch (step) {
      case 1: {
        return !!designRequestType;
      }
      case 2: {
        return typesOfLogo.length > 0;
      }
      case 3: {
        return designs.length > 0;
      }
      case 4: {
        return !!Object.keys(details).length;
      }
      case 5: {
        return Boolean(designCustomColor || colors.length);
      }
      case 6: {
        console.log('extra', extra);
        console.log('isLogoDesignRequest', isLogoDesignRequest);
        return Boolean(
          isLogoDesignRequest
            ? extra.merchTypes?.length && extra.storeLogos?.length
            : extra.name && extra.merchTypes?.length
        );
      }
      default: {
        return true;
      }
    }
  };

  const isNextButtonDisabled = !checkIsNextButtonEnabled();
  const loading = createRequestLoading;
  const loadingWithLogo = createRequestnWithLogoLoading;

  const footer: FullScreenStepperModalFooter | undefined = useMemo(() => {
    if (step < 7) {
      return {
        stepNumber: step,
        totalStepsNumber: 6,
        showPrevButton: true,
        prevButtonText: 'Back',
        prevButtonProps: {
          onClick: handlePrevStepClick,
          variant: 'secondary',
        },
        submitButtonText: step < 7 ? 'Next' : 'Back to Dashboard',
        submitButtonProps: {
          onClick: handleNextStepClick,
          variant: 'primary',
          disabled: isNextButtonDisabled,
          loading: loading || loadingWithLogo,
        },
      };
    }

    return {
      showPrevButton: false,
      submitButtonText: 'Back to Dashboard',
      submitButtonProps: {
        onClick: handleFinish,
        variant: 'primary',
      },
    };
  }, [
    step,
    handleFinish,
    handlePrevStepClick,
    handleNextStepClick,
    isNextButtonDisabled,
    loading,
    loadingWithLogo,
  ]);

  return (
    <FullScreenStepperModal
      title="REQUEST MERCH"
      open={showRequestMerchModal}
      onClose={handleClose}
      footer={footer}
    >
      <>
        {step === 1 && (
          <TypesOfRequestForm
            onChange={handleValuesChange}
            selectedValue={designRequestType}
            step={step}
          />
        )}

        {step === 2 && (
          <TypesOfLogoForm
            step={step}
            onChange={handleValuesChange}
            options={designTypes}
            selectedValues={typesOfLogo}
          />
        )}

        {step === 3 && (
          <PreferredDesignsForm
            step={step}
            onChange={handleValuesChange}
            options={designSamples}
            selectedValues={designs}
          />
        )}

        {step === 4 && (
          <DetailsForm
            step={step}
            onChange={handleValuesChange}
            selectedValues={details}
          />
        )}

        {step === 5 && (
          <PreferredColorsForm
            step={step}
            onChange={handleValuesChange}
            options={designColorPalettes}
            selectedValues={colors}
            designCustomColor={designCustomColor}
          />
        )}

        {step === 6 && (
          <ExtraInformationForm
            step={step}
            onChange={handleValuesChange}
            selectedValues={extra}
            editMode={false}
            isLogoDesignRequest={isLogoDesignRequest}
          />
        )}

        {step === 7 && <DLMerchRequestSuccess />}
      </>
    </FullScreenStepperModal>
  );
};

export default RequestMerchSetup;
