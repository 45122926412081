import { useCallback } from 'react';
// Hooks
import { useOnboardingContext } from 'hooks';
// Components
import FullScreenStepperModal from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import EcommerceInitialStep from './EcommerceInitialStep/EcommerceInitialStep';
import RequestMerchSetup from './RequestMerchSetup/RequestMerchSetup';
import MemorabiliaSetup from './MemorabiliaSetup/MemorabiliaSetup';
import ExperienceSetup from './ExperienceSetup/ExperienceSetup';

const EcommerceSetup = () => {
  const {
    showEcommerceSetupModal,
    showRequestMerchModal,
    showCreateProductMemorabiliaModal,
    showCreateExperienceModal,
    setShowEcommerceSetupModal,
  } = useOnboardingContext();

  const handleClose = useCallback(() => {
    setShowEcommerceSetupModal(false);
  }, [setShowEcommerceSetupModal]);

  return (
    <FullScreenStepperModal
      title="CREATE ON MILLIONS"
      open={showEcommerceSetupModal}
      onClose={handleClose}
    >
      <>
        <EcommerceInitialStep />

        {showRequestMerchModal && <RequestMerchSetup />}

        {showCreateProductMemorabiliaModal && <MemorabiliaSetup />}

        {showCreateExperienceModal && <ExperienceSetup />}
      </>
    </FullScreenStepperModal>
  );
};

export default EcommerceSetup;
