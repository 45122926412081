import { ReactNode } from 'react';
import cn from 'classnames';
import Link from 'next/link';
// Types
import { CardSize } from '../BaseCard/BaseCard';
// UI
import Text, { TextVariants } from 'ui3/Text/Text';
// Styles
import styles from './CardDescription.module.scss';

export type BasicCardDescriptionProps = {
  title?: string;
  user?: string;
  date?: string;
  size?: CardSize;
  userUrl?: string;
  titleUrl?: string;
  showTitle?: boolean;
  showUser?: boolean;
  showDate?: boolean;
  userLineClamp?: number;
  //showAvatar?: boolean; // TODO: check if this is needed
};

export type CardDescriptionProps = React.ComponentPropsWithoutRef<'p'> &
  BasicCardDescriptionProps;

const sizeMap = {
  small: {
    title: 'subtitle3',
    user: 'body3Regular',
    date: 'body3Regular',
  },
  medium: {
    title: 'subtitle2',
    user: 'body2Regular',
    date: 'body3Regular',
  },
  large: {
    title: 'subtitle1',
    user: 'body1Regular16',
    date: 'body2Regular',
  },
};

const WithLink = ({ link, children }: { link?: string; children: ReactNode }) =>
  link ? (
    <Link href={link} prefetch={false}>
      <a href={link}>{children}</a>
    </Link>
  ) : (
    <>{children}</>
  );

const CardDescription: React.FC<CardDescriptionProps> = ({
  size,
  title,
  user,
  date,
  userUrl,
  titleUrl,
  showTitle = true,
  showUser = true,
  showDate = true,
  userLineClamp = 2,
  className,
  ...rest
}) => {
  const sizeClass = size ?? 'large';
  const userLineClampClass = `lineClamp${userLineClamp}` as keyof typeof styles;

  return (
    <div className={cn(styles.root)}>
      {showTitle && title ? (
        <WithLink link={titleUrl}>
          <Text
            className={cn(styles.overflow, className, {
              [styles.title]: !size,
            })}
            variant={size ? (sizeMap[sizeClass].title as TextVariants) : null}
            title={title}
            {...rest}
          >
            {title}
          </Text>
        </WithLink>
      ) : null}
      {showUser && user ? (
        <WithLink link={userUrl}>
          <Text
            color="lights-low"
            className={cn(
              styles.overflow,
              styles[userLineClampClass],
              className,
              {
                [styles.user]: !size,
              }
            )}
            variant={size ? (sizeMap[sizeClass].user as TextVariants) : null}
            title={user}
            {...rest}
          >
            {user}
          </Text>
        </WithLink>
      ) : null}
      {showDate && date ? (
        <Text
          color="lights-low"
          className={cn(styles.overflow, className, {
            [styles.date]: !size,
          })}
          variant={size ? (sizeMap[sizeClass].date as TextVariants) : null}
          title={date}
          {...rest}
        >
          {date}
        </Text>
      ) : null}
    </div>
  );
};

export default CardDescription;
