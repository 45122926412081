import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import cn from 'classnames';
// Api
import {
  GET_ALL_AVAILABLE_SCHEDULE_SLOTS,
  GET_AVAILABLE_INTERVIEWERS,
  GET_AVAILABLE_SCHEDULE_SLOTS,
} from 'api/interview/queries';
import { GET_INTERVIEW_AVAILABLE_SLOTS } from 'api/schedule/queries';
// Types
import {
  GetAvailableScheduleSlots,
  GetAvailableScheduleSlotsVariables,
} from 'api/interview/types/GetAvailableScheduleSlots';
import {
  getAvailableInterviewers,
  getAvailableInterviewersVariables,
} from 'api/interview/types/getAvailableInterviewers';
import {
  GetAllAvailableScheduleSlots,
  GetAllAvailableScheduleSlotsVariables,
} from 'api/interview/types/GetAllAvailableScheduleSlots';
import {
  GetInterviewAvailableSlots,
  GetInterviewAvailableSlotsVariables,
} from 'api/schedule/types/GetInterviewAvailableSlots';
import { InterviewSourceType } from 'api/graphql-global-types';
import { Interviewer } from 'components/UserScheduleInterview/helpers/localTypes';
// Helpers
import {
  getExcludedDates,
  getStartEndOfMonth,
} from 'helpers/scheduleInterview';
// Hooks
import { useCurrentMutableStateRef } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
import TextArea from 'ui3/TextArea/TextArea';
import DatePicker from 'ui3/DatePicker/DatePicker';
import Loader from 'ui3/Loader/Loader';
// Components
import { showToast } from 'components/common/Toast/Toast';
import { ScheduleInterviewFormValues } from 'components/UserScheduleInterview/UserScheduleInterviewV2';
// Styles
import styles from './CalendarForm.module.scss';

export interface CommonSlot {
  __typename: string;
  startTs: string;
  endTs: string;
  userId?: string | null;
}

type CalendarStepFormProps = {
  interviewId: string | null;
  hostId: string | null;
  loggedUserId: string | null;
  onInterviewerChange?: (interviewer: Interviewer) => void;
};

const CalendarStepForm = ({
  interviewId,
  hostId,
  loggedUserId,
  onInterviewerChange,
}: CalendarStepFormProps) => {
  const {
    control,
    errors,
    register,
    watch,
    setValue,
    setError,
  } = useFormContext<ScheduleInterviewFormValues>();

  const minCalendarDateRef = useRef(dayjs().startOf('day').toDate());
  const [filteredTimeSlots, setFilteredTimeSlots] = useState<CommonSlot[]>([]);

  const [
    getAvailableScheduleSlots,
    { data: scheduleSlotsData, loading: loadingAvailableScheduleSlots },
  ] = useLazyQuery<
    GetAvailableScheduleSlots,
    GetAvailableScheduleSlotsVariables
  >(GET_AVAILABLE_SCHEDULE_SLOTS, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getAllAvailableScheduleSlots,
    { data: allScheduleSlotsData, loading: loadingAllScheduleSlots },
  ] = useLazyQuery<
    GetAllAvailableScheduleSlots,
    GetAllAvailableScheduleSlotsVariables
  >(GET_ALL_AVAILABLE_SCHEDULE_SLOTS, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getInterviewAvailableSlots,
    { data: interviewAvailableSlotsData, loading: loadingInterviewSlots },
  ] = useLazyQuery<
    GetInterviewAvailableSlots,
    GetInterviewAvailableSlotsVariables
  >(GET_INTERVIEW_AVAILABLE_SLOTS, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getAvailableInterviewers,
    { loading: loadingAvailableInterviewers },
  ] = useLazyQuery<getAvailableInterviewers, getAvailableInterviewersVariables>(
    GET_AVAILABLE_INTERVIEWERS
  );

  const selectedDate = watch('selectedDate');
  const selectedTimeSlot = watch('selectedTimeSlot');
  const selectedDateRef = useCurrentMutableStateRef(selectedDate);

  const selectedMonthYear = useMemo(
    () => `${dayjs(selectedDate).month()}.${dayjs(selectedDate).year()}`,
    [selectedDate]
  );

  const handleGetAvailableTimeSlots = useCallback(async () => {
    const startEnd = getStartEndOfMonth(selectedDateRef.current);

    try {
      if (!interviewId) {
        if (!hostId) {
          const {
            data: interviewersData,
            error: interviewersError,
          } = await getAvailableInterviewers({
            variables: {
              input: {
                source: InterviewSourceType.UserRequest,
              },
            },
          });
          if (interviewersData?.getAvailableInterviewers) {
            await getAllAvailableScheduleSlots({
              variables: {
                input: {
                  userIds: interviewersData?.getAvailableInterviewers.map(
                    (interviewer) => interviewer.id
                  ),
                  dateRange: {
                    from: startEnd.start.utc().toDate(),
                    to: startEnd.end.utc().toDate(),
                  },
                },
              },
            });
          } else {
            throw new Error(
              interviewersError?.message || 'No available interviewers found'
            );
          }
        } else {
          await getAvailableScheduleSlots({
            variables: {
              input: {
                userId: hostId,
                dateRange: {
                  from: startEnd.start.utc().toDate(),
                  to: startEnd.end.utc().toDate(),
                },
              },
            },
          });
        }
      } else {
        const { data: interviewSlotsData } = await getInterviewAvailableSlots({
          variables: {
            input: {
              interviewId,
              dateRange: {
                from: startEnd.start.utc().toDate(),
                to: startEnd.end.utc().toDate(),
              },
            },
          },
        });

        if (
          interviewSlotsData?.getInterviewAvailableSlots.schedule &&
          onInterviewerChange
        ) {
          onInterviewerChange({
            firstName:
              interviewSlotsData.getInterviewAvailableSlots.schedule.store
                ?.firstName || '',
            lastName:
              interviewSlotsData.getInterviewAvailableSlots.schedule.store
                ?.lastName || '',
            storeDetails: {
              avatarURL:
                interviewSlotsData.getInterviewAvailableSlots.schedule.store
                  .storeDetails?.avatarURL,
            },
          } as Interviewer);
        }
      }
    } catch (error) {
      showToast({
        message: error?.message || 'An error occurred',
        type: 'error',
      });
    }
  }, [
    selectedDateRef,
    interviewId,
    hostId,
    getAvailableInterviewers,
    getAllAvailableScheduleSlots,
    getAvailableScheduleSlots,
    getInterviewAvailableSlots,
    onInterviewerChange,
  ]);

  const handleSelectTimeSlotChange = (slot: CommonSlot) => {
    setValue('selectedTimeSlot', slot.startTs);
    setError('selectedTimeSlot', {
      type: 'manual',
      message: '',
    });

    if (slot.userId) {
      setValue('selectedUserId', slot.userId);
    }
  };

  useEffect(() => {
    if (selectedMonthYear) {
      handleGetAvailableTimeSlots().catch((e) => console.error(e));
    }
  }, [handleGetAvailableTimeSlots, selectedMonthYear]);

  useEffect(() => {
    const filteredSlots: CommonSlot[] =
      scheduleSlotsData?.getAvailableScheduleSlots?.slots ||
      allScheduleSlotsData?.getAllAvailableScheduleSlots?.slots ||
      interviewAvailableSlotsData?.getInterviewAvailableSlots.slots ||
      [];
    const selectedDateFormatted = dayjs(selectedDate).format('YYYY-MM-DD');

    if (Array.isArray(filteredSlots)) {
      const filtered = filteredSlots.filter((item, index, self) => {
        const date = dayjs(item.startTs).format('YYYY-MM-DD');

        if (date !== selectedDateFormatted) {
          return false;
        }

        const isUniqueStartTs =
          self.findIndex((slot) => slot.startTs === item.startTs) === index;
        const isNotHostSlot = item.userId !== loggedUserId;

        return isUniqueStartTs && isNotHostSlot;
      });

      setFilteredTimeSlots(filtered);
    } else {
      setFilteredTimeSlots([]);
    }

    if (!dayjs(selectedDate).isSame(dayjs(selectedTimeSlot), 'day')) {
      setValue('selectedTimeSlot', '');
    }
  }, [
    selectedDate,
    scheduleSlotsData,
    interviewAvailableSlotsData,
    allScheduleSlotsData,
    selectedTimeSlot,
    setValue,
    loggedUserId,
  ]);

  const excludedDaysWithoutSlots = useMemo(() => {
    return getExcludedDates(
      scheduleSlotsData?.getAvailableScheduleSlots?.slots ||
        allScheduleSlotsData?.getAllAvailableScheduleSlots?.slots ||
        interviewAvailableSlotsData?.getInterviewAvailableSlots.slots ||
        [],
      selectedDateRef.current,
      loggedUserId as string
    );
  }, [
    selectedDateRef,
    scheduleSlotsData,
    interviewAvailableSlotsData,
    allScheduleSlotsData,
    loggedUserId,
  ]);

  const loading =
    loadingAvailableScheduleSlots ||
    loadingAllScheduleSlots ||
    loadingInterviewSlots ||
    loadingAvailableInterviewers;
  const slotStepMinutes =
    scheduleSlotsData?.getAvailableScheduleSlots.schedule?.slotStepMinutes ||
    30;

  const calendarWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <form className={styles.root}>
      <Text variant="h5">Select A Date and Time</Text>

      <div>
        <div className={styles.dateAndTimePickerWrapper}>
          <Controller
            control={control}
            name="selectedDate"
            render={({ onChange, onBlur, value }) => (
              <div ref={calendarWrapperRef}>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  timeIntervals={slotStepMinutes}
                  calendarClassName={styles.calendar}
                  onBlur={onBlur}
                  selected={value}
                  onChange={onChange}
                  onMonthChange={onChange}
                  onYearChange={onChange}
                  minDate={minCalendarDateRef.current}
                  placeholderText="MM/DD/YYYY"
                  dateFormat="MMMM d, yyyy"
                  excludeDates={excludedDaysWithoutSlots}
                  inline
                />
              </div>
            )}
          />

          <div
            className={styles.slotRoot}
            style={{
              height: Number(calendarWrapperRef.current?.clientHeight) - 4,
            }}
          >
            <Text variant="body1Regular16">
              {dayjs(selectedDate).format('dddd, MMMM, DD')}
            </Text>

            <div className={styles.timeSlotsContainer}>
              {loading ? (
                <div className={styles.loaderContainer}>
                  <Loader loading size="medium" />
                </div>
              ) : (
                <ul>
                  {filteredTimeSlots.length ? (
                    filteredTimeSlots.map((item) => {
                      const formattedDate = dayjs(item.startTs).format('HH:mm');
                      const isSelected = selectedTimeSlot === item.startTs;

                      return (
                        <li key={item.startTs}>
                          <button
                            type="button"
                            className={cn(styles.timeBtn, {
                              [styles.selectedTimeSlot]: isSelected,
                            })}
                            onClick={() => handleSelectTimeSlotChange(item)}
                          >
                            {formattedDate}
                          </button>
                        </li>
                      );
                    })
                  ) : (
                    <p className={styles.noDateTime}>
                      No time available on this date.
                    </p>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>

        {errors?.selectedTimeSlot?.message && (
          <Text variant="body1Regular16" color="error-default">
            {errors.selectedTimeSlot.message}
          </Text>
        )}
      </div>

      <Text variant="h5">Leave Some Notes For Host</Text>
      <TextArea
        ref={register}
        label="Interview notes"
        name="description"
        placeholder="Your Host can ask you about any cool moments, upcoming events, new merch drops, or big announcements. Just let us know!"
        error={errors?.description?.message}
      />
    </form>
  );
};

export default CalendarStepForm;
