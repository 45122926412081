import { gql } from '@apollo/client';
// Fragments
import { AddressFields } from './fragments';

export const GET_COUNTRY_LIST = gql`
  query GetCountryList {
    getCountryList {
      name
      code
      states {
        name
        code
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries($input: CountryFilterInput) {
    getCountries(input: $input) {
      id
      code
      name
      states {
        name
        code
      }
    }
  }
`;

export const GET_CITIES = gql`
  query GetCities($input: CityFilterInput!) {
    getCities(input: $input) {
      total
      offset
      limit
      entities {
        id
        name
        countryCode
      }
    }
  }
`;

export const GET_MY_ADDRESSES = gql`
  query GetMyAddresses {
    getMyAddresses {
      ...AddressFields
    }
  }
  ${AddressFields}
`;
