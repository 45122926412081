import { useRef } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';

import styles from './Dropdown.module.scss';
import { useState } from 'react';
import { useOutsideClick } from 'hooks';

type DropdownProps = {
  label: string;
  options: {
    label: string;
    url?: string;
    internalLink?: boolean;
    onClick?: () => void;
  }[];
  className?: string;
};

const Dropdown = ({ label, options, className }: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [optionsListIsVisible, setOptionsListIsVisible] = useState<boolean>(
    false
  );

  useOutsideClick(dropdownRef, () => {
    setOptionsListIsVisible(false);
  });

  const toggleList = () => {
    setOptionsListIsVisible((prev) => !prev);
  };

  return (
    <div className={cn(styles.root, className)} ref={dropdownRef}>
      <button className={styles.button} onClick={toggleList}>
        {label} <MillionsIcon name="chevronDown" className={styles.icon} />
      </button>

      <ul
        aria-hidden={!optionsListIsVisible}
        className={cn(styles.optionsList, {
          [styles.visible]: optionsListIsVisible,
        })}
      >
        {options.map((option) => (
          <li key={option.label} className={styles.option}>
            {option.url ? (
              option.internalLink ? (
                <Link href={option.url} passHref>
                  <a>{option.label}</a>
                </Link>
              ) : (
                <a href={option.url}>{option.label}</a>
              )
            ) : (
              <button onClick={option.onClick}>{option.label}</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
