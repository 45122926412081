import { useQuery, QueryResult } from '@apollo/client';
// Api
import { GET_MY_ADDRESSES } from 'api/address/queries';
// Types
import { GetMyAddresses } from 'api/address/types/GetMyAddresses';
// Helpers
import { useAppContext } from './useAppContext';

export const useGetMyAddresses = (): QueryResult<GetMyAddresses> => {
  const { token: contextToken } = useAppContext();
  const skip = !contextToken;

  return useQuery<GetMyAddresses>(GET_MY_ADDRESSES, {
    fetchPolicy: 'cache-and-network',
    skip,
  });
};
