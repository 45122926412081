import { gql } from '@apollo/client';

export const CREATE_EXPERIENCE_REQUEST = gql`
  mutation CreateExperienceRequest($input: ExperienceRequestCreateInput!) {
    createExperienceRequest(input: $input) {
      stripeIntentID
      stripeIntentClientSecret
    }
  }
`;

// The two mutations below are not planned to be added in V1
// But there is working code for them in the components, just uncomment for V2

// export const DELETE_EXPERIENCE = gql`
//   mutation DeleteExperience($input: DeleteExperienceInput!) {
//     deleteExperience(input: $input) {
//       id
//     }
//   }
// `;

// export const RECOVER_EXPERIENCE = gql`
//   mutation RecoverExperience($input: RecoverExperienceInput!) {
//     recoverExperience(input: $input) {
//       id
//     }
//   }
// `;

export const CREATE_STORE_EXPERIENCE = gql`
  mutation CreateStoreExperience($input: CreateStoreExperienceInput!) {
    createStoreExperience(input: $input) {
      createdAt
      hashtags {
        name
        id
      }
      slug
      title
    }
  }
`;

export const UPDATE_STORE_EXPERIENCE = gql`
  mutation UpdateStoreExperience($input: UpdateStoreExperienceInput!) {
    updateStoreExperience(input: $input) {
      createdAt
      hashtags {
        name
        id
      }
      slug
      title
    }
  }
`;

export const DELETE_STORE_EXPERIENCE = gql`
  mutation DeleteStoreExperience($input: DeleteStoreExperienceInput!) {
    deleteStoreExperience(input: $input) {
      createdAt
      hashtags {
        name
        id
      }
      slug
      title
    }
  }
`;

export const RECOVER_STORE_EXPERIENCE = gql`
  mutation RecoverStoreExperience($input: RecoverStoreExperienceInput!) {
    recoverStoreExperience(input: $input) {
      createdAt
      hashtags {
        name
        id
      }
      slug
      title
    }
  }
`;

export const CREATE_EXPERIENCE_PRESIGNED_URL = gql`
  mutation CreateExperienceImagePresignedUrl(
    $input: CreateExperienceImagePresignedUrlInput!
  ) {
    createExperiencePresignedUrl(input: $input) {
      fields
      key
      url
    }
  }
`;
