import cn from 'classnames';
import { isSafari } from 'react-device-detect';
// UI
import Text from 'ui3/Text/Text';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from './UploadImagePlaceholder.module.scss';

type UploadImagePlaceholderProps = {
  title?: string;
  description?: string;
  onClick: () => void;
  error?: boolean;
  disabled?: boolean;
};

const UploadImagePlaceholder = ({
  title,
  description,
  onClick,
  error,
  disabled,
}: UploadImagePlaceholderProps) => {
  return (
    <button
      className={cn(styles.root, {
        [styles.error]: error,
      })}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {!isSafari && (
        <MillionsIcon
          name="plusCircleOutline"
          size={32}
          className={styles.icon}
        />
      )}

      {title && <Text variant="subtitle1">{title}</Text>}

      {description && (
        <Text variant="body3Regular" color="lights-medium">
          {description}
        </Text>
      )}
    </button>
  );
};

export default UploadImagePlaceholder;
