import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers';
import dayjs from 'dayjs';
import router from 'next/router';
// Api
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
import { CREATE_STREAM } from 'api/streams/mutations';
// Types
import {
  CreateStream,
  CreateStream_createStream,
  CreateStreamVariables,
} from 'api/streams/types/CreateStream';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
import { StreamFields } from 'api/streams/fragments';
import { GetStreamExtended_stream_mentions } from 'api/streams/types/GetStreamExtended';
// Constants
import { DASHBOARD } from 'constants/routes';
import { TimeZoneOption } from 'constants/timeZone';
// Helpers
import { getCurrentTimeZoneOffset, getTimeZoneObject } from 'helpers/timeZone';
import {
  formatHashtagInput,
  REPEAT_STREAM_OPTIONS,
  RepeatStreamOption,
} from 'helpers/streams';
import { getProperErrorMessage } from 'helpers/errors';
import { formatMentionsInput } from 'helpers/mentions';
import { createEditStreamValidationSchema } from 'helpers/validation';
// Hooks
import { useOnboardingContext } from 'hooks';
// Components
import FullScreenStepperModal from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import InviteAndPromoteModal from 'components/ProfileSetup/components/InviteAndPromoteModal/InviteAndPromoteModal';
import StreamFormV2 from 'components/ProfileSetup/components/common/StreamFormV2/StreamFormV2';
import { showToast } from 'components/common/Toast/Toast';

export type FormInputs = {
  name: string | null;
  description: string;
  scheduleDate: Date | string;
  timeZone: TimeZoneOption;
  requestedPrice: string;
  isFree: boolean;
  isHidden: boolean;
  image: File | null;
  previewImage: string;
  repeatsEveryEnum: RepeatStreamOption;
  hashtags?: string[];
  mentions?: GetStreamExtended_stream_mentions[] | null;
};

const CreateStreamSetup = () => {
  const {
    showCreateStreamModal,
    setCreateStreamDone,
    setShowCreateStreamModal,
  } = useOnboardingContext();

  const handleClose = () => {
    setShowCreateStreamModal(false);
  };

  const handleSuccess = () => {
    setCreateStreamDone(true);
    handleClose();
    router.push(DASHBOARD);
  };

  const [
    streamData,
    setStreamData,
  ] = useState<CreateStream_createStream | null>(null);
  const [showInviteAthletes, setShowInviteAthletes] = useState(false);
  const defaultTimeZone: TimeZoneOption = getTimeZoneObject(null);

  const defaultValues = {
    name: '',
    description: '',
    scheduleDate: '',
    timeZone: defaultTimeZone,
    repeatsEveryEnum: REPEAT_STREAM_OPTIONS[0],
    requestedPrice: '',
    isFree: false,
    isHidden: false,
    previewImage: '',
    image: null,
    mentions: [],
  };

  const methods = useForm<FormInputs>({
    resolver: yupResolver(createEditStreamValidationSchema(false)),
    defaultValues: defaultValues,
    shouldUnregister: false,
  });

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [createStream, { loading }] = useMutation<
    CreateStream,
    CreateStreamVariables
  >(CREATE_STREAM, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          myStreams(existingStreams = {}) {
            const newStreamRef = cache.writeFragment({
              data: data?.createStream,
              fragment: StreamFields,
            });

            return {
              ...existingStreams,
              entities: [newStreamRef, ...existingStreams.entities],
            };
          },
        },
      });
    },
  });

  const handleFormSubmit = methods.handleSubmit(
    async (values): Promise<void> => {
      const timeZoneOffset = dayjs
        .tz(
          dayjs(values.scheduleDate).format('YYYY-MM-DD HH:mm'),
          values.timeZone.tzName
        )
        .utcOffset();

      const variables = {
        sponsors: [],
        name: values.name,
        repeatingTitle: values.repeatsEveryEnum.value ? values.name : null,
        repeatsEveryEnum: values.repeatsEveryEnum.value,
        description: values.description,
        ...(values.image
          ? {
              image: values.image,
            }
          : {}),
        scheduleDate: dayjs(values.scheduleDate)
          .add(getCurrentTimeZoneOffset(), 'minute')
          .toISOString(),
        timeZone: {
          tzCode: values.timeZone.tzCode,
          offset: timeZoneOffset,
        },
        isPPV: !values.isFree,
      };

      const createUnregisteredContactInput =
        (values.mentions as any)?.filter((mention) => !mention.targetType) ||
        [];

      const unregisterContactsData = await createUnregisteredContact({
        variables: {
          input: createUnregisteredContactInput,
        },
      });

      const newUnregisteredContacts =
        unregisterContactsData.data?.createUnregisteredContact?.map(
          (contact) => ({
            unregisteredContactId: contact.id,
          })
        ) || [];

      const oldMentions =
        values.mentions?.filter((mention) => mention.targetType) || [];
      const formatedOldMentions = formatMentionsInput(oldMentions);

      try {
        const createStreamResponse = await createStream({
          variables: {
            input: {
              isFree: values.isFree,
              isHidden: values.isHidden,
              hashtagInputs: values.hashtags
                ? formatHashtagInput(values.hashtags)
                : [],
              mentionsInputs: [
                ...formatedOldMentions,
                ...newUnregisteredContacts,
              ],
              ...(!values.isFree && { requestedPrice: +values.requestedPrice }),
              ...variables,
              sponsors: [],
            },
          },
        });

        setStreamData(createStreamResponse?.data?.createStream || null);
        showToast({
          message: 'Stream successfully created',
        });
        setShowInviteAthletes(true);
      } catch (error) {
        console.log('error', error);
        showToast({
          message: getProperErrorMessage(
            error,
            `Something went wrong. (${error?.message})`
          ),
          type: 'error',
        });
      }
    }
  );

  return (
    <FullScreenStepperModal
      title="SCHEDULE A STREAM"
      open={showCreateStreamModal}
      onClose={handleClose}
      footer={{
        showPrevButton: false,
        submitButtonText: 'Schedule',
        submitButtonProps: {
          onClick: handleFormSubmit,
          variant: 'primary',
          loading,
        },
      }}
    >
      <FormProvider {...methods}>
        <StreamFormV2 />

        {streamData && showInviteAthletes && (
          <InviteAndPromoteModal
            show={showInviteAthletes}
            stream={streamData}
            onClose={handleSuccess}
          />
        )}
      </FormProvider>
    </FullScreenStepperModal>
  );
};

export default CreateStreamSetup;
