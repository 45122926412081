import { useEffect, useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, Controller } from 'react-hook-form';
import cx from 'classnames';
import dayjs from 'dayjs';
// Hooks
import { useGetCurrUser } from 'hooks';
// Types
import { TimeZoneOption } from 'constants/timeZone';
import { GetStream_stream } from 'api/streams/types/GetStream';
import {
  GetStreamExtended_stream,
  GetStreamExtended_stream_mentions,
} from 'api/streams/types/GetStreamExtended';
// Constants
import { TIME_ZONE_OPTIONS } from 'constants/timeZone';
import { STREAMS } from 'constants/routes';
// Helpers
import { getEnvLink } from 'helpers/routes';
import { downloadImageFromUrl } from 'helpers/download';
import {
  REPEAT_STREAM_OPTIONS,
  RepeatStreamOption,
  getFileName,
} from 'helpers/streams';
// Ui
import Switch from 'ui3/Switch/Switch';
import Input from 'ui3/Input/Input';
import TextArea from 'ui3/TextArea/TextArea';
import Button from 'ui3/Button/Button';
import DatePicker from 'ui3/DatePicker/DatePicker';
import Select from 'ui3/Select/Select';
import CheckBox from 'ui3/CheckBox/CheckBox';
import TagsInput from 'ui3/TagsInput/TagsInput';
import Mentions, { Mention } from 'ui3/Mentions/Mentions';
// Components
import UploadImagePlaceholder from 'components/ProfileSetup/components/common/UploadImagePlaceholder/UploadImagePlaceholder';
import EasyImageCrop from 'components/common3/EasyImageCrop/EasyImageCrop';
import RichText from 'components/common3/RichText/RichText';
import { ToolbarItem } from 'components/common3/RichText/components/Toolbar/Toolbar';
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './StreamFormV2.module.scss';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import Text from 'ui3/Text/Text';

export type FormInputs = {
  name: string | null;
  description: string;
  scheduleDate: Date | string;
  timeZone: TimeZoneOption;
  requestedPrice: string;
  isFree: boolean;
  isHidden: boolean;
  image: File | null;
  previewImage: string;
  repeatsEveryEnum: RepeatStreamOption;
  hashtags?: string[];
  mentions?: GetStreamExtended_stream_mentions[] | null;
};

type StreamFormProps = {
  isEdit?: boolean;
  isStreamSettings?: boolean;
  isInterview?: boolean;
  isPastStream?: boolean;
  loading?: boolean;
  hideDeleteIcon?: boolean;
  stream?: GetStream_stream | GetStreamExtended_stream | null;
};

const StreamForm = ({
  stream,
  isEdit,
  isStreamSettings,
  isPastStream,
  loading,
  hideDeleteIcon,
}: StreamFormProps) => {
  const imageUploadRef = useRef<HTMLInputElement>(null);
  const [mentionsValues, setMentionsValues] = useState<
    GetStreamExtended_stream_mentions[]
  >(stream?.mentions || []);
  const { data: userData } = useGetCurrUser();

  useEffect(() => {
    // Check if streamMentions prop is different from the current mentionsValues state
    if (stream?.mentions && stream.mentions !== mentionsValues) {
      setMentionsValues(stream.mentions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream?.mentions]);

  const {
    register,
    unregister,
    errors,
    setValue,
    control,
    watch,
  } = useFormContext<FormInputs>();

  useEffect(() => {
    register('image');
    register('previewImage');

    return () => {
      unregister('image');
      unregister('previewImage');
    };
  }, [register, unregister]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('image', acceptedFiles[0], { shouldValidate: true });
    },
    [setValue]
  );

  const handleApplyImage = useCallback(
    (image: File) => {
      setValue('previewImage', URL.createObjectURL(image), {
        shouldValidate: true,
      });
      setValue('image', image, { shouldValidate: true });
    },
    [setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/jpg, image/jpeg, image/png',
  });

  const handlePreviewImageRemove = () => {
    setValue('previewImage', '', {
      shouldValidate: true,
    });
    setValue('image', null, { shouldValidate: true });
  };

  const timeZone = watch('timeZone');
  const image = watch('image');
  const description = watch('description');
  const previewImage = watch('previewImage');
  const scheduleDate = watch('scheduleDate');
  const isFreeStream = watch('isFree');

  const selectedTimeZoneOffset = dayjs
    .tz(
      dayjs(scheduleDate ? dayjs(scheduleDate) : dayjs()).format(
        'YYYY-MM-DD HH:mm'
      ),
      timeZone?.tzName
    )
    .utcOffset();

  const currentTimeInSelectedTimeZone = dayjs()
    .add(selectedTimeZoneOffset, 'minute')
    .add(new Date().getTimezoneOffset(), 'minute')
    .toDate();
  const isTodaySelected = dayjs(
    scheduleDate || currentTimeInSelectedTimeZone
  ).isSame(currentTimeInSelectedTimeZone, 'day');
  const maxTime = isTodaySelected
    ? dayjs(currentTimeInSelectedTimeZone).endOf('day').toDate()
    : dayjs(scheduleDate).startOf('day').toDate();

  const renderStreamImage = () => {
    if (image) {
      return (
        <EasyImageCrop
          className={styles.croppedImage}
          imageFile={image}
          onApplyImage={handleApplyImage}
          aspect={1}
        />
      );
    }

    return (
      <img
        src={previewImage}
        className={styles.previewImage}
        alt="Stream poster"
      />
    );
  };

  const handlePlaceholderClick = () => {
    imageUploadRef.current?.click();
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        setValue('mentions', mentions, { shouldValidate: true });
        setMentionsValues(mentions);
      }
    },
    [mentionsValues, setValue]
  );

  const onShareClick = async () => {
    const sharePathname = `${getEnvLink()}/${userData?.me.slug}${STREAMS}/${
      stream?.slug
    }`;

    try {
      await navigator.clipboard.writeText(sharePathname);

      showToast({
        message: 'Copied',
        autoClose: 1000,
      });
    } catch (error) {
      showToast({
        message: 'Could not copy',
        type: 'error',
        autoClose: 1000,
      });
    }
  };

  const handleOnDownloadClick = (imageUrl: string) => async () => {
    const storeName = userData?.me?.storeDetails?.storeName;
    const fileName = storeName ? getFileName(storeName) : 'Stream poster image';
    await downloadImageFromUrl(imageUrl, fileName);
  };

  return (
    <SplitLeftRightView
      left={
        <div className={styles.leftContainer}>
          <form
            className={styles.form}
            autoComplete="off"
            aria-label="stream media details"
          >
            <TextArea
              className={cx(styles.textarea, styles.nameTextarea, {
                [styles.disabled]: isPastStream,
              })}
              name="name"
              label="Title"
              ref={register}
              error={errors?.name?.message}
              placeholder="Name your stream"
              rows={1}
              type="textarea"
              readOnly={isPastStream}
            />
            <Controller
              control={control}
              name="description"
              render={({ onChange }) => (
                <RichText
                  label="Description"
                  classNameWrapper={cx(styles.richTextWrapper, {
                    [styles.disabledRichText]: isPastStream,
                  })}
                  initialValue={description}
                  toolbarItems={[ToolbarItem.Link]}
                  onChange={onChange}
                  placeholder="Describe your stream"
                  error={errors?.description?.message}
                  toolbarPosition={'right'}
                  readOnly={isPastStream}
                />
              )}
            />

            {!isStreamSettings && (
              <>
                <div className={styles.dateTimezoneContainer}>
                  <Controller
                    control={control}
                    name="scheduleDate"
                    render={({ onChange, onBlur, value }) => {
                      const getValidDate = (date) => {
                        const isToday = dayjs(date).isSame(
                          dayjs(currentTimeInSelectedTimeZone),
                          'day'
                        );
                        const isTimeInPast = dayjs(date).isBefore(
                          dayjs(currentTimeInSelectedTimeZone)
                        );
                        const a15minAfterNow = dayjs(
                          currentTimeInSelectedTimeZone
                        ).add(15, 'm');

                        return isToday && isTimeInPast
                          ? a15minAfterNow
                              .set(
                                'm',
                                a15minAfterNow.get('minute') -
                                  (a15minAfterNow.get('minute') % 15)
                              )
                              .toDate()
                          : date;
                      };

                      const handleChange = (date) => {
                        onChange(getValidDate(date));
                      };

                      return (
                        <DatePicker
                          showMonthDropdown
                          showYearDropdown
                          yearDropdownItemNumber={70}
                          wrapperClassName={styles.datePicker}
                          selected={getValidDate(value)}
                          onBlur={onBlur}
                          onChange={handleChange}
                          showTimeSelect
                          placeholderText="MM/DD/YYYY"
                          minDate={currentTimeInSelectedTimeZone}
                          minTime={currentTimeInSelectedTimeZone}
                          maxTime={maxTime}
                          disabled={isPastStream}
                          customInput={
                            <Input
                              label="Schedule date"
                              className={styles.inputLabel}
                              aria-label={`${
                                isEdit ? 'Change' : 'Select'
                              } schedule date`}
                              name="dateTimeInput"
                              error={errors?.scheduleDate?.message}
                            />
                          }
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="timeZone"
                    render={({ onChange, value }) => {
                      const matchedOption = TIME_ZONE_OPTIONS.find(
                        (option) => option.tzCode === value?.tzCode
                      );

                      return (
                        <Select
                          aria-label={`${
                            isEdit ? 'Change' : 'Select'
                          } Time Zone`}
                          className={styles.timeZonePicker}
                          placeholder="Time Zone"
                          label="Time Zone"
                          options={TIME_ZONE_OPTIONS}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.tzCode}
                          isLoading={loading}
                          fieldSize="large"
                          id="timeZone"
                          inputId="tzCodeSelect"
                          error={(errors?.timeZone as any)?.message}
                          onChange={onChange}
                          value={matchedOption}
                          disabled={isPastStream}
                        />
                      );
                    }}
                  />
                </div>
                <div className={styles.repeatingContainer}>
                  <Controller
                    name="repeatsEveryEnum"
                    defaultValue={REPEAT_STREAM_OPTIONS[0]}
                    control={control}
                    render={({ onChange, ...rest }) => {
                      return (
                        <Select
                          className={styles.repeatStreamPicker}
                          placeholder="Select"
                          label="Does this event repeat?"
                          options={REPEAT_STREAM_OPTIONS}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => `${option.value}`}
                          isLoading={loading}
                          inputId="repeatsEveryEnum"
                          fieldSize="large"
                          onChange={(data: RepeatStreamOption) =>
                            onChange(data)
                          }
                          disabled={isPastStream}
                          {...rest}
                        />
                      );
                    }}
                  />
                </div>
                <div className={styles.priceFieldContainer}>
                  <Input
                    name="requestedPrice"
                    label="Price"
                    aria-label="Requested price"
                    placeholder="Type here"
                    ref={register}
                    error={errors?.requestedPrice?.message}
                    className={styles.priceInput}
                    readOnly={isEdit}
                    disabled={isFreeStream || isEdit || isPastStream}
                    notice="The final price will be increased due to the platform’s fee"
                  />

                  <div className={styles.freePriceContainer}>
                    <Switch
                      wrapperClassName={styles.switch}
                      label="Make it Free"
                      name="isFree"
                      ref={register}
                      disabled={isEdit || isPastStream}
                      readOnly={isEdit}
                    />
                  </div>
                </div>
              </>
            )}

            {!isEdit && (
              <div className={styles.testContainer}>
                <div className={styles.testWrapper}>
                  <Text variant="subtitle2">Is This a test Stream?</Text>
                  <Text className={styles.testDescription}>
                    Run a test stream to make sure everything runs smoothly when
                    you go live.
                  </Text>
                </div>

                <CheckBox label="Test stream" name="isHidden" ref={register} />
              </div>
            )}

            <div
              className={cx({
                [styles.lowerZIndexContainer]: isEdit,
              })}
            >
              <Controller
                name="hashtags"
                control={control}
                render={({ onChange, value: tags }) => (
                  <TagsInput
                    tags={tags}
                    label="Tags"
                    placeholder="Add tags here"
                    onChange={onChange}
                    name="hashtags"
                  />
                )}
              />
            </div>
          </form>

          {isEdit && (
            <div className={styles.mentionsSection}>
              <div>
                <Text variant="h6">Mentions</Text>
                <Text variant="body1Regular16" color="lights-medium">
                  List athletes, creators, and organizations who will be
                  participating in this stream. Input names and/or links of
                  participants.
                </Text>
              </div>

              <Mentions
                mentions={mentionsValues as Mention[]}
                onChange={handleMentionChange}
              />
            </div>
          )}
        </div>
      }
      right={
        <div className={styles.uploadImage}>
          <Text variant="h6">Image Upload</Text>
          <Text variant="body1Regular16" color="lights-medium">
            Upload image in JPEG or PNG.
          </Text>

          <div className={styles.imageContainer}>
            {image || previewImage ? (
              <div className={styles.previewImageWrapper}>
                {renderStreamImage()}
                {!hideDeleteIcon && (
                  <Button
                    className={styles.removePreviewButton}
                    onClick={handlePreviewImageRemove}
                    aria-label="Delete stream poster"
                    disabled={isPastStream}
                    icon="trash"
                  />
                )}
              </div>
            ) : (
              <>
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} aria-label="upload image" />
                  <div className={styles.dragArea}>
                    <UploadImagePlaceholder
                      onClick={handlePlaceholderClick}
                      title="Upload Image"
                      description="Image must be max. 5mb in JPEG or PNG format."
                    />
                  </div>
                </div>

                {errors?.previewImage?.message && (
                  <Text variant="captionRegular" color="error-default">
                    {errors?.previewImage?.message}
                  </Text>
                )}
              </>
            )}
          </div>
          {isStreamSettings && (
            <div className={styles.streamSettingsFooter}>
              <Button
                onClick={handleOnDownloadClick(stream?.imageURL || '')}
                icon="download"
                className={styles.downloadButton}
              >
                Download poster
              </Button>
              <Button
                color="white"
                className={styles.shareButton}
                onClick={onShareClick}
                disabled={loading}
                icon="share"
              >
                Share
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};

export default StreamForm;
