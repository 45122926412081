import { RefObject, useRef, useState } from 'react';
import cn from 'classnames';
// Types
import Image from 'ui3/Image/Image';
// UI
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from './CardVideo.module.scss';

export interface BasicCardVideoProps {
  title?: string;
  videoThumbnailURL: string;
  videoURL?: string;
  videoId: string;
  priorityImageLoading?: boolean;
  className?: string;
  imageWrapperRef: RefObject<HTMLDivElement>;
  hasRedirect?: boolean;
}

export type BaseCardProps = React.ComponentPropsWithoutRef<'video'> &
  BasicCardVideoProps;

const PLAY_ICON_NAME = 'playSolid';
const PAUSE_ICON_NAME = 'pauseSolid';
const activeAmaAttr = 'active-ama';

const CardVideo: React.FC<BaseCardProps> = ({
  title,
  videoThumbnailURL,
  videoURL = '',
  videoId,
  priorityImageLoading,
  className,
  imageWrapperRef,
  hasRedirect,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoVisible, setVideoVisibility] = useState<boolean>(false);

  const setVisibilityAttributes = (visibility: 'visible' | 'hidden') => {
    const playIcon = imageWrapperRef.current?.querySelector<SVGSVGElement>(
      `[data-icon-name=${PLAY_ICON_NAME}]`
    );
    const pauseIcon = imageWrapperRef.current?.querySelector<SVGSVGElement>(
      `[data-icon-name=${PAUSE_ICON_NAME}]`
    );

    if (playIcon && pauseIcon) {
      if (visibility === 'visible') {
        playIcon.style.display = '';
        pauseIcon.style.display = 'none';
        imageWrapperRef.current?.removeAttribute(activeAmaAttr);
      } else {
        playIcon.style.display = 'none';
        pauseIcon.style.display = 'block';
        imageWrapperRef.current?.setAttribute(activeAmaAttr, videoId);
      }
    }
  };

  const handleVideoPlayToggle = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    // Don't go to the link from the parent, when clicking play/pause icon
    e.stopPropagation();
    e.preventDefault();
    /**
     * Stop another active AMA video before starting a new one with
     * a help of HTML attributes
     */
    const activeAma = document.querySelector<HTMLDivElement>(
      `[${activeAmaAttr}]`
    );
    const isAnotherActiveAma =
      imageWrapperRef.current?.getAttribute(activeAmaAttr) !==
      activeAma?.getAttribute(activeAmaAttr);

    if (isAnotherActiveAma && activeAma) {
      const playIcon = activeAma.querySelector<SVGSVGElement>(
        `[data-icon-name=${PLAY_ICON_NAME}]`
      );
      const pauseIcon = activeAma.querySelector<SVGSVGElement>(
        `[data-icon-name=${PAUSE_ICON_NAME}]`
      );

      activeAma.removeAttribute(activeAmaAttr);
      activeAma.querySelector('video')?.pause();

      if (playIcon && pauseIcon) {
        playIcon.style.display = '';
        pauseIcon.style.display = 'none';
      }
    }

    if (isVideoVisible) {
      if (videoRef.current?.paused) {
        videoRef.current.play();
        setVisibilityAttributes('hidden');
      } else {
        videoRef.current?.pause();
        setVisibilityAttributes('visible');
      }
    } else {
      setVideoVisibility(true);
      setVisibilityAttributes('hidden');
    }
  };

  const handleVideoEnded = () => {
    setVisibilityAttributes('visible');
  };

  return (
    <div
      className={cn(styles.root, className, {
        [styles.noRedirect]: !hasRedirect,
      })}
      {...(!hasRedirect && { onClick: handleVideoPlayToggle })}
    >
      {videoThumbnailURL && !isVideoVisible && (
        <Image
          title={title}
          alt={title}
          src={videoThumbnailURL}
          placeholder="blur"
          priority={priorityImageLoading}
          sizes="(max-width: 344px) 50vw, (max-width: 344px) 33vw, 20vw"
          objectFit="cover"
          className={styles.image}
        />
      )}
      {!videoThumbnailURL && !isVideoVisible && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          src={`${videoURL}#t=0.001`}
          controls={false}
          className={styles.video}
        />
      )}
      <div
        aria-label="Play Personal Video"
        role="button"
        tabIndex={0}
        onClick={handleVideoPlayToggle}
        onKeyPress={handleVideoPlayToggle}
      >
        <MillionsIcon
          name={PLAY_ICON_NAME}
          size={48}
          className={styles.playIcon}
        />
        <MillionsIcon
          name={PAUSE_ICON_NAME}
          size={48}
          className={styles.pauseIcon}
        />
      </div>
      {isVideoVisible && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          src={videoURL}
          autoPlay
          playsInline
          controls={false}
          ref={videoRef}
          className={styles.video}
          onEnded={handleVideoEnded}
        />
      )}
    </div>
  );
};

export default CardVideo;
