import React from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
// Watchers
import GoogleSdkWatcher from 'layouts/Header/Watchers/GoogleSdkWatcher';
import FacebookSdkWatcher from 'layouts/Header/Watchers/FacebookSdkWatcher';
import TwitterSdkWatcher from 'layouts/Header/Watchers/TwitterSdkWatcher';
import TiktokSdkWatcher from 'layouts/Header/Watchers/TiktokSdkWatcher';
import ShoppingCartWatcher from 'layouts/Header/Watchers/ShoppingCartWatcher';
import SyncBrowserTabsWatcher from 'layouts/Header/Watchers/SyncBrowserTabsWatcher';
import OnboardingWatcher from './Watchers/OnboardingWatcher';
import OnboardingV2Watcher from './Watchers/OnboardingV2Watcher';
import CookieTokenWatcher from './Watchers/CookieTokenWatcher';
// Components
import DesktopHeader from 'layouts/Header/DesktopHeader/DesktopHeader';
import MobileHeader from 'layouts/Header/MobileHeader/MobileHeader';
import Onboarding from 'components/Onboarding/Onboarding';
import CreatorSetup from 'components/CreatorSetup/CreatorSetup';
// Styles
import styles from './Header.module.scss';
// Dynamic imports
const SignIn = dynamic(() => import('components/SignIn/SignIn/SignIn'), {
  ssr: false,
});
const SignUp = dynamic(() => import('components/SignUp/SignUpV2/SignUpV2'), {
  ssr: false,
});
const ForgotPassword = dynamic(
  () =>
    import(
      'components/ForgotPassword/ForgotPasswordContainer/ForgotPasswordContainer'
    ),
  {
    ssr: false,
  }
);
const ResetPassword = dynamic(
  () =>
    import(
      'components/ResetPassword/ResetPasswordContainer/ResetPasswordContainer'
    ),
  {
    ssr: false,
  }
);

export type Theme = 'black' | 'white';

type Props = {
  transparent?: boolean;
  fixed?: boolean;
  hidden?: boolean;
};

const Header: React.FC<Props> = ({
  transparent,
  fixed = true,
  hidden = false,
}) => {
  return (
    <>
      <header
        className={cn({
          [styles.hidden]: hidden,
        })}
      >
        <DesktopHeader fixed={fixed} />
        <MobileHeader transparent={transparent} fixed={fixed} />

        <SignIn />
        <SignUp />
        <ForgotPassword />
        <ResetPassword />
        <Onboarding />
        <CreatorSetup />
        <OnboardingWatcher />
        <OnboardingV2Watcher />
        <GoogleSdkWatcher />
        <FacebookSdkWatcher />
        <TwitterSdkWatcher />
        <TiktokSdkWatcher />
        <ShoppingCartWatcher />
        <SyncBrowserTabsWatcher />
        <CookieTokenWatcher />
      </header>
    </>
  );
};

export default Header;
