import cn from 'classnames';
import { TabList as ReactTabsTabList } from 'react-tabs';
import { TabsContextProvider, TabsListType } from 'ui/Tabs/hook/useTabsContext';

import styles from './TabList.module.scss';

type TabListProps = {
  className?: string;
  type?: TabsListType;
};

const TabList: React.FC<TabListProps> & {
  tabsRole: string;
} = ({ className, type = 'underline', ...props }) => {
  return (
    <TabsContextProvider type={type}>
      <ReactTabsTabList
        className={cn(styles.tabList, { [styles[type]]: !!type }, className)}
        {...props}
      />
    </TabsContextProvider>
  );
};

TabList.tabsRole = 'TabList';

export default TabList;
