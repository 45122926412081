import type React from 'react';
import { useState } from 'react';
// Ui3
import Text from 'ui3/Text/Text';
import Switch from 'ui3/Switch/Switch';
import MillionsIcon, { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from '../BrandDealSteps.module.scss';

type AdvancedPreferencesStepProps = {
  register: any;
  setValue: any;
  watch: any;
};

const AdvancedPreferencesStep = ({
  register,
  setValue,
  watch,
}: AdvancedPreferencesStepProps): JSX.Element => {
  const sponsoredArticles = watch('sponsoredArticles', false);
  const acceptFreeProducts = watch('acceptFreeProducts', false);
  const [restrictedCategories, setRestrictedCategories] = useState(
    watch('restrictedCategories') || {
      tobacco: false,
      alcohol: false,
      crypto: false,
      gambling: false,
      'cbd/cannabis': false,
    }
  );

  const handleBooleanToggle = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const newValue = e.target.checked;

    setValue(fieldName, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleRestrictedCategoryToggle = (
    category: keyof typeof restrictedCategories
  ) => {
    const updatedCategories = {
      ...restrictedCategories,
      [category]: !restrictedCategories[category],
    };
    setRestrictedCategories(updatedCategories);
    setValue('restrictedCategories', updatedCategories, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.advancedSettings}>
      <div className={styles.settingItem}>
        <div className={styles.settingHeader}>
          <Text variant="body1Regular16">Sponsored Articles & PR</Text>
          <Switch
            name="sponsoredArticles"
            checked={sponsoredArticles}
            onChange={(e) => handleBooleanToggle(e, 'sponsoredArticles')}
            ref={register}
          />
        </div>
        <Text color="lights-low" variant="body2Regular">
          I have writers, brands, and journalists write stories about me or
          featuring me to increase my profile&apos;s SEO, press, and
          awareness/traffic.
        </Text>
      </div>

      <div className={styles.settingItem}>
        <div className={styles.settingHeader}>
          <Text variant="body1Regular16">
            Accept Free Product or Affiliate Campaigns
          </Text>
          <Switch
            name="acceptFreeProducts"
            checked={acceptFreeProducts}
            onChange={(e) => handleBooleanToggle(e, 'acceptFreeProducts')}
            ref={register}
          />
        </div>
        <Text color="lights-low" variant="body2Regular">
          Choose if you&apos;re open to receiving free items (e.g., apparel,
          equipment) from brands as compensation for deals, or if you&apos;re
          open to deals that pay commission.
        </Text>
      </div>

      <Text
        color="lights-low"
        variant="body1Regular16"
        className={styles.sectionTitle}
      >
        ADVANCED SETTINGS
      </Text>

      <div className={styles.settingItem}>
        <Text className={styles.categoriesPadding} variant="body1Regular16">
          Limited Industry Categories
        </Text>

        {([
          'tobacco',
          'alcohol',
          'crypto',
          'gambling',
          'CBD/Cannabis',
        ] as const).map((category) => {
          const iconMapping = {
            tobacco: 'cigarette',
            alcohol: 'alcohol',
            crypto: 'bitcoin',
            gambling: 'dice',
            'CBD/Cannabis': 'weed',
          };
          const iconName = iconMapping[category];

          const displayName =
            category.charAt(0).toUpperCase() + category.slice(1);

          return (
            <div key={category} className={styles.categoryItem}>
              <div className={styles.labelContainer}>
                <MillionsIcon
                  name={iconName as IconNames}
                  className={styles.categoryIcon}
                />
                <Text variant="body2Regular">{displayName}</Text>
              </div>
              <Switch
                name={`restrictedCategories.${category}`}
                checked={restrictedCategories[category]}
                onChange={() => handleRestrictedCategoryToggle(category)}
                ref={register}
              />
            </div>
          );
        })}

        <Text
          color="lights-low"
          variant="body2Regular"
          className={styles.categoryNote}
        >
          If you are not able to promote brands in these restricted categories,
          or do not wish to see brand deals of this type, please opt out.
        </Text>
      </div>
    </div>
  );
};

export default AdvancedPreferencesStep;
