import cn from 'classnames';
import dayjs from 'dayjs';
// Helpers
import { DateSpecificHours, createDateTimeObject } from '../helpers';
// UI
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Input from 'ui3/Input/Input';
import DatePicker from 'ui3/DatePicker/DatePicker';
import Text from 'ui3/Text/Text';
import Button from 'ui3/Button/Button';
// Icons
import CalendarIcon from '../../../../public/images/icons/calendar.svg';
// Styles
import styles from './DateSpecificHoursForm.module.scss';

type DateSpecificHoursFormProps = {
  specificHoursList: DateSpecificHours[];
  setSpecificHoursList: (value: DateSpecificHours[]) => void;
};

const DateSpecificHoursForm = ({
  specificHoursList,
  setSpecificHoursList,
}: DateSpecificHoursFormProps): JSX.Element => {
  const addDayRange = () => {
    setSpecificHoursList([
      ...specificHoursList,
      {
        date: null,
        timeRanges: [{ from: null, to: null }],
      },
    ]);
  };

  const handleRemoveDay = (dayIndex: number) => {
    const newSpecificHoursList = specificHoursList.filter(
      (_, counter) => counter !== dayIndex
    );

    setSpecificHoursList(newSpecificHoursList);
  };

  const handleDateChange = (value: [Date, Date], index: number) => {
    const date = dayjs(new Date(`${value}`));

    const newSpecificHoursList = [...specificHoursList];
    newSpecificHoursList[index].date = date;

    setSpecificHoursList(newSpecificHoursList);
  };

  const handleTimeChange = (
    value: [dayjs.Dayjs | null, dayjs.Dayjs | null],
    dayIndex: number,
    timeIndex: number
  ) => {
    const [from, to] = value || [null, null];

    const newSpecificHoursList = [...specificHoursList];
    newSpecificHoursList[dayIndex].timeRanges[timeIndex] = {
      from,
      to,
    };

    setSpecificHoursList(newSpecificHoursList);
  };

  const handleAddTimeRange = (index: number) => {
    const newSpecificHoursList = [...specificHoursList];
    newSpecificHoursList[index].timeRanges.push({ from: null, to: null });

    setSpecificHoursList(newSpecificHoursList);
  };

  const handleRemoveTimeRange = (dayIndex: number, timeIndex: number) => {
    const newTimeRanges = specificHoursList[dayIndex].timeRanges.filter(
      (_, counter) => counter !== timeIndex
    );
    const newSpecificHoursList = [...specificHoursList];
    newSpecificHoursList[dayIndex].timeRanges = newTimeRanges;

    setSpecificHoursList(newSpecificHoursList);
  };

  return (
    <div className={styles.root}>
      <Text variant="subtitle1">Date specific hours</Text>
      <div className={styles.dateWrapper}>
        {specificHoursList.map((day, dayIndex) => {
          const lastItem = day.timeRanges.length - 1;

          const selectedDate = createDateTimeObject(day.date);

          return (
            <div key={'day_' + dayIndex} className={styles.dayWrapper}>
              <div className={styles.dayPickerWrapper}>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  onChange={(e) =>
                    handleDateChange(e as [Date, Date], dayIndex)
                  }
                  minDate={new Date()}
                  selected={selectedDate}
                  dateFormat="MM/d/yyyy"
                  placeholderText="Select date"
                  customInput={
                    <Input
                      className={styles.dateInput}
                      name={'dayInput_' + dayIndex}
                    />
                  }
                />
                <CalendarIcon className={styles.calendarIcon} />
              </div>
              <div className={styles.timePickerWrapper}>
                {day.timeRanges?.map((timeRange, timeIndex) => {
                  const timeFrom = createDateTimeObject(timeRange.from);
                  const minTime = timeFrom
                    ? dayjs(dayjs(timeFrom).add(15, 'minutes')).toDate()
                    : undefined;

                  const maxTime = dayjs().endOf('day').toDate();

                  return (
                    <div
                      key={'time_' + timeIndex}
                      className={styles.timeRangeWrapper}
                    >
                      <div className={styles.timeRange}>
                        <DatePicker
                          className={styles.timeInput}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={timeFrom}
                          onChange={(e) =>
                            handleTimeChange(
                              [dayjs(e as Date), timeRange.to],
                              dayIndex,
                              timeIndex
                            )
                          }
                          placeholderText="Start time"
                          customInput={
                            <Input
                              className={styles.timeInput}
                              name={'timeFrom_' + timeIndex}
                            />
                          }
                        />
                        <p className={styles.timeSeparator}>to</p>

                        <DatePicker
                          className={styles.timeInput}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={createDateTimeObject(timeRange.to)}
                          onChange={(e) =>
                            handleTimeChange(
                              [timeRange.from, dayjs(e as Date)],
                              dayIndex,
                              timeIndex
                            )
                          }
                          placeholderText="End time"
                          minTime={minTime}
                          maxTime={maxTime}
                          disabled={!(minTime && maxTime)}
                          customInput={
                            <Input
                              className={styles.timeInput}
                              name={'timeTo_' + timeIndex}
                            />
                          }
                        />
                      </div>
                      <div>
                        {/* show Plus icon to add new time value (from, to) */}
                        {timeIndex === lastItem && (
                          <MillionsIcon
                            className={cn(styles.icon, styles.addIcon)}
                            name="plusCircleOutline"
                            onClick={() => handleAddTimeRange(dayIndex)}
                          />
                        )}
                        {/* show Minus icon that removes time value, usable on all except first */}
                        {timeIndex > 0 && (
                          <MillionsIcon
                            className={styles.icon}
                            name="minusCircle"
                            onClick={() =>
                              handleRemoveTimeRange(dayIndex, timeIndex)
                            }
                          />
                        )}
                        {/* show Minus icon that removes the day if it is the first and only item */}
                        {timeIndex === 0 && timeIndex === lastItem && (
                          <MillionsIcon
                            className={styles.icon}
                            name="minusCircle"
                            onClick={() => handleRemoveDay(dayIndex)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div>
          <Button
            type="button"
            variant="primary"
            subVariant="text"
            icon="plusCircleOutline"
            onClick={() => addDayRange()}
          >
            Add date specific hours
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateSpecificHoursForm;
