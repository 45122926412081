import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
// API
import { GET_OR_CREATE_STREAM_PARTICIPANT_INVITE } from 'api/streams/queries';
// Types
import { CreateStream_createStream } from 'api/streams/types/CreateStream';
import { UpdateStream_updateStream } from 'api/streams/types/UpdateStream';
import { MyStreams_myStreams_entities } from 'api/streams/types/MyStreams';
import {
  GetOrCreateStreamParticipantInvite,
  GetOrCreateStreamParticipantInviteVariables,
} from 'api/streams/types/GetOrCreateStreamParticipantInvite';
import { GetStream_stream } from 'api/streams/types/GetStream';
import { Me_me_upcomingParticipatedStream } from 'api/auth/types/Me';
// Constants
import { MANAGE_STREAMS } from 'constants/routes';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Ui
import Button from 'ui3/Button/Button';
import Modal from 'ui3/Modal/Modal';
import Loader from 'ui3/Loader/Loader';
import Text from 'ui3/Text/Text';
// Components
import CopyLink from 'components/common3/CopyLink/CopyLink';
import DownloadPoster from 'components/common3/DownloadPoster/DownloadPoster';
// Styles
import styles from './InviteAndPromoteModal.module.scss';

type InviteAndPromoteModalProps = {
  stream:
    | CreateStream_createStream
    | UpdateStream_updateStream
    | MyStreams_myStreams_entities
    | GetStream_stream
    | Me_me_upcomingParticipatedStream;
  show: boolean;
  showInvite?: boolean;
  onClose?: () => void;
};

const InviteAndPromoteModal = ({
  stream,
  show,
  showInvite = true,
  onClose,
}: InviteAndPromoteModalProps) => {
  const router = useRouter();
  const { data: streamInviteData, loading } = useQuery<
    GetOrCreateStreamParticipantInvite,
    GetOrCreateStreamParticipantInviteVariables
  >(GET_OR_CREATE_STREAM_PARTICIPANT_INVITE, {
    variables: {
      input: {
        streamId: stream.id,
      },
    },
    skip: !show || !stream.id,
    fetchPolicy: 'cache-and-network',
  });

  const streamLink = `${getEnvLink()}/${stream.store?.slug}/streams/${
    stream.slug
  }`;
  const inviteLink = `${getEnvLink()}/stream-invite/${stream.id}/${
    streamInviteData?.getOrCreateStreamParticipantInvite.invite
  }`;

  const handleOnContinue = () => {
    if (onClose) {
      onClose();
    } else {
      router.push(MANAGE_STREAMS);
    }
  };

  return (
    <Modal
      className={styles.modal}
      focusTrapped={false}
      open={show}
      onClose={handleOnContinue}
    >
      <div className={styles.root}>
        <Loader loading={loading} size="xLarge" />

        <Text variant="h3" textAlign="center">
          Share & Promote
        </Text>

        <DownloadPoster
          posterName={stream.name ?? ''}
          posterImageURL={stream.imageURL ?? ''}
        />

        <div className={styles.links}>
          {showInvite && (
            <CopyLink
              name="invite"
              label="Participant invitation link"
              link={inviteLink}
            />
          )}

          <CopyLink
            name="event"
            label="Event promotion link"
            link={streamLink}
          />
        </div>

        <Button block color="black" onClick={handleOnContinue}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default InviteAndPromoteModal;
