import cn from 'classnames';
// Context
import { SocialAccount } from 'context/OnboardingContext';
// Helpers
import {
  mapSocialPlatformToIcon,
  mapSocialPlatformToLabel,
} from 'helpers/socials';
// Ui
import Text from 'ui3/Text/Text';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from './SocialsRow.module.scss';

type SocialsRowProps = {
  socialAccount: SocialAccount;
  firstRow?: boolean;
  lastRow?: boolean;
  onRemove: () => void;
};

const SocialsRow = ({
  socialAccount,
  firstRow,
  lastRow,
  onRemove,
}: SocialsRowProps) => {
  return (
    <div
      className={cn(styles.root, {
        [styles.firstRow]: firstRow,
        [styles.lastRow]: lastRow,
      })}
    >
      <div className={styles.content}>
        <div>
          <MillionsIcon
            name={mapSocialPlatformToIcon(socialAccount.platform)}
          />
        </div>
        <div>
          <Text variant="subtitle2">
            {mapSocialPlatformToLabel(socialAccount.platform)}
          </Text>
          <Text variant="body2Regular" color="lights-low">
            {socialAccount.link}
          </Text>
        </div>
      </div>
      <button type="button" className={styles.removeButton} onClick={onRemove}>
        <MillionsIcon name="minus" />
      </button>
    </div>
  );
};

export default SocialsRow;
