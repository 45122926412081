// Types
import {
  OrganizationType,
  StoreAthleticLevel,
  StoreGender,
} from 'api/graphql-global-types';

export type FollowerCountOption = {
  value: number;
  label: string;
};

export type StoreGenderOptionsType = {
  value: StoreGender;
  label: string;
};

export type AthleteLevelOption = {
  value: StoreAthleticLevel;
  label: string;
};

export const FOLLOWER_OPTIONS: FollowerCountOption[] = [
  { value: 800, label: '<1k+' },
  { value: 1200, label: '1k+' },
  { value: 11000, label: '10k+' },
  { value: 110000, label: '100k+' },
  { value: 1100000, label: '1M+' },
];

export const GENDER_OPTIONS: StoreGenderOptionsType[] = [
  {
    value: StoreGender.male,
    label: 'Male',
  },
  {
    value: StoreGender.female,
    label: 'Female',
  },
  {
    value: StoreGender.other,
    label: 'Other',
  },
  {
    value: StoreGender.preferNotToSay,
    label: 'Prefer not to say',
  },
];

export const ATHLETIC_LEVEL: AthleteLevelOption[] = [
  {
    value: StoreAthleticLevel.Amateur,
    label: 'Amateur',
  },
  {
    value: StoreAthleticLevel.CollegeUniversity,
    label: 'College University',
  },
  {
    value: StoreAthleticLevel.OlympicNational,
    label: 'Olympic National',
  },
  {
    value: StoreAthleticLevel.Other,
    label: 'Other',
  },
  {
    value: StoreAthleticLevel.Professional,
    label: 'Professional',
  },
  {
    value: StoreAthleticLevel.RetiredProfessional,
    label: 'Retired Professional',
  },
];

export type OrgTypeOption = {
  value: string;
  label: string;
};

export const ORGANIZATION_TYPE_OPTIONS: OrgTypeOption[] = [
  {
    value: OrganizationType.Gym,
    label: 'Gym',
  },
  {
    value: OrganizationType.EventOrganizer,
    label: 'Event/Organizer',
  },
  {
    value: OrganizationType.AssociationLeagueTeam,
    label: 'Association/League/Team',
  },
  {
    value: OrganizationType.Other,
    label: 'Other',
  },
];
