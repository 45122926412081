import React from 'react';
import { RenderElementProps } from 'slate-react';
// Components
import Link from 'components/common3/RichText/components/Link/Link';

const Element = ({
  attributes,
  element,
  children,
}: RenderElementProps): JSX.Element => {
  // eslint-disable-next-line
  // @ts-ignore
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return <Link element={element}>{children}</Link>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
