import cn from 'classnames';
// Hooks
import { useOnboardingContext } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
// Styles
import styles from './StreamingInitialStep.module.scss';

const StreamingInitialStep = () => {
  const {
    createStreamDone,
    createInterviewDone,
    hostCalendarDone,
    setShowCreateStreamModal,
    setShowCreateInterviewModal,
    setShowHostCalendarModal,
  } = useOnboardingContext();

  return (
    <div className={styles.root}>
      <div className={styles.textContainer}>
        <Text textAlign="center" className={styles.title}>
          Choose Your Streaming Type
        </Text>

        <Text
          color="lights-medium"
          textAlign="center"
          className={styles.subtitle}
        >
          Select whether you want to stream, get interviewed or host your own
          interviews.
        </Text>
      </div>

      <div className={styles.buttonsContainer}>
        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: createStreamDone,
          })}
          disabled={createStreamDone}
          onClick={() => setShowCreateStreamModal(true)}
        >
          <img src={'/images/stream.png'} alt="Host free or paid events" />
          <Text variant="h6" uppercase>
            Host free or paid events
          </Text>

          <Text variant="body2Regular" color="lights-medium">
            Create streams. Monetize them with PPV or host for free.
          </Text>
        </button>

        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: createInterviewDone,
          })}
          disabled={createInterviewDone}
          onClick={() => setShowCreateInterviewModal(true)}
        >
          <img src={'/images/microphone.png'} alt="Get interviewed" />
          <Text variant="h6" uppercase>
            Get interviewed
          </Text>

          <Text variant="body2Regular" color="lights-medium">
            Get interviewed by a host, or let us assign one for you.
          </Text>
        </button>

        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: hostCalendarDone,
          })}
          disabled={hostCalendarDone}
          onClick={() => setShowHostCalendarModal(true)}
        >
          <img src={'/images/microphone_headset.png'} alt="Host interviews" />
          <Text variant="h6" uppercase>
            Host interviews
          </Text>

          <Text variant="body2Regular" color="lights-medium">
            Host interviews, set availability, and invite guests.
          </Text>
        </button>
      </div>
    </div>
  );
};

export default StreamingInitialStep;
