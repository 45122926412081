// Hooks
import { useOnboardingContext } from 'hooks';
// Components
import FullScreenStepperModal from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import StreamingInitialStep from './StreamingInitialStep/StreamingInitialStep';
import CreateStreamSetup from './CreateStreamSetup/CreateStreamSetup';
import CreateInterviewSetup from './CreateInterviewSetup/CreateInterviewSetup';
import HostInterviewSetup from './HostInterviewSetup/HostInterviewSetup';

const StreamingSetup = () => {
  const {
    showStreamingSetupModal,
    showCreateStreamModal,
    showCreateInterviewModal,
    showHostCalendarModal,
    setShowStreamingSetupModal,
  } = useOnboardingContext();

  const handleClose = () => {
    setShowStreamingSetupModal(false);
  };

  return (
    <FullScreenStepperModal
      title="STREAMING AND MEDIA"
      open={showStreamingSetupModal}
      onClose={handleClose}
    >
      <>
        <StreamingInitialStep />
        {showCreateStreamModal && <CreateStreamSetup />}
        {showCreateInterviewModal && <CreateInterviewSetup />}
        {showHostCalendarModal && <HostInterviewSetup />}
      </>
    </FullScreenStepperModal>
  );
};

export default StreamingSetup;
