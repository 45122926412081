import { gql } from '@apollo/client';
// Fragments
import { MemorabiliaFields } from './fragments';

export const CREATE_MEMORABILIA = gql`
  mutation CreateMemorabilia($input: CreateMemorabiliaInput!) {
    createMemorabilia(input: $input) {
      ...MemorabiliaFields
    }
  }
  ${MemorabiliaFields}
`;

export const UPDATE_MEMORABILIA = gql`
  mutation UpdateMemorabilia($input: UpdateMemorabiliaInput!) {
    updateMemorabilia(input: $input) {
      ...MemorabiliaFields
    }
  }
  ${MemorabiliaFields}
`;

export const DELETE_MEMORABILIA = gql`
  mutation DeleteMemorabilia($input: DeleteMemorabiliaInput!) {
    deleteMemorabilia(input: $input) {
      id
    }
  }
`;

export const RECOVER_MEMORABILIA = gql`
  mutation RecoverMemorabilia($input: RecoverMemorabiliaInput!) {
    recoverMemorabilia(input: $input) {
      id
    }
  }
`;

export const CREATE_PRESIGNED_URL = gql`
  mutation CreatePresignedUrl($input: CreateMemorabiliaImagePresignedUrl!) {
    createPresignedUrl(input: $input) {
      fields
      key
      url
    }
  }
`;

export const PLACE_BID_V2 = gql`
  mutation PlaceBidV2($input: PlaceBidInput!) {
    placeBidV2(input: $input) {
      id
      auctionId
      actualAmount
      stripeIntentClientSecret
      stripeIntentID
    }
  }
`;

export const COMPLETE_STRIPE_AUCTION_PAYMENT = gql`
  mutation CompleteStripeAuctionPayment($input: CompleteStripePaymentInput!) {
    completeStripePayment(input: $input) {
      status
    }
  }
`;

export const ACCEPT_SIGNED_MEMORABILIA = gql`
  mutation AcceptSignedMemorabilia($input: AcceptSignedMemorabiliaInput!) {
    acceptSignedMemorabilia(input: $input) {
      ...MemorabiliaFields
    }
  }
  ${MemorabiliaFields}
`;
