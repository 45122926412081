import { Controller, FieldError, useFormContext } from 'react-hook-form';
// Types
import { GetPlaceDetails_getPlaceDetails } from 'api/places/types/GetPlaceDetails';
import { AccountSubType } from 'api/graphql-global-types';
// Constants
import { USER_SUBTYPES } from 'constants/user';
import { ATHLETIC_LEVEL } from 'constants/store';
// Helpers
import { joinLocation } from 'helpers/place';
// Context
import { OnboardingFormData } from 'context/OnboardingContext';
// Hooks
import { useGetSports } from 'hooks';
// Ui
import Select from 'ui3/Select/Select';
import TextArea from 'ui3/TextArea/TextArea';
import PhoneNumberInput from 'ui3/PhoneInput/PhoneNumberInput';
import PlaceAutocomplete from 'ui3/PlaceAutocomplete/PlaceAutocomplete';
// Styles
import styles from './BasicInfoForm.module.scss';
import CheckBox from 'ui3/CheckBox/CheckBox';

const BasicInfoForm = () => {
  const { data } = useGetSports();
  const sports = data?.sports || [];

  const {
    errors,
    register,
    watch,
    control,
  } = useFormContext<OnboardingFormData>();

  const accountType = watch('accountType');

  return (
    <div className={styles.root}>
      <Controller
        name="accountType"
        control={control}
        render={({ onChange, value }) => (
          <Select
            label="Category"
            placeholder="Select"
            onChange={onChange}
            value={value}
            options={USER_SUBTYPES}
            error={(errors.accountType as FieldError)?.message}
          />
        )}
      />

      {accountType?.value === AccountSubType.Athlete && (
        <Controller
          name="experienceLevel"
          control={control}
          render={({ onChange, value }) => (
            <Select
              label="Experience Level"
              placeholder="Select"
              onChange={onChange}
              value={value}
              options={ATHLETIC_LEVEL}
              error={errors.experienceLevel?.value?.message}
            />
          )}
        />
      )}

      <Controller
        name="sport"
        control={control}
        render={({ onChange, value }) => (
          <Select
            label="Sport"
            placeholder="Select"
            onChange={onChange}
            value={value}
            options={sports}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            error={(errors.sport as FieldError)?.message}
          />
        )}
      />

      <TextArea
        name="bio"
        ref={register}
        label="Bio (optional)"
        placeholder="Tell us more about yourself"
        error={errors.bio?.message}
        maxLength={300}
      />

      <Controller
        name="location"
        control={control}
        render={({ onChange, value }) => {
          const handlePlaceSelect = (
            placeDetails: GetPlaceDetails_getPlaceDetails
          ) => {
            const location = {
              country: placeDetails.country,
              countryCode: placeDetails.countryCode,
              city: placeDetails.city,
              state: placeDetails.stateCode,
              stateCode: placeDetails.stateCode,
              coordinates: {
                latitude: placeDetails.location.latitude,
                longitude: placeDetails.location.longitude,
              },
            };

            onChange(location);
          };

          return (
            <PlaceAutocomplete
              name="location"
              label="Location"
              placeholder="Enter location"
              onChange={handlePlaceSelect}
              value={value ? joinLocation([value.city, value.country]) : ''}
              error={(errors.location as FieldError)?.message}
            />
          );
        }}
      />

      <Controller
        name="phoneNumber"
        control={control}
        render={({ onChange, value }) => (
          <PhoneNumberInput
            name="phoneNumber"
            label="Your Phone Number"
            placeholder="Phone Number"
            onChange={onChange}
            value={value}
            error={errors.phoneNumber?.message}
          />
        )}
      />

      <CheckBox
        alignLabelTop
        label="By checking this box, you agree to receive SMS text messages from MILLIONS regarding support and updates for your profile. Reply STOP to opt out at any time. Reply HELP for Customer Care Contact Information. Messages and data rates may apply. Message frequency will vary."
        name="phoneOptIn"
        ref={register}
      />
    </div>
  );
};

export default BasicInfoForm;
