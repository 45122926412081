import NextImage, { ImageProps as NextImageProps } from 'next/image';
import cn from 'classnames';

import styles from './Image.module.scss';

const Image = ({ className, layout = 'fill', ...rest }: NextImageProps) => {
  if (!rest.src) {
    return null;
  }

  // kludge to create a responsive image
  // https://github.com/vercel/next.js/discussions/18739#discussioncomment-344932
  if (layout === 'fill') {
    return (
      <div className={cn(styles.root, className)}>
        <NextImage
          className={styles.image}
          objectFit="cover"
          objectPosition="center"
          layout={layout}
          blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          {...rest}
        />
      </div>
    );
  }

  return <NextImage layout={layout} className={className} {...rest} />;
};

export default Image;
