import {
  FieldName,
  FieldValues,
  SetFieldValue,
  SetValueConfig,
  UseFormMethods,
} from 'react-hook-form';
// Types
import { GetPlaceDetails_getPlaceDetails } from 'api/places/types/GetPlaceDetails';

export type PlaceType = 'hometown' | 'city';

export const checkIsUSorCA = (input: string | null) => {
  return (
    Boolean(input === 'United States' || input === 'Canada') ||
    Boolean(input === 'US' || input === 'CA')
  );
};

export const joinLocation = (input: (string | undefined | null)[]) => {
  return input.filter(Boolean).join(', ');
};

export const createLocation = (
  latitude?: number | null,
  longitude?: number | null
) => (latitude != null && longitude != null ? { latitude, longitude } : null);

const setFormField = <T extends FieldValues>(
  setValue: UseFormMethods<T>['setValue'],
  field: string,
  value: string | null | { latitude: number; longitude: number },
  options?: SetValueConfig
) => {
  setValue(field as FieldName<T>, value as SetFieldValue<T>, options);
};

export const handlePlaceSelect = <
  T extends FieldValues,
  U = Record<string, never>
>(
  setValue: UseFormMethods<T>['setValue'],
  placeDetails: GetPlaceDetails_getPlaceDetails,
  type: PlaceType,
  onCountryChange?: (country: string) => void,
  onStateChange?: <K extends keyof U>(key: K, value: U[K]) => void
) => {
  const isUSOrCA = checkIsUSorCA(placeDetails.country);

  const fieldMap = {
    hometown: {
      city: 'hometown',
      state: 'hometownState',
      country: 'hometownCountry',
      location: 'hometownLocation',
    },
    city: {
      city: 'city',
      state: 'state',
      country: 'country',
      location: 'location',
    },
  };

  const valueMapping = {
    city: placeDetails.city,
    state: isUSOrCA ? placeDetails.stateCode : null,
    country: placeDetails.countryCode,
    location: {
      latitude: placeDetails.location.latitude,
      longitude: placeDetails.location.longitude,
    },
  };

  const options = { shouldValidate: true, shouldDirty: true };

  Object.entries(fieldMap[type]).forEach(([key, fieldName]) => {
    const value = valueMapping[key as keyof typeof valueMapping];
    // Optionally, if updating country should include the options, apply them:
    const opts =
      fieldName === fieldMap[type].country && onCountryChange
        ? options
        : undefined;
    setFormField(setValue, fieldName, value, opts);

    // Update parent state for all fields
    if (onStateChange) {
      onStateChange(fieldName as keyof U, (value as unknown) as U[keyof U]);
    }
  });

  // If country change is handled, update it
  if (onCountryChange && placeDetails.countryCode) {
    onCountryChange(placeDetails.countryCode);
  }

  return joinLocation([
    placeDetails.city,
    isUSOrCA ? placeDetails.stateCode : null,
    placeDetails.countryCode,
  ]);
};
