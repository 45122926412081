import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Tabs, TabPanel } from 'react-tabs';
import router from 'next/router';
// Helpers
import {
  CheckboxState,
  TimeRanges,
  getWeekDayRule,
  getWeekDayAvailability,
  getDatesAvailability,
  DateSpecificHours,
  getInitialWeekdayRanges,
  getInitialDateRanges,
} from 'components/ManageAccountSettings/ManageHostCalendar/helpers';
// Api
import { SET_MY_SCHEDULE } from 'api/schedule/mutations';
import { GET_MY_SCHEDULE } from 'api/schedule/queries';
import { ME_PROFILE_SETUP } from 'api/auth/queries';
// Types
import {
  GetMySchedule,
  GetMySchedule_getMySchedule_rules_ScheduleDateRule,
  GetMySchedule_getMySchedule_rules_ScheduleWeekDayRule,
  GetMyScheduleVariables,
} from 'api/schedule/types/GetMySchedule';
import {
  SetMySchedule,
  SetMyScheduleVariables,
} from 'api/schedule/types/SetMySchedule';
import { ScheduleType } from 'api/graphql-global-types';
import { MeProfileSetup } from 'api/auth/types/MeProfileSetup';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Ui
import Text from 'ui3/Text/Text';
import TabList from 'ui3/Tabs/TabList/TabList';
import Tab from 'ui3/Tabs/Tab/Tab';
// Hooks
import { useOnboardingContext } from 'hooks';
// Constants
import { DASHBOARD } from 'constants/routes';
// Components
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import { showToast } from 'components/common/Toast/Toast';
import WeeklyHoursForm from 'components/ManageAccountSettings/ManageHostCalendar/WeeklyHoursForm/WeeklyHoursForm';
import DateSpecificHoursForm from 'components/ManageAccountSettings/ManageHostCalendar/DateSpecificHoursForm/DateSpecificHoursForm';
import ShareHostCalendar from 'components/ManageAccountSettings/ManageHostCalendar/ShareHostCalendar/ShareHostCalendar';
// Styles
import styles from './HostInterviewSetup.module.scss';

const CreateInterviewSetup = () => {
  const {
    showHostCalendarModal,
    setHostCalendarDone,
    setShowHostCalendarModal,
  } = useOnboardingContext();
  const { data: store } = useQuery<MeProfileSetup>(ME_PROFILE_SETUP);

  const handleClose = useCallback(() => {
    setShowHostCalendarModal(false);
  }, [setShowHostCalendarModal]);

  const handleSuccess = useCallback(() => {
    setShowShareModal(false);
    setHostCalendarDone(true);
    handleClose();
    router.push(DASHBOARD);
  }, [handleClose, setHostCalendarDone]);

  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const shareLink = `${getEnvLink()}/user-schedule-interview/host/${
    store?.me.id
  }`;

  const { data: scheduleData } = useQuery<
    GetMySchedule,
    GetMyScheduleVariables
  >(GET_MY_SCHEDULE, {
    variables: { input: { type: ScheduleType.Interview } },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (scheduleData?.getMySchedule?.timezone) {
      setSelectedTimezone(scheduleData.getMySchedule.timezone);
    }
  }, [scheduleData]);

  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(null);

  const [minimumNotice, setMinimumNotice] = useState<number>(0);
  const [durationMinutes, setDurationMinutes] = useState<number>(0);

  const [setMySchedule] = useMutation<SetMySchedule, SetMyScheduleVariables>(
    SET_MY_SCHEDULE,
    {
      refetchQueries: [GET_MY_SCHEDULE],
    }
  );

  const weekdayRules = scheduleData?.getMySchedule?.rules;
  const weekdayRulesFiltered = weekdayRules?.filter(
    (rule) => rule.__typename === 'ScheduleWeekDayRule'
  ) as GetMySchedule_getMySchedule_rules_ScheduleWeekDayRule[];

  const dateRules = scheduleData?.getMySchedule
    ?.rules as GetMySchedule_getMySchedule_rules_ScheduleDateRule[];

  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [timeRanges, setTimeRanges] = useState<TimeRanges>({
    monday: [{ from: null, to: null }],
    tuesday: [{ from: null, to: null }],
    wednesday: [{ from: null, to: null }],
    thursday: [{ from: null, to: null }],
    friday: [{ from: null, to: null }],
    saturday: [{ from: null, to: null }],
    sunday: [{ from: null, to: null }],
  });

  const [specificHoursList, setSpecificHoursList] = useState<
    DateSpecificHours[]
  >([]);

  const onSubmit = useCallback(async () => {
    try {
      const weekDayAvailability = getWeekDayAvailability(
        timeRanges,
        checkboxState
      );

      const datesAvailability = getDatesAvailability(specificHoursList);
      const dates = datesAvailability.map((item) => ({
        ...item,
        intervals: item.intervals.filter(
          (interval) => interval.from && interval.to
        ),
      }));
      await setMySchedule({
        variables: {
          input: {
            timezone: selectedTimezone as string,
            dates,
            weekdays: weekDayAvailability,
            type: ScheduleType.Interview,
            durationMinutes: Number(durationMinutes), // difference between durationMinutes and slotStepMinutes is that the durationMinutes sets the allowed time for an interview
            slotStepMinutes: Number(durationMinutes), // while slotStepMinutes property sets the time intervals when fetching data for host's schedule-but both use same value
            minimumNoticeMinutes: Number(minimumNotice),
          },
        },
      });
      showToast({
        message: 'Schedule updated',
        type: 'success',
      });
      setShowShareModal(true);
    } catch (err) {
      showToast({
        message:
          'Please ensure that the duration and timezone fields are filled out.',
        type: 'error',
      });
      console.error('error:', err);
    }
  }, [
    checkboxState,
    durationMinutes,
    minimumNotice,
    selectedTimezone,
    setMySchedule,
    specificHoursList,
    timeRanges,
  ]);

  useEffect(() => {
    if (weekdayRules) {
      const monday = getWeekDayRule('monday', weekdayRulesFiltered);
      const tuesday = getWeekDayRule('tuesday', weekdayRulesFiltered);
      const wednesday = getWeekDayRule('wednesday', weekdayRulesFiltered);
      const thursday = getWeekDayRule('thursday', weekdayRulesFiltered);
      const friday = getWeekDayRule('friday', weekdayRulesFiltered);
      const saturday = getWeekDayRule('saturday', weekdayRulesFiltered);
      const sunday = getWeekDayRule('sunday', weekdayRulesFiltered);

      const weekdays = {
        monday: !!monday,
        tuesday: !!tuesday,
        wednesday: !!wednesday,
        thursday: !!thursday,
        friday: !!friday,
        saturday: !!saturday,
        sunday: !!sunday,
      };

      setCheckboxState(weekdays);

      const initialTimeRanges = getInitialWeekdayRanges(weekdayRulesFiltered);
      setTimeRanges(initialTimeRanges);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  useEffect(() => {
    if (dateRules) {
      const dateRulesFiltered = dateRules.filter(
        (item) => item.__typename === 'ScheduleDateRule'
      );

      if (dateRulesFiltered.length > 0) {
        const newSpecificHoursList = getInitialDateRanges(dateRulesFiltered);
        setSpecificHoursList(newSpecificHoursList);
      }
    }
  }, [dateRules]);

  useEffect(() => {
    setMinimumNotice(scheduleData?.getMySchedule?.minimumNoticeMinutes || 0);
    setDurationMinutes(scheduleData?.getMySchedule?.durationMinutes || 0);
  }, [
    scheduleData?.getMySchedule?.durationMinutes,
    scheduleData?.getMySchedule?.minimumNoticeMinutes,
  ]);

  const footer: FullScreenStepperModalFooter = useMemo(() => {
    return {
      showPrevButton: true,
      prevButtonText: 'Back',
      prevButtonProps: {
        variant: 'secondary',
        onClick: handleClose,
      },
      submitButtonText: 'Next',
      submitButtonProps: {
        variant: 'primary',
        onClick: onSubmit,
      },
    };
  }, [handleClose, onSubmit]);

  return (
    <FullScreenStepperModal
      title="HOST AN INTERVIEW"
      open={showHostCalendarModal}
      onClose={handleClose}
      footer={footer}
    >
      <>
        <SplitLeftRightView
          left={
            <div className={styles.leftSection}>
              <Text variant="h2">Set Up Your Host Calendar</Text>

              <Text variant="body1Regular16" color="lights-medium">
                Add your availability to host interviews. If your schedule each
                week is the same, set your weekly availability. If your schedule
                changes by date, set date-specific availability.
              </Text>
            </div>
          }
          right={
            <div className={styles.rightSection}>
              <Tabs>
                <TabList>
                  <Tab>Weekly Availability</Tab>
                  <Tab>Date-Specific Availability</Tab>
                </TabList>
                <TabPanel>
                  <WeeklyHoursForm
                    checkboxState={checkboxState}
                    timeRanges={timeRanges}
                    minimumNotice={minimumNotice}
                    durationMinutes={durationMinutes}
                    selectedTimezone={selectedTimezone || ''}
                    setCheckboxState={setCheckboxState}
                    setTimeRanges={setTimeRanges}
                    setMinimumNotice={setMinimumNotice}
                    setDurationMinutes={setDurationMinutes}
                    setTimezone={setSelectedTimezone}
                  />
                </TabPanel>
                <TabPanel>
                  <DateSpecificHoursForm
                    specificHoursList={specificHoursList}
                    setSpecificHoursList={setSpecificHoursList}
                  />
                </TabPanel>
              </Tabs>
            </div>
          }
        />

        <ShareHostCalendar
          shareLink={shareLink}
          show={showShareModal}
          onClose={handleSuccess}
        />
      </>
    </FullScreenStepperModal>
  );
};

export default CreateInterviewSetup;
