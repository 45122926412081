// Helpers
import { downloadImageFromUrl } from 'helpers/download';
// Ui3
import Button from 'ui3/Button/Button';
// Styles
import styles from './DownloadPoster.module.scss';

type DownloadPosterProps = {
  posterName: string;
  posterImageURL: string;
};

const DownloadPoster = ({
  posterName,
  posterImageURL,
}: DownloadPosterProps) => {
  const handleDownloadPoster = () => {
    downloadImageFromUrl(posterImageURL);
  };

  return (
    <div className={styles.root}>
      <img
        className={styles.posterImage}
        alt={posterName}
        src={posterImageURL}
      />

      <Button
        type="button"
        variant="primary"
        subVariant="outlined"
        onClick={handleDownloadPoster}
      >
        Download Event Poster
      </Button>
    </div>
  );
};

export default DownloadPoster;
