import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import cn from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import router from 'next/router';
// Api
import { CREATE_STREAM } from 'api/streams/mutations';
import { StreamFields } from 'api/streams/fragments';
// Types
import { UserRole } from 'api/graphql-global-types';
import {
  CreateStream,
  CreateStreamVariables,
} from 'api/streams/types/CreateStream';
import { TimeZoneOption } from 'constants/timeZone';
import { MyStreams_myStreams_entities } from 'api/streams/types/MyStreams';
// Hooks
import { useGetCurrUser } from 'hooks';
// Helpers
import {
  getTimeZoneObject,
  getCurrentTimeZoneOffset,
  getDateWithoutCurrentTimeZone,
  getCurrentTimeZone,
} from 'helpers/timeZone';
import { computeStartStreamPath } from 'helpers/routes';
// Ui
import Switch from 'ui3/Switch/Switch';
import Button from 'ui3/Button/Button';
import Text from 'ui3/Text/Text';
import TextField from 'ui3/TextField/TextField';
// Icons
import Icon from 'ui/Icon/Icon';
// Components
import { showToast } from 'components/common/Toast/Toast';
import RichText from 'components/common2/RichText/RichText';
import { ToolbarItem } from 'components/common2/RichText/components/Toolbar/Toolbar';
import StreamCoverImage from './StreamCoverImage/StreamCoverImage';
import EasyImageCrop from 'components/common/EasyImageCrop/EasyImageCrop';
// Styles
import styles from './CreateEditGoLiveStream.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

type CreateEditStreamProps = {
  stream?: MyStreams_myStreams_entities;
  onComplete?: () => void;
};

type FormInputs = {
  name: string;
  description: string;
  scheduleDate: Date | string;
  timeZone: TimeZoneOption;
  requestedPrice: string;
  isGoLiveFree: boolean;
  isGoLiveHidden: boolean;
  image: File | null;
  previewImage: string;
};

const getValidationSchema = () => {
  return yup.object().shape({
    name: yup
      .string()
      .max(200, 'Max 200 characters')
      .required('Title is required'),
    description: yup
      .string()
      .max(400, 'Max 400 characters')
      .required('Description is required'),
    timeZone: yup
      .object()
      .shape({
        label: yup.string(),
        tzCode: yup.string(),
        offset: yup.number(),
      })
      .typeError('Time Zone is required')
      .required('Time Zone is required'),
    isGoLiveFree: yup.boolean(),
    isGoLiveHidden: yup.boolean(),
    requestedPrice: yup.string().when(['isGoLiveFree'], {
      is: (isFree) => !isFree,
      then: yup
        .string()
        .required('Price is required')
        .matches(
          /^(?:0\.\d{0,2}[1-9]|(?!0)\d{1,}(?:\.\d{0,2}[0-9])?)$/,
          'Only numbers are allowed'
        ),
      otherwise: yup.string(),
    }),
    previewImage: yup.string().required(),
    image: yup.mixed(),
  });
};

const CreateEditGoLiveStream = ({
  stream,
  onComplete,
}: CreateEditStreamProps) => {
  const defaultTimeZone: TimeZoneOption = getTimeZoneObject(stream);
  const { data: userData } = useGetCurrUser();
  const streamDate = getDateWithoutCurrentTimeZone(stream?.scheduleDate);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    unregister,
    control,
    watch,
  } = useForm<FormInputs>({
    resolver: yupResolver(getValidationSchema()),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      scheduleDate:
        stream?.scheduleDate && dayjs(stream.scheduleDate).isValid()
          ? dayjs(streamDate).toDate()
          : '',
      timeZone: defaultTimeZone,
      requestedPrice: stream?.requestedPrice ? `${stream.requestedPrice}` : '',
      isGoLiveFree: Boolean(stream?.isFree),
      isGoLiveHidden: Boolean(stream?.isHidden),
      previewImage: userData?.me.storeDetails?.avatarURL || '',
    },
  });

  // userData is now fetched with "network-only" policy,
  // so avatarURL is only available after the first render
  useEffect(() => {
    if (userData) {
      const sport = userData?.me.sports[0].name;
      const role =
        userData?.me.role === UserRole.ContentCreator
          ? 'Content Creator'
          : userData?.me.role;
      const storeName = userData?.me?.storeDetails?.storeName || '';
      const defaultText = `Join ${sport} ${role}, ${storeName}, for a live streaming event on MILLIONS.co`;

      setValue('name', defaultText);
      setValue('description', defaultText);
      setValue('previewImage', userData?.me.storeDetails?.avatarURL || '');
    }
  }, [setValue, userData]);

  const [createStream, { loading: createStreamLoading }] = useMutation<
    CreateStream,
    CreateStreamVariables
  >(CREATE_STREAM, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          myStreams(existingStreams = {}) {
            const newStreamRef = cache.writeFragment({
              data: data?.createStream,
              fragment: StreamFields,
            });

            return {
              ...existingStreams,
              entities: [newStreamRef, ...existingStreams.entities],
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    register('image');
    register('previewImage');

    return () => {
      unregister('image');
      unregister('previewImage');
    };
  }, [register, unregister]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('image', acceptedFiles[0], { shouldValidate: true });
    },
    [setValue]
  );

  const handleApplyImage = useCallback(
    (image: File) => {
      setValue('previewImage', URL.createObjectURL(image), {
        shouldValidate: true,
      });
      setValue('image', image, { shouldValidate: true });
    },
    [setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/jpg, image/jpeg, image/png',
  });

  const handleFormSubmit: SubmitHandler<FormInputs> = async (
    values
  ): Promise<void> => {
    const timeZoneOffset = dayjs
      .tz(
        dayjs(values.scheduleDate).format('YYYY-MM-DD HH:mm'),
        values.timeZone.tzName
      )
      .utcOffset();

    const sponsorsInput =
      stream?.sponsors?.length === 0 || !stream?.sponsors
        ? []
        : [
            {
              name: (stream?.sponsors && stream?.sponsors[0]?.name) || '',
              logo: stream?.sponsors && stream?.sponsors[0]?.logoUrl,
              id: stream?.sponsors && stream?.sponsors[0]?.id,
            },
          ];

    const variables = {
      sponsors: sponsorsInput,
      moderatorId: stream?.moderator?.id,
      name: values.name,
      description: values.description,
      ...(values.image
        ? {
            image: values.image,
          }
        : {}),
      scheduleDate: dayjs(values.scheduleDate)
        .add(getCurrentTimeZoneOffset(), 'minute')
        .toISOString(),
      timeZone: {
        tzCode: values.timeZone.tzCode,
        offset: timeZoneOffset,
      },
    };

    try {
      {
        const { data } = await createStream({
          variables: {
            input: {
              isFree: values.isGoLiveFree,
              isHidden: values.isGoLiveHidden,
              ...(!values.isGoLiveFree && {
                requestedPrice: +values.requestedPrice,
              }),
              ...variables,
              timeZone: {
                tzCode: getCurrentTimeZone().tzCode,
                offset: getCurrentTimeZoneOffset(),
              },
              sponsors: [],
            },
          },
        });
        const startStreamPath = computeStartStreamPath(data?.createStream);
        router.push(startStreamPath);
      }

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.log('error', error);
      showToast({
        message: `Something went wrong. (${error?.message})`,
        type: 'error',
      });
    }
  };

  const handlePreviewImageRemove = () => {
    setValue('previewImage', '', {
      shouldValidate: true,
    });
    setValue('image', null, { shouldValidate: true });
  };

  const image = watch('image');
  const previewImage = watch('previewImage');
  const isFreeStream = watch('isGoLiveFree');
  const description = watch('description');

  const renderStreamImage = () => {
    if (image) {
      return (
        <EasyImageCrop
          className={styles.imageCropper}
          imageFile={image}
          onApplyImage={handleApplyImage}
          aspect={1}
        />
      );
    }

    return (
      <StreamCoverImage
        previewImage={previewImage}
        fullname={userData?.me?.storeDetails?.storeName || ''}
        onSetNewImage={handleApplyImage}
      />
    );
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(handleFormSubmit)}
      autoComplete="off"
      aria-label="stream details"
    >
      <TextField
        type="textarea"
        name="name"
        placeholder="Title"
        ref={register}
        error={errors?.name?.message}
        className={cn(styles.textarea, styles.nameTextarea)}
        rows={2}
      />

      <Controller
        control={control}
        name="description"
        render={({ onChange }) => (
          <RichText
            initialValue={description}
            toolbarItems={[ToolbarItem.Link]}
            onChange={onChange}
            placeholder="Type the stream description"
            error={errors?.description?.message}
            classNameWrapper={styles.richTextWrapper}
          />
        )}
      />

      <div className={styles.inputWithSwitchWrapper}>
        <TextField
          name="requestedPrice"
          aria-label="Requested price"
          placeholder="Price ($ USD)"
          ref={register}
          error={isFreeStream ? '' : errors?.requestedPrice?.message}
          className={styles.input}
          // className={styles.priceInput}
          readOnly={!!stream}
          notice="The final price will be increased due to the platform’s fee"
          disabled={isFreeStream}
        />

        <Switch
          wrapperClassName={styles.switch}
          label="Make it Free"
          name="isGoLiveFree"
          ref={register}
          disabled={!!stream}
          readOnly={!!stream}
        />
      </div>

      {!stream && (
        <div className={styles.testSwitchWrapper}>
          <Switch
            wrapperClassName={styles.switch}
            label="Test"
            name="isGoLiveHidden"
            ref={register}
            disabled={!!stream}
            readOnly={!!stream}
          />

          <Text className={styles.testInfo} color="black">
            Test streams are not visible on the platform
          </Text>
        </div>
      )}

      {image || previewImage ? (
        <div className={styles.previewImageWrapper}>
          {renderStreamImage()}
          <button
            className={styles.removePreviewButton}
            onClick={handlePreviewImageRemove}
            aria-label="Delete stream poster"
          >
            <Icon name="trash" className={styles.removePreviewIcon} />
          </button>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={cn(styles.dropzone, {
            [styles.error]: errors.previewImage,
          })}
        >
          <input {...getInputProps()} aria-label="upload stream image" />
          <div className={styles.dragArea}>
            <Icon name="upload" className={styles.dragAreaIcon} />
            <p className={styles.dragAreaText}>
              <span className={styles.dragLabel}>Drag & Drop or</span>
              <span className={styles.attachLabel}>Attach Cover Photo</span>
            </p>
          </div>
        </div>
      )}

      <p className={styles.imageRestrictionsText}>
        Image should be 343x190 px or more
      </p>

      <Button
        className={styles.submitButton}
        type="submit"
        loading={createStreamLoading}
      >
        Go live
      </Button>
    </form>
  );
};

export default CreateEditGoLiveStream;
