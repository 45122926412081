import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { components } from 'react-select';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Api
import { GET_MENTIONS_STORES } from 'api/store/queries';
// Types
import { StoreStatus, StoresOrderBy, UserRole } from 'api/graphql-global-types';
import {
  GetMentionsStores,
  GetMentionsStoresVariables,
} from 'api/store/types/GetMentionsStores';
// Ui
import { Mention, MillionsMention } from 'ui3/Mentions/Mentions';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Select from 'ui3/Select/Select';
// Styles
import styles from './MillionsMentionsSelect.module.scss';

type MillionsMentionsProps = {
  mention: Mention | null;
  index: number;
  onChange: any;
  handleMillionsMentionRemove: (index) => void;
};

const formattedMention = (mention) => ({
  label: mention?.store?.storeDetails?.storeName || '',
  value: mention?.store?.storeDetails?.storeId || '',
  avatarURL: mention?.store?.storeDetails?.avatarURL || '',
});

const reformattedMention = (option) => ({
  store: {
    storeDetails: {
      storeId: option.value,
      storeName: option.label,
      avatarURL: option.avatarURL,
    },
  },
  mentionType: 'MEDIA_POST',
  targetType: 'REGISTERED',
  unregisteredContact: null,
});

export const MillionsMentionsSelect = ({
  mention,
  onChange,
  index,
  handleMillionsMentionRemove,
}: MillionsMentionsProps) => {
  const [selectedOption, setSelectedOption] = useState<MillionsMention | null>(
    null
  );
  const { searchKey, search } = useFiltersContext();

  useEffect(() => {
    const formattedMentionsValue = formattedMention(mention);
    setSelectedOption(formattedMentionsValue);
  }, [mention]);

  const inputVariables = useMemo(
    () => ({
      offset: 0,
      limit: 10,
      ...(searchKey &&
        search[searchKey] && {
          [searchKey]: search[searchKey],
        }),
    }),
    [searchKey, search]
  );

  const { data: getStoresData, refetch } = useQuery<
    GetMentionsStores,
    GetMentionsStoresVariables
  >(GET_MENTIONS_STORES, {
    variables: {
      storeRoles: [
        UserRole.Athlete,
        UserRole.Organization,
        UserRole.ContentCreator,
      ],
      input: {
        ...inputVariables,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const storesData = getStoresData?.stores?.entities || [];

  const formattedStoresData = storesData.map((item) => ({
    value: item?.storeDetails?.storeId,
    label: item?.storeDetails?.storeName,
    avatarURL: item?.storeDetails?.avatarURL,
  }));

  const handleOnSearchStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: {
          storeName,
          orderBy: StoresOrderBy.storeName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const handleChange = (option) => {
    if (option) {
      const reformattedOptionValue = reformattedMention(option);
      onChange(reformattedOptionValue, index);
    }
  };

  const mentionRemove = (index) => {
    setSelectedOption(null);
    handleMillionsMentionRemove(index);
  };

  // Custom Option component to display avatar and label
  const CustomOption = (props) => (
    <components.Option {...props} className={styles.optionsValue}>
      <img
        src={props.data.avatarURL}
        alt={props.data.label}
        style={{ width: 30, height: 30, marginRight: 10, borderRadius: '20px' }}
      />
      {props.data.label}
    </components.Option>
  );

  // Custom SingleValue component to display selected option with avatar and label
  const CustomSingleValue = (props) => (
    <components.SingleValue {...props} className={styles.singleValue}>
      <img
        src={props.data.avatarURL || '/favicon.png'}
        alt={props.data.label}
        className={styles.imageAvatar}
      />
      {props.data.label ||
        'Search For An Athlete, Creator, or Organization Profile on MILLIONS'}
    </components.SingleValue>
  );

  return (
    <div className={styles.root}>
      <Select
        id="storeSearch"
        inputId="storeSearchInput"
        placeholder=""
        label=""
        onInputChange={handleOnSearchStores}
        options={formattedStoresData}
        defaultOptions={true}
        cacheOptions
        value={selectedOption}
        onChange={handleChange}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
      />
      {selectedOption && (
        <button
          className={styles.deleteButton}
          onClick={() => mentionRemove(index)}
          type="button"
          aria-label="Delete mention"
        >
          <MillionsIcon name="trash" className={styles.deleteIcon} />
        </button>
      )}
    </div>
  );
};

export default MillionsMentionsSelect;
