import { useRef, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
// Types
import { UserRole } from 'api/graphql-global-types';
// Hooks
import {
  useGetCurrUser,
  useLogout,
  useOnboardingContext,
  useOutsideClick,
} from 'hooks';
// Ui
import Image from 'ui/Image/Image';
import Text from 'ui3/Text/Text';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Components
import CreatorCard from '../CreatorCard/CreatorCard';
// Styles
import styles from './UserDropdown.module.scss';

const UserDropdown = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const { data } = useGetCurrUser();
  const logout = useLogout();
  const {
    setShowOnboardingModal,
    resetOnboardingState,
  } = useOnboardingContext();

  const user = data?.me;
  const avatarUrl = user?.avatarUrl;
  const isBasicUser = user?.role === UserRole.Customer;

  useOutsideClick(dropdownRef, () => {
    setIsDropdownVisible(false);
  });

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleLogoutClick = () => {
    resetOnboardingState();
    logout();
  };

  const handleOnboardingClick = () => {
    setShowOnboardingModal(true);
    setIsDropdownVisible(false);
  };

  return (
    <div className={styles.root} ref={dropdownRef}>
      <button type="button" className={styles.button} onClick={toggleDropdown}>
        {avatarUrl ? (
          <Image
            alt="User Avatar"
            className={styles.image}
            src={avatarUrl}
            sizes="5vw"
          />
        ) : (
          <MillionsIcon name="user" size={32} />
        )}
      </button>

      <ul
        aria-hidden={!isDropdownVisible}
        className={cn(styles.dropdown, {
          [styles.visible]: isDropdownVisible,
        })}
      >
        <li>
          <Link href="/dashboard" passHref>
            <a className={styles.option}>
              <MillionsIcon name="v3Dashboard" />
              <Text variant="subtitle2">Dashboard</Text>
            </a>
          </Link>
        </li>

        <li>
          <Link href="/orders" passHref>
            <a className={styles.option}>
              <MillionsIcon name="shoppingCart" />
              <Text variant="subtitle2">My Orders</Text>
            </a>
          </Link>
        </li>

        <li className={styles.divider} />

        <li>
          <Link href="/account-settings" passHref>
            <a className={styles.option}>
              <MillionsIcon name="cog" />
              <Text variant="subtitle2">Settings</Text>
            </a>
          </Link>
        </li>

        <li>
          <button
            data-testid="logout-button"
            type="button"
            className={cn(styles.option, styles.logoutButton)}
            onClick={handleLogoutClick}
          >
            <MillionsIcon name="logout" />
            <Text variant="subtitle2">Logout</Text>
          </button>
        </li>

        {isBasicUser && (
          <li>
            <CreatorCard onConfirmClick={handleOnboardingClick} />
          </li>
        )}
      </ul>
    </div>
  );
};

export default UserDropdown;
