import { AccountSubType } from 'api/graphql-global-types';

export const USER_SUBTYPES = [
  {
    value: AccountSubType.Athlete,
    label: 'Athlete',
  },
  {
    value: AccountSubType.ContentCreator,
    label: 'Content Creator',
  },
  {
    value: AccountSubType.EventOrganizer,
    label: 'Event Organizer',
  },
  {
    value: AccountSubType.Association,
    label: 'Association',
  },
  {
    value: AccountSubType.League,
    label: 'League',
  },
  {
    value: AccountSubType.Team,
    label: 'Team',
  },
  {
    value: AccountSubType.Gym,
    label: 'Gym',
  },
  {
    value: AccountSubType.Host,
    label: 'Host',
  },
  {
    value: AccountSubType.PodcastHost,
    label: 'Podcast',
  },
  {
    value: AccountSubType.Brand,
    label: 'Brand',
  },
  {
    value: AccountSubType.Shop,
    label: 'Shop',
  },
  {
    value: AccountSubType.Business,
    label: 'Business',
  },
  {
    value: AccountSubType.SocialPage,
    label: 'Social Page',
  },
  {
    value: AccountSubType.FanPage,
    label: 'Fan Page',
  },
];
