// Types
import { Interviewer } from 'components/UserScheduleInterview/helpers/localTypes';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import CalendarStepForm from './CalendarForm/CalendarForm';
// Styles
import styles from './CalendarStep.module.scss';

type CalendarStepProps = {
  isLoggedIn: boolean;
  interviewId: string | null;
  hostId: string | null;
  loggedUserId: string | null;
  interviewerName?: string;
  onInterviewerChange?: (interviewer: Interviewer) => void;
};

const CalendarStep = ({
  isLoggedIn,
  interviewId,
  hostId,
  loggedUserId,
  interviewerName,
  onInterviewerChange,
}: CalendarStepProps) => {
  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {isLoggedIn ? (
            <>
              <Text variant="h2">
                When are you available to do an interview?
              </Text>
              <Text variant="body1Regular16" color="lights-medium">
                Pick your best day/time for your interview on MILLIONS.
                Typically takes 30 mins.
              </Text>
            </>
          ) : (
            <>
              <Text variant="h2">Book your interview</Text>
              <Text variant="body1Regular16" color="lights-medium">
                Choose an available time to schedule your interview with{' '}
                {interviewerName}. Typically takes 30 mins.
              </Text>
            </>
          )}
        </div>
      }
      right={
        <CalendarStepForm
          interviewId={interviewId}
          hostId={hostId}
          loggedUserId={loggedUserId}
          onInterviewerChange={onInterviewerChange}
        />
      }
    />
  );
};

export default CalendarStep;
