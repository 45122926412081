import { gql } from '@apollo/client';
// Fragments
import { MemorabiliaFields, MemorabiliaFieldsAuthorized } from './fragments';

export const GET_MEMORABILIA = gql`
  query GetMemorabilia($input: MemorabiliaFilterInput!) {
    getMemorabilia(input: $input) {
      entities {
        ...MemorabiliaFields
      }
      filters {
        price
        sports {
          id
          name
        }
        types {
          name
        }
        saleMethods
      }
      limit
      offset
      total
    }
  }
  ${MemorabiliaFields}
`;

export const SEARCH_MEMORABILIA = gql`
  query SearchMemorabilia($input: MemorabiliaFilterInput!) {
    getMemorabilia(input: $input) {
      entities {
        id
        title
        memorabiliaProductType
      }
    }
  }
`;

/**
 * Get memorabilia products with minimal info to display in related products list
 */
export const GET_MEMORABILIA_MINIMAL_INFO = gql`
  query GetMemorabiliaMinimalInfo($input: MemorabiliaFilterInput!) {
    getMemorabilia(input: $input) {
      entities {
        id
        description
        images {
          id
          isMainImage
          url
        }
        memorabiliaProductType
        numberOfUnits
        status
        title
        price
        slug
        store {
          id
          slug
          storeDetails {
            storeName
          }
        }
      }
    }
  }
`;

export const GET_MEMORABILIA_AUTHORIZED = gql`
  query GetMemorabiliaAuthorized($input: MemorabiliaFilterInput!) {
    getMemorabilia(input: $input) {
      entities {
        ...MemorabiliaFieldsAuthorized
      }
      filters {
        price
        sports {
          id
          name
        }
        types {
          name
        }
      }
      limit
      offset
      total
    }
  }
  ${MemorabiliaFieldsAuthorized}
`;

export const GET_MEMORABILIA_BY_SLUG = gql`
  query GetMemorabiliaBySlug($slug: String!, $storeSlug: String) {
    getMemorabiliaBySlug(slug: $slug, storeSlug: $storeSlug) {
      id
      description
      images {
        id
        isMainImage
        url
      }
      memorabiliaProductType
      numberOfUnits
      status
      title
      price
      hashtags {
        id
        name
      }
      slug
      store {
        id
        slug
        hasMerch
        storeDetails {
          storeName
        }
      }
      isForAuction
      onAuctionFrom
      onAuctionTo
      auctions {
        id
        startsAt
        expiresAt
        currentPrice
        status
        startPrice
        isShippingPaid
        reservedBids {
          id
          actualAmount
          amount
          customerId
          paidAt
          shippingCost
        }
      }
    }
  }
`;

export const GET_MEMORABILIA_BY_SLUG_AUTHORIZED = gql`
  query GetMemorabiliaBySlugAuthorized($slug: String!) {
    getMemorabiliaBySlug(slug: $slug) {
      ...MemorabiliaFieldsAuthorized
    }
  }
  ${MemorabiliaFieldsAuthorized}
`;

export const GET_SHIPMENT_CATEGORIES = gql`
  query GetShipmentCategories {
    getShipmentCategories {
      slug
      name
    }
  }
`;

export const GET_ADDRESSES = gql`
  query GetAddresses($input: GetAddressesInput!) {
    getAddresses(input: $input) {
      id
      addressLine1
      addressLine2
      city
      company
      country
      countryCode
      email
      firstName
      isDefault
      lastName
      phone
      state
      stateCode
      zipCode
    }
  }
`;

export const ESTIMATE_PRODUCT_PRICE = gql`
  query EstimateProductPrice($input: ProductPriceEstimateInput!) {
    estimateProductPrice(input: $input) {
      platformFee
      price
      productFees {
        box
        inboundProcessingLoose
        inboundProcessingReceipt
        inboundShippingCost
        margin
        outboundOrderProcess
        packaging
        pickCost
        storage
      }
      totalFee
      warehouseFee
    }
  }
`;

export const ESTIMATE_AUCTION_DELIVERY_V2 = gql`
  query EstimateAuctionDeliveryV2($input: EstimateAuctionDeliveryInput!) {
    estimateAuctionDeliveryV2(input: $input) {
      shipping
      subtotal
      total
      taxesAndFeesTotal
    }
  }
`;

export const GET_STORE_MEMORABILIA = gql`
  query GetStoreMemorabilia($input: GetStoreMemorabiliaInput!) {
    getStoreMemorabilia(input: $input) {
      entities {
        ...MemorabiliaFields
      }
      limit
      offset
      total
    }
  }
  ${MemorabiliaFields}
`;
