import { useFormContext } from 'react-hook-form';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
import AdvancedPreferencesStep from './steps/AdvancedPreferncesStep';
import MinimumPriceStep from './steps/MinimalPriceStep';
import ProfileDetailsStep from './steps/ProfileDetailsStep';
// Ui3
import Text from 'ui3/Text/Text';
// Styles
import styles from './BrandDealSteps.module.scss';

type BrandDealBudgetStepFormProp = {
  selectedValue?: string;
  step: number;
  onStepChange?: (step: number) => void;
  initialValues?: any;
};

const BrandDealBudgetStep = ({
  step,
}: BrandDealBudgetStepFormProp): JSX.Element => {
  const { control, register, setValue, watch, errors } = useFormContext();

  const renderLeftContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            {step && <StepChip step={step} />}
            <Text variant="h2">Set Up Your Minimums</Text>
            <Text variant="body1Regular16">
              Set a minimum price for brand deals to be invited and offered only
              deals you want.
            </Text>
          </>
        );
      case 2:
        return (
          <>
            {step && <StepChip step={step} />}
            <Text variant="h2">Setup Advanced Preferences</Text>
            <Text variant="body1Regular16">
              Set up your advanced preferences for a better deal matching
              experience.
            </Text>
          </>
        );
      case 3:
        return (
          <>
            {step && <StepChip step={step} />}
            <Text variant="h2">Tell Us More About Yourself</Text>
            <Text variant="body1Regular16">
              Provide additional details to help us tailor your brand
              experience.
            </Text>
          </>
        );
      default:
        return null;
    }
  };

  const renderRightContent = () => {
    switch (step) {
      case 1:
        return (
          <MinimumPriceStep
            register={register}
            setValue={setValue}
            watch={watch}
            errors={errors}
          />
        );
      case 2:
        return (
          <AdvancedPreferencesStep
            register={register}
            setValue={setValue}
            watch={watch}
          />
        );
      case 3:
        return (
          <ProfileDetailsStep
            control={control}
            setValue={setValue}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SplitLeftRightView
        left={<div className={styles.left}>{renderLeftContent()}</div>}
        right={<div className={styles.right}>{renderRightContent()}</div>}
      />
    </>
  );
};

export default BrandDealBudgetStep;
