import { useRouter } from 'next/router';
import cn from 'classnames';
// Types
import { Theme } from 'layouts/Header/Header';
// Constants
import { ORDER_MERCH } from 'constants/routes';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';

import styles from './ShoppingCartButton.module.scss';

type ShoppingCartButtonProps = {
  merchCounter?: number | null;
  theme?: Theme;
  className?: string;
};

const ShoppingCartButton = ({
  merchCounter = 0,
  theme = 'black',
  className,
}: ShoppingCartButtonProps) => {
  const { push } = useRouter();

  const merchCounterValue = (merchCounter ?? 0) <= 9 ? merchCounter : '9+';

  const handleShoppingCartClick = () => {
    push(ORDER_MERCH);
  };

  if (merchCounter === null) return null;

  return (
    <button
      className={cn(styles.root, className, {
        [styles[`theme-${theme}`]]: theme,
      })}
      onClick={handleShoppingCartClick}
    >
      <MillionsIcon name="bag" className={styles.cartIcon} />

      <span className={styles.cartCounter}>{merchCounterValue}</span>
    </button>
  );
};

export default ShoppingCartButton;
