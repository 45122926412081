import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { parse } from 'query-string';
// Types
import { SignUpFlow } from 'api/graphql-global-types';
// Constants
import { PROFILE_SETUP, DASHBOARD } from 'constants/routes';
import { STORE_USER_FIRST_LOGIN } from 'constants/auth';
// Helper
import { isStoreRole } from 'helpers/user';
// Hooks
import { useGetCurrUser, useAppContext } from 'hooks';

const OnboardingWatcher = () => {
  const { push, pathname, query, asPath } = useRouter();

  const { withOnboardingWatcher, setLoginModalVisibility } = useAppContext();

  const { data } = useGetCurrUser();

  const isLoggedIn = !!data?.me?.id;

  const isNotOnboardedStore =
    isStoreRole(data?.me.role) && !data?.me?.isOnboarded;
  const isOnboardedStore = isStoreRole(data?.me.role) && data?.me?.isOnboarded;
  const isQuickSignUpFlow =
    data?.me?.storeDetails?.signUpFlow === SignUpFlow.QuickFlow;
  const parsedUrl = parse(asPath);
  const code = parsedUrl['/#code'] as string;
  useEffect(() => {
    const formRedirect = localStorage.getItem('formRedirect') !== 'false';

    if (
      !query?.oauth_token &&
      !code &&
      isLoggedIn &&
      isNotOnboardedStore &&
      withOnboardingWatcher &&
      !isQuickSignUpFlow &&
      formRedirect &&
      pathname !== '/user-schedule-interview/interview' &&
      pathname !== '/user-schedule-interview/host' &&
      pathname !== '/user-schedule-interview' &&
      pathname !== '/manage-merch'
    ) {
      push(PROFILE_SETUP);
      // this is fix for case of social login for Athlete, Organization or ContentCreator
      // for isNotOnboardedStore, redirect interupts login modal close so it stays open
      setLoginModalVisibility(false);
    } else if (
      !query?.oauth_token &&
      !code &&
      isLoggedIn &&
      isQuickSignUpFlow &&
      isNotOnboardedStore &&
      pathname !== '/user-schedule-interview/interview' &&
      pathname !== '/user-schedule-interview/host' &&
      pathname !== '/user-schedule-interview' &&
      pathname !== '/dashboard' &&
      withOnboardingWatcher
    ) {
      push(PROFILE_SETUP);
      setLoginModalVisibility(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotOnboardedStore, withOnboardingWatcher]);

  useEffect(() => {
    if (pathname === '/') {
      if (
        !code &&
        isOnboardedStore &&
        !query.redirectPath &&
        localStorage.getItem(STORE_USER_FIRST_LOGIN) &&
        localStorage.getItem(STORE_USER_FIRST_LOGIN) === 'true'
      ) {
        localStorage.setItem(STORE_USER_FIRST_LOGIN, 'false');
        push(DASHBOARD);
      }
    }
  }, [isOnboardedStore, code, pathname, push, query.redirectPath]);

  return null;
};

export default OnboardingWatcher;
