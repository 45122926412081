import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
// Types
import { MyStreams_myStreams_entities } from 'api/streams/types/MyStreams';
import { GetStream_stream } from 'api/streams/types/GetStream';
import { GetStreamExtended_stream } from 'api/streams/types/GetStreamExtended';
import {
  GetMediaPost_getMediaPost_ImagePost,
  GetMediaPost_getMediaPost_VideoPost,
} from 'api/mediaPost/types/GetMediaPost';
// Constants
import { TIME_ZONE_OPTIONS, TimeZoneOption } from 'constants/timeZone';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsIsBetween);

export const getCurrentTimeZoneOffset = (): number => {
  const clientLocalDate = new Date();
  return -clientLocalDate.getTimezoneOffset();
};

export const getDateWithoutCurrentTimeZone = (
  date?: string | null
): Date | string =>
  date?.length
    ? dayjs(
        dayjs(date).subtract(getCurrentTimeZoneOffset(), 'minute').toISOString()
      ).toDate()
    : '';

export const getDateInOriginalTimeZone = (
  date: string | null,
  format?: string
): string => {
  if (date && dayjs(date).isValid()) {
    const dateInTimeZone = dayjs(date).subtract(
      getCurrentTimeZoneOffset(),
      'minute'
    );
    return dayjs(dateInTimeZone).format(format || 'YYYY-MM-DDThh:mm:ss.sssZ');
  }
  return '\u2014';
};

export const getTimeZoneObject = (
  stream:
    | MyStreams_myStreams_entities
    | GetStream_stream
    | GetStreamExtended_stream
    | null
    | undefined
): TimeZoneOption => {
  const streamTimeZone: TimeZoneOption | undefined = TIME_ZONE_OPTIONS.find(
    (i) => i.tzCode === stream?.timeZone?.tzCode
  );
  return streamTimeZone || TIME_ZONE_OPTIONS[0];
};

export const getCurrentTimeZone = () => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTimeZoneOption =
    TIME_ZONE_OPTIONS.find((tz) => tz.tzName === currentTimeZone) ||
    TIME_ZONE_OPTIONS[0];

  return currentTimeZoneOption;
};

export const getMediaPostTimeZoneObject = (
  mediaPost:
    | GetMediaPost_getMediaPost_ImagePost
    | GetMediaPost_getMediaPost_VideoPost
    | null
    | undefined
): TimeZoneOption => {
  const mediaPostTimeZone: TimeZoneOption | undefined = TIME_ZONE_OPTIONS.find(
    (i) => i.tzCode === mediaPost?.scheduledAtTzCode
  );
  return mediaPostTimeZone || TIME_ZONE_OPTIONS[0];
};

export const convertToUtcDate = (
  date: string | null | Date | undefined,
  timeZoneOffset: number
): string => {
  if (date && dayjs(date).isValid()) {
    const utcDate = dayjs(date).subtract(timeZoneOffset, 'minute');
    return utcDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }
  return '\u2014';
};

export const convertToLocalDateFromUtcDate = (
  date: string,
  timeZoneOffset: number | null
) => {
  if (date && dayjs(date).isValid()) {
    const totalOffset = (timeZoneOffset || 0) + new Date().getTimezoneOffset();
    return dayjs(date)
      .add(totalOffset || 0, 'minute')
      .toDate();
  }
  return '\u2014';
};
