import {
  forwardRef,
  MouseEventHandler,
  ComponentPropsWithRef,
  Children,
  cloneElement,
} from 'react';
import cn from 'classnames';
// UI
import MillionsIcon, { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from './TextField.module.scss';

type BasicProps = {
  name: string;
  label?: string;
  labelClassName?: string;
  error?: string | boolean;
  customTextField?: JSX.Element;
  notice?: string;
  prefixIcon?: IconNames;
  action?: {
    icon: IconNames;
    onClick: MouseEventHandler<HTMLButtonElement>;
    title?: string;
  };
};

type InputType = 'text' | 'number' | 'email' | 'search' | 'password' | 'tel';

type InputProps = {
  type?: InputType;
  fieldSize?: 'default' | 'small';
} & React.InputHTMLAttributes<HTMLInputElement>;

type TextareaProps = {
  type: 'textarea';
  fieldSize?: never;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

type TextFieldProps = BasicProps & (InputProps | TextareaProps);

type BaseInputProps =
  | ({
      type?: InputType;
    } & ComponentPropsWithRef<'input'>)
  | ({
      type: 'textarea';
    } & ComponentPropsWithRef<'textarea'>);

const BaseInput = forwardRef(function BaseInput(
  props: BaseInputProps,
  ref: React.ForwardedRef<any>
) {
  if (props.type === 'textarea') {
    return <textarea ref={ref} rows={4} {...props} />;
  }

  return <input ref={ref} {...props} />;
});

const TextField = forwardRef(
  (props: TextFieldProps, ref: React.ForwardedRef<any>) => {
    const {
      name,
      label,
      labelClassName,
      error,
      className,
      action,
      disabled = false,
      id = name,
      fieldSize = 'default',
      customTextField,
      type = 'text',
      notice,
      prefixIcon,
      ...rest
    } = props;

    const isError = Boolean(error);
    const isErrorLabelVisible = typeof error === 'string' && !!error.length;
    const textFieldId = `${id}-text-field`;
    const errorId = `${id}-error-text`;
    const ariaDescribedby = isError ? errorId : undefined;
    const actionIcon = action?.icon;

    const actionButton = actionIcon ? (
      <button
        className={styles.actionButton}
        onClick={disabled ? undefined : action.onClick}
        disabled={disabled}
        type="button"
        aria-label={action.title || 'submit'}
        title={action.title || 'submit'}
      >
        <MillionsIcon name={actionIcon} className={styles.actionIcon} />
      </button>
    ) : null;

    return (
      <div
        className={cn(
          styles.root,
          {
            [styles[`size-${fieldSize}`]]: !!fieldSize,
            [styles.error]: isError,
            [styles.unlabeled]: !label,
            [styles.disabled]: disabled,
            [styles.multiline]: type === 'textarea',
            [styles.withAction]: !!action?.icon,
          },
          className
        )}
      >
        <div className={styles.entry}>
          {label && (
            <label
              htmlFor={textFieldId}
              className={cn(styles.label, labelClassName)}
            >
              {label}
            </label>
          )}

          <div
            className={cn(
              styles.textFieldWrapper,
              prefixIcon ? styles.prefix : ''
            )}
          >
            {prefixIcon ? (
              <MillionsIcon name={prefixIcon} className={styles.prefixIcon} />
            ) : null}
            {customTextField ? (
              Children.map(customTextField, (child) => {
                return cloneElement(
                  child,
                  {
                    className: cn(
                      customTextField.props.className,
                      styles.input
                    ),
                  },
                  null
                );
              })
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BaseInput
                  ref={ref}
                  type={type}
                  id={textFieldId}
                  name={name}
                  aria-invalid={isError}
                  aria-describedby={ariaDescribedby}
                  disabled={disabled}
                  className={styles.input}
                  {...rest}
                />
              </div>
            )}
          </div>
          {actionButton}
        </div>

        {isErrorLabelVisible && (
          <span
            id={errorId}
            className={styles.errorLabel}
            role="alert"
            aria-label={errorId}
          >
            {error}
          </span>
        )}

        {notice && <span className={styles.noticeLabel}>{notice}</span>}
      </div>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
