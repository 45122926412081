import cn from 'classnames';
import { startCase } from 'lodash';
// Types
import { CardSize } from '../BaseCard/BaseCard';
import { ContentStreamStatus, ContentType } from '../ContentCard';
import { ProductType } from '../ProductCard';
// UI
import { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from './CardLabel.module.scss';
import { StreamType } from 'api/graphql-global-types';

export type LabelVariant =
  | 'primary'
  | 'secondary'
  | 'disabled'
  | 'error'
  | 'live'
  | 'accent'
  | 'text'
  | 'inStock'
  | 'outOfStock';

export type CardLabelIconNames =
  | ContentType
  | ContentStreamStatus.HIDDEN
  | ProductType
  | StreamType;

export type BasicCardLabelProps = {
  text?: string;
  size?: CardSize;
  variant?: LabelVariant;
  onlyIcon?: boolean;
  isCurrency?: boolean;
};

export type CardLabelProps = React.ComponentPropsWithoutRef<'div'> &
  BasicCardLabelProps;

const iconMap: Record<string, IconNames> = {
  interview: 'microphoneOutline',
  stream: 'stream',
  image: 'image',
  video: 'video',
  merch: 'shirtOutline',
  memorabilia: 'award',
  product: 'shoppingBag',
  experience: 'starOutline',
  hidden: 'eyeOff',
  live: 'stream',
  ama: 'record',
};

const CardLabel: React.FC<CardLabelProps> = ({
  text,
  size = 'small',
  variant = 'primary',
  onlyIcon = false,
  isCurrency = false,
  className,
  ...rest
}) => {
  const resolvedIcon = text ? iconMap[text.toLowerCase()] : null;
  const labelText = text === 'Ama' ? 'Personal Video' : text;

  return (
    <div
      className={cn(styles.root, styles[size], styles[variant], className)}
      {...rest}
    >
      {resolvedIcon && (
        <MillionsIcon name={resolvedIcon} size={16} className={styles.icon} />
      )}
      {onlyIcon ? null : (
        <p className={styles.label}>
          {isCurrency ? labelText : startCase(labelText)}
        </p>
      )}
    </div>
  );
};

export default CardLabel;
