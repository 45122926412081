import { useFormContext, Controller } from 'react-hook-form';
// Context
import { OnboardingFormData } from 'context/OnboardingContext';
// Components
import SocialMediaFields from '../SocialMediaFields/SocialMediaFields';

// Styles
import styles from './SocialMediaForm.module.scss';

const SocialMediaForm = () => {
  const { errors, control } = useFormContext<OnboardingFormData>();

  return (
    <div className={styles.root}>
      <Controller
        name="socialAccounts"
        control={control}
        render={({ onChange, value }) => {
          return (
            <SocialMediaFields
              socialAccounts={value}
              onChange={onChange}
              errors={errors.socialAccounts}
            />
          );
        }}
      />
    </div>
  );
};

export default SocialMediaForm;
