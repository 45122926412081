import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Text from 'ui3/Text/Text';
// Components
import EasyImageCrop from '../EasyImageCrop/EasyImageCrop';
// Styles
import styles from './UploadProfileImage.module.scss';

type UploadProfileImageProps = {
  avatar?: File;
  imageUrl?: string;
  onFileChange: (file: File | null) => void;
};

const UploadProfileImage = ({
  avatar,
  imageUrl,
  onFileChange,
}: UploadProfileImageProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // necessary to reset EasyImageCrop state
      onFileChange(null);
      onFileChange(acceptedFiles[0]);
    },
    maxFiles: 1,
    maxSize: 5242880, // 5MB
    accept: 'image/jpg, image/jpeg, image/png',
  });

  const hasImage = Boolean(imageUrl || avatar);

  const handleApplyImage = useCallback(
    (image: File) => {
      onFileChange(image);
    },
    [onFileChange]
  );

  const imagePreview = useMemo(() => {
    if (avatar) {
      return (
        <EasyImageCrop
          className={styles.imageCropper}
          imageFile={avatar}
          onApplyImage={handleApplyImage}
        />
      );
    }

    return <img src={imageUrl} alt="Profile avatar" className={styles.image} />;
  }, [avatar, handleApplyImage, imageUrl]);

  return (
    <div
      className={cn(styles.root, {
        [styles.hasImage]: hasImage,
      })}
    >
      {hasImage ? (
        <div className={styles.imageContainer}>
          {imagePreview}
          <button
            type="button"
            {...getRootProps()}
            className={styles.editButton}
          >
            <input {...getInputProps()} />

            <MillionsIcon name="pencil" />
          </button>
        </div>
      ) : (
        <div {...getRootProps()} className={styles.dropzone}>
          <input {...getInputProps()} />

          <MillionsIcon name="image" size={32} />

          <div className={styles.text}>
            <Text variant="subtitle1">Upload profile image</Text>

            <Text variant="body2Regular">
              Image must be max. 5mb in JPEG od PNG format.
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadProfileImage;
