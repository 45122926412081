import React, { FC, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import cn from 'classnames';
// Ui2
import Text from 'ui3/Text/Text';
// Styles
import styles from './PhoneNumberInput.module.scss';
import 'react-phone-input-2/lib/style.css';

interface PhoneNumberInputProps {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  value: string;
  onChange?: (event: string) => void;
  onBlur?: () => void;
  classNames?: string;
  error?: string;
  upwards?: boolean;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  name,
  label,
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  classNames,
  error,
  upwards = false,
}) => {
  const preferredCountries = ['us', 'ca'];
  const containerClassName = `${styles.phoneNumberContainer} ${
    disabled ? styles.disabledContainer : ''
  }`;
  const phoneInputRef = useRef<HTMLInputElement | null>(null);

  const handleContainerClick = () => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  };

  const handleOnChange = (phone: string) => {
    if (phone === '') {
      onChange && onChange('');
    } else {
      onChange && onChange('+' + phone); // input only registers numbers, but BE is set to recognize international numbers, thus the plus character
    }
  };

  useEffect(() => {
    if (value) {
      const formattedPhoneNumber = value.includes('+')
        ? value.replace('+', '')
        : value;
      handleOnChange(formattedPhoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const errorId = `${name}-error-text`;

  return (
    <div className={cn(containerClassName, classNames)}>
      <label className={styles.phoneNumberLabel} htmlFor={name}>
        {label}
      </label>
      <PhoneInput
        onClick={handleContainerClick}
        value={value}
        onChange={handleOnChange}
        inputProps={{
          name: name,
          placeholder: placeholder,
          disabled: disabled,
          className: `${styles.phoneNumberInput} ${
            error ? styles.errorInput : ''
          }`,
          onBlur: onBlur,
        }}
        containerClass={styles.phoneNumberInputContainer}
        buttonClass={styles.phoneNumberButton}
        preferredCountries={preferredCountries}
        dropdownClass={cn(styles.flagDropdown, styles.open, {
          [styles.upwards]: upwards,
        })}
        countryCodeEditable={false}
        country="us"
      />

      {error && (
        <Text variant="captionRegular" id={errorId} color="error-default">
          {error}
        </Text>
      )}
    </div>
  );
};

export default PhoneNumberInput;
