import { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Text from 'ui3/Text/Text';
// Components
import { UploadImage } from '../UploadMultipleImages';
import ImageItem from './ImageItem/ImageItem';
// Styles
import styles from './MultipleImagesList.module.scss';

type MultipleImagesProps = {
  imageList: UploadImage[];
  onImageRemove: (index: number) => void;
  onImageUpload: () => void;
  setMainImageIndex: Dispatch<SetStateAction<number>>;
  mainImageIndex: number | null;
};

const MultipleImagesList = ({
  imageList,
  onImageRemove,
  onImageUpload,
  setMainImageIndex,
  mainImageIndex,
}: MultipleImagesProps): JSX.Element => {
  const handleImageRemove = (removeIndex: number) => {
    if (mainImageIndex === removeIndex) {
      setMainImageIndex(0);
    }
    onImageRemove(removeIndex);
  };

  return (
    <div className={styles.imagesList}>
      {imageList.map((image, index) => (
        <ImageItem
          image={image}
          index={index}
          key={uuidv4()}
          mainImageIndex={mainImageIndex}
          setMainImageIndex={setMainImageIndex}
          onImageRemove={handleImageRemove}
        />
      ))}
      {imageList.length < 10 && (
        <button
          type="button"
          className={cn(styles.addNewButton, {
            [styles.withImage]: imageList.length > 0,
          })}
          onClick={onImageUpload}
        >
          <div className={styles.iconContainer}>
            <MillionsIcon name="solidAdd" size={34} />
          </div>
          <Text variant="subtitle1"> Upload image</Text>
          <Text variant="body3Regular" color="lights-medium">
            Image must be max. 5mb in JPEG or PNG format.
          </Text>
        </button>
      )}
    </div>
  );
};

export default MultipleImagesList;
