import { useState, useRef } from 'react';
import cn from 'classnames';
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
// Types
import { BaseElement } from 'slate';
// Helpers
import { removeLink } from 'components/common2/RichText/helpers/link';
// Hooks
import { useOutsideClick } from 'hooks';
// Ui
import Button from 'ui3/Button/Button';
import Icon from 'ui/Icon/Icon';
// Styles
import styles from './Link.module.scss';

export type LinkProps = BaseElement & {
  element: BaseElement & { href?: string };
};

const Link: React.FC<LinkProps> = ({ element, children }) => {
  const popoverContentRef = useRef(null);
  const editor = useSlateStatic() as ReactEditor;
  const selected = useSelected();
  const focused = useFocused();
  const [showPopover, setShowPopover] = useState(false);
  useOutsideClick(popoverContentRef, () => setShowPopover(false));

  const handleRemoveLink = () => {
    removeLink(editor);
    setShowPopover(false);
  };

  const { href } = element;
  const isActive = selected && focused;

  const handleOnLinkClick = () => {
    window.open(href, '_blank');
  };

  const handleOnShowPopup = () => {
    setShowPopover(true);
  };

  return (
    <span>
      <Tooltip
        className={styles.tooltipRoot}
        classNameArrow={styles.tooltipArrow}
        isOpen={showPopover}
        anchorId={href}
        clickable={true}
        offset={30}
      >
        <div
          className={styles.content}
          ref={popoverContentRef}
          contentEditable={false}
        >
          <button
            className={cn(styles.linkButton, styles.btnMargin)}
            title={`Visit ${href}`}
            onClick={handleOnLinkClick}
          >
            {href}
          </button>

          <Button
            className={cn(styles.button, styles.btnMargin)}
            size="small"
            color="transparent"
            title={`Go to ${href}`}
            onClick={handleOnLinkClick}
          >
            <Icon name="v2-open-new" />
          </Button>

          <Button
            className={styles.button}
            size="small"
            color="maximum-red"
            onClick={handleRemoveLink}
            title="Remove link"
            type="button"
          >
            <Icon name="v2-link-off" />
          </Button>
        </div>
      </Tooltip>

      <button
        className={cn(styles.cta, { [styles.isActive]: isActive })}
        id={href}
        type="button"
        onClick={handleOnShowPopup}
      >
        {children}
      </button>
    </span>
  );
};

export default Link;
