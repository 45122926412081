// Ui
import Input from 'ui3/Input/Input';
import Button from 'ui3/Button/Button';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './CopyLink.module.scss';

type CopyLinkProps = {
  name: string;
  label: string;
  link: string;
};

const CopyLink = ({ name, label, link }: CopyLinkProps) => {
  const handleCopyLinkButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(link);

      showToast({
        message: 'Copied',
        autoClose: 1000,
      });
    } catch (error) {
      showToast({
        message: 'Could not copy text',
        type: 'error',
        autoClose: 1000,
      });
    }
  };

  return (
    <div className={styles.root}>
      <Input
        label={label}
        name={name}
        aria-label={label}
        readOnly
        value={link}
      />

      <Button
        aria-label={`Copy ${name} link`}
        variant="secondary"
        icon="share"
        onClick={handleCopyLinkButtonClick}
        className={styles.button}
      />
    </div>
  );
};

export default CopyLink;
