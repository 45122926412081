import React, { FC, useEffect, useRef, useState } from 'react';
// Types
import { ContactType } from 'api/graphql-global-types';
// Ui
import { Mention } from 'ui3/Mentions/Mentions';
import Button from 'ui3/Button/Button';
// Components
import UnregisteredMentionsSelect from '../UnregisteredMentionsSelect/UnregisteredMentionsSelect';
// Styles
import styles from './OutsideMillionsAthletesSelect.module.scss';
import Text from 'ui3/Text/Text';

interface OutsideMillionsSelectProps {
  mentions: Mention[];
  onChange: (values: Mention[]) => void;
}

export const OutsideMillionsAthletesSelect: FC<OutsideMillionsSelectProps> = ({
  mentions,
  onChange,
}) => {
  const [
    unregisteredAthletesMentions,
    setUnregisteredAthletesMentions,
  ] = useState<Mention[]>(mentions);
  const previousMentionsRef = useRef(unregisteredAthletesMentions);

  useEffect(() => {
    if (mentions && mentions.length > 0) {
      setUnregisteredAthletesMentions(mentions);
    }
  }, [mentions]);

  const handleUnregisteredAthleteChange = (mention, index) => {
    setUnregisteredAthletesMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions[index] = mention;

      return updatedMentions;
    });
  };

  useEffect(() => {
    // Compare the previous and current mentions arrays
    if (
      JSON.stringify(previousMentionsRef.current) !==
      JSON.stringify(unregisteredAthletesMentions)
    ) {
      // Only trigger onChange if the values have actually changed
      onChange(unregisteredAthletesMentions);
      previousMentionsRef.current = unregisteredAthletesMentions; // Update the ref with the current mentions
    }
  }, [unregisteredAthletesMentions, onChange]);

  const addNewAthletesMention = () => {
    setUnregisteredAthletesMentions((prevMentions) => [
      ...prevMentions,
      {
        id: null,
        targetType: null,
        user: null,
        unregisteredContact: null,
      },
    ]);
  };

  const handleOtherAthleteMentionRemove = (index: number) => {
    setUnregisteredAthletesMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions.splice(index, 1);
      return updatedMentions;
    });
  };

  return (
    <div className={styles.root}>
      <Text variant="subtitle2">Athletes outside of MILLIONS</Text>

      <Text variant="body2Regular" color="lights-medium">
        Mention athletes by using their names and links to directly engage them
        and increase interaction on your content.
      </Text>

      <div className={styles.athletesSelectContainer}>
        {unregisteredAthletesMentions.length === 0 ? (
          <UnregisteredMentionsSelect
            mention={null}
            index={0}
            type={ContactType.ATHLETE}
            onChange={handleUnregisteredAthleteChange}
            handleMillionsMentionRemove={handleOtherAthleteMentionRemove}
          />
        ) : (
          unregisteredAthletesMentions.map((mention, index) => (
            <div key={mention.id || `mention-${index}`}>
              <UnregisteredMentionsSelect
                mention={mention}
                index={index}
                type={ContactType.ATHLETE}
                onChange={handleUnregisteredAthleteChange}
                handleMillionsMentionRemove={handleOtherAthleteMentionRemove}
              />
            </div>
          ))
        )}

        {unregisteredAthletesMentions.length < 5 && (
          <div>
            <Button
              variant="primary"
              subVariant="text"
              type="button"
              icon="plus"
              className={styles.addNewButton}
              onClick={addNewAthletesMention}
            >
              Add new
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutsideMillionsAthletesSelect;
