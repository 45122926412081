import React from 'react';
// Types
import { PlacesType } from 'react-tooltip';
// Components
import MarkButton from 'components/common3/RichText/components/MarkButton/MarkButton';
import LinkButton from 'components/common3/RichText/components/LinkButton/LinkButton';
// Styles
import styles from './Toolbar.module.scss';

export enum ToolbarItem {
  Mark = 'Mark',
  Link = 'Link',
}

type ToolbarProps = {
  items?: ToolbarItem[];
  toolbarPosition?: PlacesType;
};

const Toolbar = ({
  items,
  toolbarPosition,
}: ToolbarProps): JSX.Element | null => {
  if (!items?.length) {
    return null;
  }

  return (
    <span className={styles.root}>
      {items.includes(ToolbarItem.Mark) && <MarkButton />}
      {items.includes(ToolbarItem.Link) && (
        <LinkButton toolbarPosition={toolbarPosition} />
      )}
    </span>
  );
};

export default Toolbar;
