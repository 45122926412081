import { useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
// API
import { SWITCH_TO_CREATOR } from 'api/auth/mutations';
import { CREATE_PRESIGNED_AVATAR_URL } from 'api/upload/mutations';
// Types
import {
  SwitchToCreator,
  SwitchToCreatorVariables,
} from 'api/auth/types/SwitchToCreator';
import {
  CreatePresignedAvatarUrl,
  CreatePresignedAvatarUrlVariables,
} from 'api/upload/types/CreatePresignedAvatarUrl';
// Context
import { OnboardingFormData } from 'context/OnboardingContext';
// Helpers
import { yupInternationalPhoneValidation } from 'helpers/validation';
import { uploadImagesToS3 } from 'helpers/single-uploader';
import { createUsernameValidation } from 'helpers/usernameValidation';
// Hooks
import { useGetCurrUser, useOnboardingContext } from 'hooks';
// Components
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import ProfileStep from './ProfileStep/ProfileStep';
import BasicInfoStep from './BasicInfoStep/BasicInfoStep';
import SocialMediaStep from './SocialMediaStep/SocialMediaStep';
import { showToast } from 'components/common/Toast/Toast';

const validationSchema = (step: number) => {
  if (step === 1) {
    return yup.object().shape({
      username: createUsernameValidation(),
      profileName: yup.string().required('Profile name is required'),
      avatar: yup.string().required('Avatar is required'),
    });
  }
  if (step === 2) {
    return yup.object().shape({
      accountType: yup.object().required('Account type is required'),
      experienceLevel: yup
        .object()
        .nullable()
        .when(['accountType'], {
          is: (accountType) => accountType?.value === 'Athlete',
          then: yup
            .object()
            .shape({
              value: yup.string().required('Experience level is required'),
              label: yup.string().required('Experience level is required'),
            })
            .required('Experience level is required'),
        }),
      bio: yup.string().max(300, 'Bio must be less than 300 characters'),
      sport: yup.object().required('Sport is required'),
      phoneNumber: yupInternationalPhoneValidation(),
      phoneOptIn: yup.boolean(),
      location: yup.object().required('Location is required'),
    });
  }

  if (step === 3) {
    return yup.object().shape({
      socialAccounts: yup
        .array()
        .of(
          yup.object().shape({
            platform: yup.string().required('Platform is required'),
            link: yup
              .string()
              .url('Link must be a valid URL')
              .required('Link is required'),
          })
        )
        .required('At least one social account is required'),
    });
  }

  return yup.object().shape({});
};

const Onboarding = () => {
  const { data, loading: userDataLoading } = useGetCurrUser();

  const { setShowCreatorSetupModal } = useOnboardingContext();

  const [switchToCreator, { loading: loadingSwitchToCreator }] = useMutation<
    SwitchToCreator,
    SwitchToCreatorVariables
  >(SWITCH_TO_CREATOR, {
    onCompleted: () => {
      handleModalClose();
      setShowCreatorSetupModal(true);
    },
  });
  const [
    createPresignedAvatarUrl,
    { loading: loadingCreatePresignedUrl },
  ] = useMutation<CreatePresignedAvatarUrl, CreatePresignedAvatarUrlVariables>(
    CREATE_PRESIGNED_AVATAR_URL
  );

  const loading = loadingSwitchToCreator || loadingCreatePresignedUrl;

  const {
    onboardingStep,
    showOnboardingModal,
    onboardingData,
    setShowOnboardingModal,
    setOnboardingStep,
    resetOnboardingStateAfterSwitch,
    setOnboardingData,
  } = useOnboardingContext();

  const methods = useForm<OnboardingFormData>({
    resolver: yupResolver(validationSchema(onboardingStep)),
    defaultValues: {
      username: '',
      profileName: '',
      avatar: '',
      accountType: undefined,
      experienceLevel: undefined,
      sport: undefined,
      bio: '',
      phoneNumber: '',
      phoneOptIn: false,
      location: undefined,
      socialAccounts: [
        {
          platform: undefined,
          link: '',
          isAdded: false,
        },
      ],
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  useEffect(() => {
    if (data?.me) {
      methods.reset({
        username: data?.me?.slug || '',
        avatar: data?.me?.avatarUrl || '',
        phoneNumber: data?.me?.phoneNumber || '',
        phoneOptIn: data?.me?.phoneOptIn || false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleModalClose = useCallback(() => {
    setShowOnboardingModal(false);
    resetOnboardingStateAfterSwitch();
    methods.reset({});
  }, [setShowOnboardingModal, resetOnboardingStateAfterSwitch, methods]);

  useEffect(() => {
    if (!userDataLoading && showOnboardingModal && !data?.me) {
      handleModalClose();
    }
  }, [data, userDataLoading, showOnboardingModal, handleModalClose]);

  const handlePrevStepClick = useCallback(() => {
    if (onboardingStep > 1) {
      setOnboardingStep(onboardingStep - 1);
    }
  }, [onboardingStep, setOnboardingStep]);

  const handleSubmit = methods.handleSubmit(
    async (values) => {
      try {
        if (onboardingStep === 1) {
          setOnboardingData(values);
          setOnboardingStep(2);
        }

        if (onboardingStep === 2) {
          setOnboardingData(values);
          setOnboardingStep(3);
        }

        if (onboardingStep === 3) {
          if (
            onboardingData &&
            onboardingData.phoneNumber &&
            onboardingData.accountType &&
            onboardingData.username &&
            onboardingData.profileName
          ) {
            let avatarKey = '';
            if (
              onboardingData.avatar &&
              typeof onboardingData.avatar !== 'string'
            ) {
              const filenameParts = onboardingData.avatar.name.split('.');

              const {
                data: presignedUrlsData,
              } = await createPresignedAvatarUrl({
                variables: {
                  input: {
                    ext: filenameParts[filenameParts.length - 1],
                    contentType: onboardingData.avatar.type,
                  },
                },
              });

              if (presignedUrlsData?.createPresignedAvatarUrl) {
                await uploadImagesToS3([
                  {
                    ...presignedUrlsData.createPresignedAvatarUrl,
                    file: onboardingData.avatar,
                  },
                ]);

                avatarKey = presignedUrlsData.createPresignedAvatarUrl.key;
              }
            }

            await switchToCreator({
              variables: {
                input: {
                  username: onboardingData.username,
                  profileName: onboardingData.profileName,
                  ...(avatarKey && { avatarKey }),
                  ...(onboardingData.bio && { bio: onboardingData.bio }),
                  ...(onboardingData.location && {
                    country: onboardingData.location.countryCode,
                    city: onboardingData.location.city,
                    ...(onboardingData.location.state && {
                      state: onboardingData.location.stateCode,
                    }),
                    location: onboardingData.location.coordinates,
                  }),
                  ...(onboardingData.experienceLevel && {
                    level: onboardingData.experienceLevel.value,
                  }),
                  sportIds: onboardingData.sport
                    ? [onboardingData.sport.id]
                    : [],
                  subtype: onboardingData.accountType.value,
                  phoneNumberE164: onboardingData.phoneNumber as string,
                  // TODO: check with PROD if this is okay not be shown in form
                  phoneOptIn: true,
                  ...(values.socialAccounts && {
                    socialAccounts: values.socialAccounts.map(
                      (socialAccount) => ({
                        platform: socialAccount.platform,
                        link: socialAccount.link,
                      })
                    ),
                  }),
                },
              },
            });

            handleModalClose();
          }
        }
      } catch (error) {
        console.error(error);
        showToast({
          type: 'error',
          message: error?.message || 'Something went wrong. Please try again.',
        });
      }
    },
    (err) => console.log(err)
  );

  const getModalFooter = (): FullScreenStepperModalFooter | undefined => {
    if (onboardingStep === 1 || onboardingStep === 2 || onboardingStep === 3) {
      return {
        totalStepsNumber: 4,
        stepNumber: onboardingStep,
        submitButtonProps: {
          onClick: handleSubmit,
          variant: 'primary',
          loading,
        },
        submitButtonText: 'Next',
        showPrevButton: true,
        prevButtonText: 'Back',
        prevButtonProps: {
          onClick: onboardingStep > 1 ? handlePrevStepClick : handleModalClose,
          variant: 'secondary',
        },
      };
    }

    return;
  };

  return (
    <FullScreenStepperModal
      open={showOnboardingModal}
      onClose={handleModalClose}
      title="JOIN AS A CREATOR"
      footer={getModalFooter()}
    >
      <FormProvider {...methods}>
        {onboardingStep === 1 && <ProfileStep />}

        {onboardingStep === 2 && <BasicInfoStep />}

        {onboardingStep === 3 && <SocialMediaStep />}
      </FormProvider>
    </FullScreenStepperModal>
  );
};

export default Onboarding;
