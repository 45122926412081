import { SocialMediaType } from 'api/graphql-global-types';

export const SOCIALS_OPTIONS = [
  {
    label: 'Instagram',
    value: SocialMediaType.Instagram,
  },
  {
    label: 'Facebook',
    value: SocialMediaType.Facebook,
  },
  {
    label: 'X',
    value: SocialMediaType.Twitter,
  },
  {
    label: 'Tiktok',
    value: SocialMediaType.Tiktok,
  },
  {
    label: 'YouTube',
    value: SocialMediaType.Youtube,
  },
];
