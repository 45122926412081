import cn from 'classnames';
// Ui
import Button from 'ui3/Button/Button';
import Text from 'ui3/Text/Text';
// Styles
import styles from './CreatorCard.module.scss';

type CreatorCardProps = {
  className?: string;
  onConfirmClick: () => void;
};
const CreatorCard = ({ className, onConfirmClick }: CreatorCardProps) => {
  return (
    <div className={cn(styles.root, className)}>
      <Text variant="subtitle1">Become a creator</Text>

      <Text variant="body2Regular">
        Access brand deals, create products, host interviews, and more.
      </Text>

      <div className={styles.actions}>
        <Button
          className={styles.startSpanButton}
          variant="secondary"
          subVariant="text"
          onClick={onConfirmClick}
        >
          Go Pro
        </Button>
      </div>
    </div>
  );
};

export default CreatorCard;
