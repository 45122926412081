import { useState } from 'react';
// Ui
import Modal from 'ui3/Modal/Modal';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Button from 'ui3/Button/Button';
// Components
import CreateEditGoLiveStream from 'components/ManageStreams/CreateEditStream/CreateEditGoLiveStream';
// Styles
import styles from './GoLiveButton.module.scss';

const GoLiveButton = () => {
  const [createStreamModal, setCreateStreamModalVisibility] = useState<boolean>(
    false
  );

  const handleCreateStreamModalOpen = () => {
    setCreateStreamModalVisibility(true);
  };

  const handleCreateStreamModalClose = () => {
    setCreateStreamModalVisibility(false);
  };

  const onCompleteCallback = () => {
    setCreateStreamModalVisibility(false);
  };
  return (
    <div className={styles.root}>
      <Button
        variant="secondary"
        size="medium"
        onClick={handleCreateStreamModalOpen}
      >
        <MillionsIcon name="stream" className={styles.icon} />
        Go Live
      </Button>

      <Modal
        open={createStreamModal}
        onClose={handleCreateStreamModalClose}
        title="Create stream"
      >
        <CreateEditGoLiveStream onComplete={onCompleteCallback} />
      </Modal>
    </div>
  );
};

export default GoLiveButton;
