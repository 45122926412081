const toDataURL = async (url: string) => {
  const blob = await fetch(url).then((res) => res.blob());
  return URL.createObjectURL(blob);
};

export const downloadImageFromUrl = async (url: string, fileName?: string) => {
  const a = document.createElement('a');
  a.href = await toDataURL(url);
  a.download = fileName ? fileName : 'Social pack image.png';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  // this is a way to wait for image to actually be downloaded
  await Promise.resolve();
};
