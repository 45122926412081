// Hooks
import { useOnboardingContext } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
import SocialMediaForm from './SocialMediaForm/SocialMediaForm';
// Styles
import styles from './SocialMediaStep.module.scss';

const SocialMediaStep = () => {
  const { onboardingStep } = useOnboardingContext();

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          <StepChip step={onboardingStep} />

          <Text variant="h2">Connect Your Socials</Text>

          <Text variant="body1Regular16" color="lights-medium">
            Add your social media profile links for verification and so that
            brands and fans can find you.
          </Text>
        </div>
      }
      right={<SocialMediaForm />}
    />
  );
};

export default SocialMediaStep;
