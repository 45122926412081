import React from 'react';
import { Tab as ReactTabsTab, TabProps as ReactTabsTabProps } from 'react-tabs';
import cn from 'classnames';
import { useTabContext } from 'ui/Tabs/hook/useTabsContext';

import styles from './Tab.module.scss';

type TabProps = Omit<ReactTabsTabProps, 'ref' | 'key'>;

const Tab: React.FC<TabProps> & { tabsRole: string } = ({
  className,
  disabledClassName,
  selectedClassName,
  ...props
}) => {
  const { type } = useTabContext();

  return (
    <ReactTabsTab
      className={cn(styles.tab, { [styles[type]]: !!type }, className)}
      disabledClassName={cn(styles.disabled, disabledClassName)}
      selectedClassName={cn(styles.selected, selectedClassName)}
      {...props}
    />
  );
};

Tab.tabsRole = 'Tab';

export default Tab;
