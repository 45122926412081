import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
// Api
import {
  GET_EXPERIENCES,
  ESTIMATE_EXPERIENCE_PRICE,
  GET_EXPERIENCE_BY_ID,
} from 'api/experiences/queries';
import {
  CREATE_STORE_EXPERIENCE,
  UPDATE_STORE_EXPERIENCE,
  CREATE_EXPERIENCE_PRESIGNED_URL,
} from 'api/experiences/mutations';
// Types
import {
  EstimateExperiencePrice,
  EstimateExperiencePriceVariables,
} from 'api/experiences/types/EstimateExperiencePrice';
import { CreateStoreExperienceVariables } from 'api/experiences/types/CreateStoreExperience';
import { UpdateStoreExperienceVariables } from 'api/experiences/types/UpdateStoreExperience';
import {
  CreateStoreExperienceInput,
  SortDirection,
  UpdateStoreExperienceInput,
} from 'api/graphql-global-types';
import {
  GetExperienceById,
  GetExperienceByIdVariables,
} from 'api/experiences/types/GetExperienceById';
import {
  CreateExperienceImagePresignedUrl,
  CreateExperienceImagePresignedUrlVariables,
  CreateExperienceImagePresignedUrl_createExperiencePresignedUrl,
} from 'api/experiences/types/CreateExperienceImagePresignedUrl';
// Hooks
import { useOnboardingContext, useGetCurrUser } from 'hooks';
// Helpers
import { formatPrice } from 'helpers/formatPrice';
import {
  formatHashtagInput,
  imagesSortedByMain,
  validationSchema,
} from 'components/ManageExperiences/helpers';
// Constants
import { DASHBOARD } from 'constants/routes';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import TagsInput from 'ui3/TagsInput/TagsInput';
import TextArea from 'ui3/TextArea/TextArea';
import Switch from 'ui3/Switch/Switch';
import Input from 'ui3/Input/Input';
import Text from 'ui3/Text/Text';
// Components
import { showToast } from 'components/common/Toast/Toast';
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import UploadMultipleImages, {
  UploadImage,
} from 'components/common3/UploadImage/UploadMultipleImages/UploadMultipleImages';
// Styles
import styles from './ExperienceSetup.module.scss';

type FormValues = {
  title: string;
  description: string;
  requestedPrice: number | null;
  numberOfUnits: number | null;
  hashtags?: string[];
  images: UploadImage[];
};

type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | string;
};

type ExperienceSetupProps = {
  onClose?: () => void;
};

const ExperienceSetup = ({ onClose }: ExperienceSetupProps) => {
  const router = useRouter();
  const { experienceId } = router.query;

  const {
    showCreateExperienceModal,
    setCreateExperienceDone,
    setShowCreateExperienceModal,
  } = useOnboardingContext();

  const { data: experienceData } = useQuery<
    GetExperienceById,
    GetExperienceByIdVariables
  >(GET_EXPERIENCE_BY_ID, {
    variables: {
      id: experienceId as string,
    },
    skip: !experienceId,
  });
  const record = experienceData?.getExperienceById;
  const sortedImages = imagesSortedByMain(record);

  const initialFormValues: FormValues = {
    title: record?.title || '',
    description: record?.description || '',
    requestedPrice: record?.requestedPrice || null,
    numberOfUnits: record?.numberOfUnits || null,
    hashtags: record?.hashtags.map((tag) => tag.name) || [],
    images:
      sortedImages?.map((item) => ({
        data_url: item.experienceImageFileKey || undefined,
      })) || [],
  };
  const { data: currentUserData } = useGetCurrUser();
  const currentUser = currentUserData?.me;

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    watch,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: initialFormValues,
  });

  const [mainImageIndex, setMainImageIndex] = useState<number>(0);
  const [imagesUploading, setImagesUploading] = useState<boolean>(false);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [unlimitedValue, setUnlimitedValue] = useState(
    record ? !record?.numberOfUnits : true
  );

  useEffect(() => {
    if (record) {
      reset({
        title: record?.title || '',
        description: record?.description || '',
        requestedPrice: record?.requestedPrice || null,
        numberOfUnits: record?.numberOfUnits || null,
        hashtags: record?.hashtags.map((tag) => tag.name) || [],
        images:
          sortedImages?.map((item) => ({
            data_url: item.experienceImageFileKey || undefined,
          })) || [],
      });
      setUnlimitedValue(!record?.numberOfUnits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, reset]);

  const currentPrice = watch('requestedPrice');

  const toggleUnlimitedValue = () => {
    if (!unlimitedValue) {
      setValue('numberOfUnits', null);
    }
    setUnlimitedValue(!unlimitedValue);
  };

  const [estimatePrice] = useLazyQuery<
    EstimateExperiencePrice,
    EstimateExperiencePriceVariables
  >(ESTIMATE_EXPERIENCE_PRICE, {
    variables: {
      input: {
        requestedPrice: Number(currentPrice),
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const fetchPriceEstimation = async () => {
      if (currentPrice) {
        const { data } = await estimatePrice();

        if (data?.estimateExperiencePrice) {
          setFinalPrice(data.estimateExperiencePrice.price);
        }
      }
    };

    fetchPriceEstimation();
  }, [currentPrice, estimatePrice]);

  const [createStoreExperince, { loading: createLoading }] = useMutation<
    CreateStoreExperienceInput,
    CreateStoreExperienceVariables
  >(CREATE_STORE_EXPERIENCE, {
    refetchQueries: [
      {
        query: GET_EXPERIENCES,
        variables: {
          input: {
            direction: SortDirection.DESC,
            isOrphanPage: null,
            limit: 20,
            offset: 0,
            orderBy: 'createdAt',
            storeIds: currentUser?.id ? [currentUser.id] : [],
            storeStatuses: null,
          },
        },
      },
    ],
  });

  const [updateStoreExperince, { loading: updateLoading }] = useMutation<
    UpdateStoreExperienceInput,
    UpdateStoreExperienceVariables
  >(UPDATE_STORE_EXPERIENCE);

  const [
    createPresignedUrl,
    { loading: createExperienceImagePresignedUrlLoading },
  ] = useMutation<
    CreateExperienceImagePresignedUrl,
    CreateExperienceImagePresignedUrlVariables
  >(CREATE_EXPERIENCE_PRESIGNED_URL);

  const getImagesToUpload = (
    preSignedUrls: CreateExperienceImagePresignedUrl_createExperiencePresignedUrl[],
    images: UploadImage[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach(({ file }, index) => {
      const preSignedUrl = preSignedUrls?.[index];

      const imageExtension = file
        ? file.name.split('.')[file.name.split('.').length - 1]
        : '';

      const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

      res.push({
        fields: preSignedUrl?.fields || '',
        url: preSignedUrl?.url || '',
        name: imageName,
        type: file?.type || '',
        file: file || '',
      });
    });

    return res;
  };

  const uploadImages = async (
    preSignedUrls: CreateExperienceImagePresignedUrl_createExperiencePresignedUrl[],
    images: UploadImage[]
  ): Promise<string[]> => {
    const imagesToUpload = getImagesToUpload(preSignedUrls, images);
    setImagesUploading(true);

    for (const image of imagesToUpload) {
      const { fields, url, name, type, file } = image;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', name);
      formData.append('Content-Type', type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    }

    setImagesUploading(false);

    return imagesToUpload.map(({ name }) => name);
  };

  const handleClose = () => {
    setShowCreateExperienceModal(false);

    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = () => {
    setCreateExperienceDone(true);
    handleClose();
    router.push(DASHBOARD);
  };

  const handleCreateExperience = async (values: FormValues) => {
    const {
      title,
      description,
      requestedPrice,
      numberOfUnits,
      images,
      hashtags,
    } = values;

    try {
      const { data } = await createPresignedUrl({
        variables: {
          input: {
            numberOfImages: images.length,
          },
        },
      });

      if (data) {
        try {
          const imagesUrls = await uploadImages(
            data.createExperiencePresignedUrl,
            images
          );

          if (imagesUrls) {
            const experienceInput = {
              title,
              description,
              hashtagInputs: formatHashtagInput(hashtags),
              images: imagesUrls.map((item) => ({
                isMainImage: imagesUrls.indexOf(item) === mainImageIndex,
                experienceImageFileKey: item,
              })),
              numberOfUnits: Number(numberOfUnits) ?? null,
              requestedPrice: requestedPrice || 0,
            };

            try {
              await createStoreExperince({
                variables: {
                  input: experienceInput,
                },
              });
              showToast({
                message: 'New experience is successfully created',
              });
              handleSuccess();
            } catch (error) {
              showToast({
                message: error?.message,
                type: 'error',
              });
            }
          }
        } catch (error) {
          showToast({
            message: error?.message,
            type: 'error',
          });
        }
      }
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  };

  const handleEditExperience = async (values: FormValues) => {
    const {
      title,
      description,
      requestedPrice,
      images,
      hashtags,
      numberOfUnits,
    } = values;

    const newImages = images.filter((item: UploadImage) => {
      return item.file;
    });

    const existingImages = images.filter((item: UploadImage) => {
      return !item.file;
    });

    try {
      const { data } = await createPresignedUrl({
        variables: {
          input: {
            numberOfImages: newImages.length,
          },
        },
      });

      if (data) {
        try {
          const imagesUrls = await uploadImages(
            data.createExperiencePresignedUrl,
            newImages
          );

          if (imagesUrls) {
            // here we combine old images and new ones, uploading only new ones to s3
            const newAndExistingImages = [
              ...imagesUrls,
              ...existingImages.map((item: any) => item.data_url),
            ];

            try {
              await updateStoreExperince({
                variables: {
                  input: {
                    id: record?.id || '',
                    title,
                    description,
                    hashtagInputs: formatHashtagInput(hashtags),
                    requestedPrice: requestedPrice || 0,
                    numberOfUnits: numberOfUnits ? Number(numberOfUnits) : null,
                    images: newAndExistingImages.map((item) => ({
                      isMainImage:
                        newAndExistingImages.indexOf(item) === mainImageIndex,
                      experienceImageFileKey: item.includes('https://')
                        ? `experience-image${
                            item.split('experience-image')[1]
                          }  `
                        : item,
                    })),
                  },
                },
              });
              showToast({
                message: 'New item successfully updated',
              });
              handleSuccess();
            } catch (error) {
              showToast({
                message: error?.message,
                type: 'error',
              });
            }
          }
        } catch (error) {
          showToast({
            message: error?.message,
            type: 'error',
          });
        }
      }
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  };

  const handleFinish = handleSubmit(async (values: FormValues) => {
    if (record?.id) {
      await handleEditExperience(values);
    } else {
      await handleCreateExperience(values);
    }
  });

  const isEdit = !!record?.id;
  const loading =
    createLoading ||
    updateLoading ||
    imagesUploading ||
    createExperienceImagePresignedUrlLoading;
  const modalTitle = isEdit ? 'EDIT EXPERIENCE' : 'NEW EXPERIENCE';
  const modalFooter: FullScreenStepperModalFooter = useMemo(() => {
    return {
      showPrevButton: false,
      leftSection: (
        <div className={styles.finalPrice}>
          <span className={styles.finalPriceLabel}>
            <Text variant="subtitle2">Final price</Text>
            <MillionsIcon name="infoCircle" size={16} />
          </span>
          <Text variant="h5">{formatPrice(finalPrice)}</Text>
        </div>
      ),
      submitButtonText: 'Publish',
      submitButtonProps: {
        onClick: handleFinish,
        loading: loading,
      },
    };
  }, [finalPrice, handleFinish, loading]);

  return (
    <FullScreenStepperModal
      title={modalTitle}
      footer={modalFooter}
      open={showCreateExperienceModal}
      onClose={handleClose}
    >
      <SplitLeftRightView
        left={
          <div className={styles.entry}>
            <form className={styles.form}>
              <div className={cn(styles.section)}>
                <div>
                  <Text variant="h6">Details</Text>
                  <Text variant="body1Regular16" color="lights-medium">
                    Please provide detailed information about your experience.
                  </Text>
                </div>

                <Input
                  label="Title"
                  name="title"
                  placeholder="Name your experience"
                  ref={register}
                  error={errors?.title?.message}
                />

                <TextArea
                  label="Description"
                  name="description"
                  placeholder="Describe your experience"
                  ref={register}
                  error={errors?.description?.message}
                  rows={isMobile ? 5 : 3}
                  type="textarea"
                />

                <div>
                  <Controller
                    name="hashtags"
                    control={control}
                    render={({ onChange, value: tags }) => (
                      <TagsInput
                        tags={tags}
                        label="Tags"
                        placeholder="Add tags here"
                        onChange={onChange}
                        name="hashtags"
                      />
                    )}
                  />
                  <Text variant="captionRegular" color="lights-low">
                    Add up to 5 tags.
                  </Text>
                </div>
              </div>

              <div className={styles.section}>
                <div>
                  <Text variant="h6">Pricing</Text>
                  <Text variant="body1Regular16" color="lights-medium">
                    Please enter the price and select the quantity of units.
                  </Text>
                </div>

                <div className={styles.priceQuantity}>
                  <Input
                    name="requestedPrice"
                    label="Price"
                    placeholder="USD"
                    hint="Platform fee will be added on top."
                    type="number"
                    ref={register}
                    error={errors?.requestedPrice?.message}
                  />
                </div>

                <div className={styles.priceQuantity}>
                  <Input
                    label="Quantity of units"
                    name="numberOfUnits"
                    placeholder="Enter number of experiences"
                    hint="How many times can this experience be purchased?"
                    ref={register}
                    type="number"
                    error={errors?.numberOfUnits?.message}
                    disabled={unlimitedValue}
                  />

                  <Switch
                    label="Unlimited"
                    name="unlimited"
                    ref={register}
                    onChange={toggleUnlimitedValue}
                    checked={unlimitedValue}
                  />
                </div>
              </div>
            </form>
          </div>
        }
        right={
          <div>
            <Controller
              name="images"
              control={control}
              render={({ onChange, value }) => (
                <>
                  <UploadMultipleImages
                    images={value}
                    setImages={(images) => onChange(images)}
                    mainImageIndex={mainImageIndex}
                    setMainImageIndex={setMainImageIndex}
                  />
                </>
              )}
            />

            {(errors?.images as any)?.message && (
              <Text variant="captionRegular" color="error-default">
                {(errors?.images as any)?.message}
              </Text>
            )}
          </div>
        }
      />
    </FullScreenStepperModal>
  );
};

export default ExperienceSetup;
