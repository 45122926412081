import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
// Types
import { ContactType } from 'api/graphql-global-types';
// Components
import PhoneNumberInput from 'ui3/PhoneInput/PhoneNumberInput';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import { Mention } from 'ui3/Mentions/Mentions';
import Input from 'ui3/Input/Input';
// Styles
import styles from './UnregisteredMentionsSelect.module.scss';

type UnregisteredMentionsSelectProps = {
  mention: Mention | FormValues | null;
  index: number;
  type: ContactType;
  onChange: (value, index) => void;
  handleMillionsMentionRemove: (index) => void;
};

type FormValues = {
  name: string;
  email: string;
  phoneNumberE164: string;
  url: string;
  type: ContactType;
  socialMediaLink: string;
};

const getValidationSchema = (type: ContactType) => {
  if (type === ContactType.ATHLETE) {
    return yup.object().shape({
      name: yup
        .string()
        .max(140, 'Max 140 characters')
        .required('You need to provide the name'),
      email: yup.string().max(1000, 'Max 1000 characters'),
      phoneNumberE164: yup.string().nullable(),
      url: yup.string().nullable(),
      socialMediaLink: yup.string().nullable(),
    });
  } else if (type === ContactType.BRAND) {
    return yup.object().shape({
      name: yup
        .string()
        .max(140, 'Max 140 characters')
        .required('You need to provide the name'),
      email: yup.string().nullable(),
      phoneNumberE164: yup.string().nullable(),
      url: yup.string(),
    });
  }
  return yup.object().shape({});
};

export const UnregisteredMentionsSelect = ({
  mention,
  onChange,
  type,
  index,
  handleMillionsMentionRemove,
}: UnregisteredMentionsSelectProps) => {
  const isEditOption = (mention as Mention)?.id;

  const defaultValues = isEditOption
    ? {
        name: (mention as Mention)?.unregisteredContact?.name || '',
        email: (mention as Mention)?.unregisteredContact?.email || '',
        phoneNumberE164:
          (mention as Mention)?.unregisteredContact?.phoneNumber || '',
        url: (mention as Mention)?.unregisteredContact?.url || '',
        socialMediaLink:
          (mention as Mention)?.unregisteredContact?.socialMediaLink || '',
      }
    : {
        name: (mention as FormValues)?.name || '',
        email: (mention as FormValues)?.email || '',
        phoneNumberE164: (mention as FormValues)?.phoneNumberE164 || '',
        url: (mention as FormValues)?.url || '',
        socialMediaLink: (mention as FormValues)?.socialMediaLink || '',
      };

  const {
    register,
    errors,
    control,
    reset,
    getValues,
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(getValidationSchema(type)),
    mode: 'onBlur',
    defaultValues,
  });

  // Reset form whenever the mention prop changes
  useEffect(() => {
    reset(defaultValues); // Reset form values when mention changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mention, reset, type]);

  const mentionRemove = (index) => handleMillionsMentionRemove(index);

  const handleBlur = async () => {
    const isValid = await trigger(); // Validate the form on blur
    if (isValid) {
      const currentValues = getValues();
      currentValues['type'] = type;
      onChange(currentValues, index); // Trigger parent change handler
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.unregisteredSelectContainer}>
        <Input
          name="name"
          placeholder="Enter name"
          ref={register}
          error={errors.name?.message}
          label="Name"
          disabled={!!isEditOption}
          onBlur={handleBlur}
        />
        <button
          className={styles.deleteButton}
          onClick={() => mentionRemove(index)}
          aria-label="Delete mention"
        >
          <MillionsIcon name="trash" />
        </button>
      </div>
      {!isEditOption && (
        <div className={styles.row}>
          <Input
            name="email"
            ref={register}
            placeholder="Enter email"
            error={errors.email?.message}
            label="Email"
            className={styles.halfRow}
            onBlur={handleBlur}
          />

          {type === ContactType.ATHLETE ? (
            <Controller
              name="phoneNumberE164"
              control={control}
              render={({ ...props }) => (
                <PhoneNumberInput
                  {...props}
                  label="Phone number"
                  placeholder="Phone Number"
                  onBlur={handleBlur}
                  classNames={styles.phoneNumberField}
                />
              )}
            />
          ) : (
            <Input
              name="url"
              ref={register}
              placeholder="Enter URL"
              label="URL"
              error={errors.url?.message}
              className={styles.halfRow}
              onBlur={handleBlur}
            />
          )}
        </div>
      )}
      {type === ContactType.ATHLETE && (
        <div className={styles.row}>
          <Input
            name="socialMediaLink"
            placeholder="Enter social media link"
            ref={register}
            label="Social media link"
            disabled={!!isEditOption}
            onBlur={handleBlur}
          />
        </div>
      )}
    </div>
  );
};

export default UnregisteredMentionsSelect;
