import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
// Api
import { GET_PLACE_DETAILS, GET_PLACE_PREDICTIONS } from 'api/places/queries';
// Types
import {
  GetPlaceDetails,
  GetPlaceDetails_getPlaceDetails,
  GetPlaceDetailsVariables,
} from 'api/places/types/GetPlaceDetails';
import {
  GetPlacePredictions,
  GetPlacePredictions_getPlacePredictions_entities,
  GetPlacePredictionsVariables,
} from 'api/places/types/GetPlacePredictions';
// UI
import Select from 'ui3/Select/Select';

type PlaceAutocompleteProps = {
  name: string;
  value: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  onChange: (details: GetPlaceDetails_getPlaceDetails) => void;
};

const PlaceAutocomplete = ({
  name,
  value,
  disabled = false,
  label,
  placeholder,
  className,
  error,
  onChange,
}: PlaceAutocompleteProps) => {
  const sessionTokenRef = useRef<string>(uuidv4());
  const isLoadingApiRef = useRef<boolean>(false);

  // Prepare the predictions query (skipped on mount).
  const { refetch: fetchPredictions, loading } = useQuery<
    GetPlacePredictions,
    GetPlacePredictionsVariables
  >(GET_PLACE_PREDICTIONS, {
    skip: true,
  });
  const [fetchPlaceDetails] = useLazyQuery<
    GetPlaceDetails,
    GetPlaceDetailsVariables
  >(GET_PLACE_DETAILS);

  // This callback is called when the generic autocomplete input changes.
  const debouncedInputChange = useDebouncedCallback(
    async (
      query: string,
      callback: (
        results: GetPlacePredictions_getPlacePredictions_entities[]
      ) => void
    ) => {
      try {
        const { data, loading } = await fetchPredictions({
          input: {
            query,
            sessionToken: sessionTokenRef.current,
          },
        });
        const entities = data?.getPlacePredictions?.entities || [];
        isLoadingApiRef.current = loading;

        callback(entities);
      } catch (error) {
        console.error('Error fetching place predictions:', error);
        callback([]);
      }
    },
    300
  );

  // The loadOptions function is what AsyncSelect calls.
  // We delegate the work to our debounced function.
  const loadOptions = (
    inputValue: string,
    callback: (
      options: GetPlacePredictions_getPlacePredictions_entities[]
    ) => void
  ) => {
    if (!inputValue) {
      callback([]);
      return;
    }
    debouncedInputChange(inputValue, callback);
  };

  // This callback is called when a suggestion is selected.
  const handleSelect = async (
    item: GetPlacePredictions_getPlacePredictions_entities
  ) => {
    try {
      const { data } = await fetchPlaceDetails({
        variables: {
          input: {
            id: item.id,
            sessionToken: sessionTokenRef.current,
          },
        },
      });
      const placeDetails = data?.getPlaceDetails;
      isLoadingApiRef.current = false;
      if (placeDetails && onChange) {
        onChange(placeDetails);
      }
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
    // Reset session token for the next query
    sessionTokenRef.current = uuidv4();
  };

  return (
    <Select
      type="async"
      name={name}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      error={error}
      className={className}
      defaultInputValue={value}
      onChange={handleSelect}
      loadOptions={loadOptions}
      isLoading={loading}
      getOptionLabel={(
        option: GetPlacePredictions_getPlacePredictions_entities
      ) => option.text}
    />
  );
};

export default PlaceAutocomplete;
