import { Controller, FieldError } from 'react-hook-form';
// Types
import { StoreGender, StoreAthleticLevel } from 'api/graphql-global-types';
import { GetPlaceDetails_getPlaceDetails } from 'api/places/types/GetPlaceDetails';
import { GetLeaguesList_getLeaguesList } from 'api/store/types/GetLeaguesList';
import { GetCollegesList_getCollegesList } from 'api/store/types/GetCollegesList';
// Helpers
import { joinLocation } from 'helpers/place';
// Hook
import { useGetLeagues } from 'hooks';
// Ui3
import Input from 'ui3/Input/Input';
import Select from 'ui3/Select/Select';
import PlaceAutocomplete from 'ui3/PlaceAutocomplete/PlaceAutocomplete';
import DatePicker from 'ui3/DatePicker/DatePicker';
// Styles
import styles from '../BrandDealSteps.module.scss';

type ProfileDetailsStepProps = {
  control: any;
  setValue: any;
  errors: any;
};

const ProfileDetailsStep = ({
  control,
  setValue,
  errors,
}: ProfileDetailsStepProps): JSX.Element => {
  const { data: leagues } = useGetLeagues();

  const genderOptions = [
    { value: StoreGender.male, label: 'Male' },
    { value: StoreGender.female, label: 'Female' },
    { value: StoreGender.other, label: 'Other' },
    { value: StoreGender.preferNotToSay, label: 'Prefer not to say' },
  ];

  const athleticLevelOptions = [
    { value: StoreAthleticLevel.Professional, label: 'Professional' },
    {
      value: StoreAthleticLevel.CollegeUniversity,
      label: 'College/University',
    },
    { value: StoreAthleticLevel.Amateur, label: 'Amateur' },
    { value: StoreAthleticLevel.OlympicNational, label: 'Olympic/National' },
    {
      value: StoreAthleticLevel.RetiredProfessional,
      label: 'Retired Professional',
    },
    { value: StoreAthleticLevel.Other, label: 'Other' },
  ];

  const handleInputChange = (field: string, value: string) => {
    setValue(field, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className={styles.profileForm}>
      <div className={styles.formField}>
        <Controller
          name="gender"
          control={control}
          defaultValue=""
          render={({ onChange, value, ...rest }) => (
            <Select
              label="Gender"
              placeholder="Select gender"
              options={genderOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isSearchable
              id="gender"
              inputId="genderSelect"
              error={errors?.gender?.message}
              onChange={(selectedOption) => {
                if (selectedOption && 'value' in selectedOption) {
                  const enumValue = selectedOption.value as StoreGender;
                  onChange(enumValue);
                } else {
                  onChange('');
                }
              }}
              value={
                value
                  ? genderOptions.find((option) => option.value === value) ||
                    null
                  : null
              }
              {...rest}
            />
          )}
        />
      </div>

      <div className={styles.formField}>
        <Controller
          name="athleticLevel"
          control={control}
          defaultValue={null}
          render={({ onChange, value, ...rest }) => (
            <Select
              label="Current Athletic Level"
              placeholder="Select athletic level"
              options={athleticLevelOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isSearchable
              id="athleticLevel"
              inputId="athleticLevelSelect"
              error={errors?.athleticLevel?.message}
              onChange={(selectedOption) => {
                if (
                  selectedOption &&
                  'value' in selectedOption &&
                  'label' in selectedOption
                ) {
                  const enumValue = selectedOption.value as StoreAthleticLevel;
                  onChange({
                    value: enumValue,
                    label: selectedOption.label,
                  });
                } else {
                  onChange(null);
                }
              }}
              value={
                value && typeof value === 'object' && value.value
                  ? athleticLevelOptions.find(
                      (option) => option.value === value.value
                    ) || null
                  : null
              }
              {...rest}
            />
          )}
        />
      </div>

      <div className={styles.formField}>
        <Controller
          control={control}
          name="birthday"
          defaultValue={null}
          render={({ onChange, onBlur, value }) => {
            return (
              <DatePicker
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={70}
                maxDate={new Date()}
                dateFormat="MM/d/yyyy"
                openToDate={new Date('2005/01/01')}
                selected={value}
                onBlur={onBlur}
                showTimeSelect={false}
                onChange={onChange}
                placeholderText="Type here"
                customInput={
                  <Input
                    aria-label="Birthday"
                    name="birthday"
                    label="Birthday"
                    placeholder="MM/DD/YYYY"
                    onChange={(e) =>
                      handleInputChange('birthday', e.target.value)
                    }
                    error={errors?.birthday?.message}
                  />
                }
              />
            );
          }}
        />
      </div>

      <div className={styles.formField}>
        <Controller
          name="location"
          control={control}
          render={({ onChange, value }) => {
            const handlePlaceSelect = (
              placeDetails: GetPlaceDetails_getPlaceDetails
            ) => {
              const location = {
                country: placeDetails.country,
                countryCode: placeDetails.countryCode,
                city: placeDetails.city,
                state: placeDetails.stateCode,
                stateCode: placeDetails.stateCode,
                coordinates: {
                  latitude: placeDetails.location.latitude,
                  longitude: placeDetails.location.longitude,
                },
              };

              onChange(location);
            };

            return (
              <PlaceAutocomplete
                name="location"
                label="Location"
                placeholder="Enter location"
                onChange={handlePlaceSelect}
                value={value ? joinLocation([value.city, value.country]) : ''}
                error={(errors.location as FieldError)?.message}
              />
            );
          }}
        />
      </div>

      <div className={styles.formField}>
        <Controller
          name="hometown"
          control={control}
          render={({ onChange, value }) => {
            const handlePlaceSelect = (
              placeDetails: GetPlaceDetails_getPlaceDetails
            ) => {
              const hometownLocation = {
                country: placeDetails.country,
                countryCode: placeDetails.countryCode,
                city: placeDetails.city,
                state: placeDetails.stateCode,
                stateCode: placeDetails.stateCode,
                coordinates: {
                  latitude: placeDetails.location.latitude,
                  longitude: placeDetails.location.longitude,
                },
              };

              onChange(hometownLocation);
            };

            return (
              <PlaceAutocomplete
                name="hometown"
                label="Hometown"
                placeholder="Enter your hometown"
                onChange={handlePlaceSelect}
                value={value ? joinLocation([value.city, value.country]) : ''}
                error={(errors.hometown as FieldError)?.message}
              />
            );
          }}
        />
      </div>

      <div className={styles.formField}>
        <Controller
          name="league"
          control={control}
          defaultValue={null}
          render={({ onChange, ...rest }) => (
            <Select
              className={styles.input}
              label="What League Do You Compete In?"
              placeholder="League"
              options={leagues?.getLeaguesList}
              isClearable
              isSearchable
              getOptionLabel={(option: GetLeaguesList_getLeaguesList) =>
                option.name
              }
              getOptionValue={(option: GetLeaguesList_getLeaguesList) =>
                `${option.id}`
              }
              inputId="league"
              error={errors.league?.message}
              onChange={(data: GetCollegesList_getCollegesList) =>
                onChange(data)
              }
              {...rest}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ProfileDetailsStep;
