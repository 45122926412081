import { useRouter } from 'next/router';
// Types
import { InputActionMeta } from 'react-select';
import { SearchResultType } from 'api/graphql-analytics-global-types';
// Constants
import { ATHLETES } from 'constants/routes';
// Hooks
import { useGetSportNames } from 'hooks';
// Context
import { useSearchBarContext } from 'components/HomePage/components/V3/SearchBar/hook/useSearchBarContext';
// Ui3
import Select from 'ui3/Select/Select';

export type SearchBarOptionProps = {
  name: string;
  searchTerm: string;
  value: string;
  label: string;
  url: string;
  id: string;
  type: SearchResultType;
};

export type SearchBarGroupProps = {
  label: string;
  options: SearchBarOptionProps[];
};

export type FilterOptionOption = {
  label: string;
  value: string;
  data: SearchBarOptionProps;
};

const HeaderSearchBar = () => {
  const { data: sportsData } = useGetSportNames({
    options: {
      ssr: false,
    },
  });

  const allSports = sportsData?.sports || [];

  const { push } = useRouter();

  const {
    searchValue,
    setSearchValue,
    options,
    loading,
    setSearchFocused,
    setSelectedOption,
  } = useSearchBarContext();

  const optionValue = searchValue
    ? {
        label: searchValue,
        value: searchValue,
      }
    : null;

  const onInputChange = (value: string, action: InputActionMeta) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setSearchValue(value);

      return value;
    }

    if (action.action === 'input-blur') {
      setSearchValue(searchValue);

      return searchValue;
    }
  };

  const onSearchApply = (option: SearchBarOptionProps) => {
    // checking if option exists-in case of clear, it will be null, otherwise it will be an object
    if (option) {
      // send analytics when user selects an autocomplete option along with relevant data (search type and id)
      setSelectedOption(option);
    }
    setSearchValue(option.searchTerm);
    push(option.url);

    return option;
  };

  const navigateToAthletesPage = () => {
    const foundSport = allSports.find(
      (sport) => sport.name.toLowerCase() === searchValue.toLowerCase().trim()
    );

    if (!foundSport) {
      push(`${ATHLETES}?searchTerm=${searchValue}`);
    } else {
      push(`${ATHLETES}?sports=${foundSport.id}`);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      // setting focused false will trigger the analytics api without the timer
      setSearchFocused(false);

      navigateToAthletesPage();
    }
  };

  const commonSelectProps = {
    placeholder: 'Search Athletes, Sport, Streams and more...',
    defaultOptions: true,
    getOptionLabel: (option: SearchBarOptionProps) => option.label,
    getOptionValue: (option: SearchBarOptionProps) => option.value,
    options,
    isLoading: loading,
    onInputChange,
    onChange: onSearchApply,
    onKeyDown,
    value: optionValue,
    defaultValue: optionValue,
    inputValue: searchValue,
    defaultInputValue: searchValue,
    cacheOptions: true,
    dropdownIndicator: null,
  } as const;

  return (
    <Select
      {...commonSelectProps}
      id="homepageSearch"
      inputId="homepageSearchInput"
      size="medium"
      onFocus={() => setSearchFocused(true)}
      onBlur={() => setSearchFocused(false)}
    />
  );
};

export default HeaderSearchBar;
