import React, { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import cn from 'classnames';
import postalCodes from 'postal-codes-js';
// Api
import { AddressFields } from 'api/address/fragments';
import { GET_COUNTRY_LIST, GET_MY_ADDRESSES } from 'api/address/queries';
import { CREATE_ADDRESS, UPDATE_ADDRESS } from 'api/address/mutations';
// Types
import { GetCountryList } from 'api/address/types/GetCountryList';
import { GetCountryList_getCountryList } from 'api/address/types/GetCountryList';
import { GetMyAddresses_getMyAddresses } from 'api/address/types/GetMyAddresses';
import {
  CreateAddress,
  CreateAddressVariables,
} from 'api/address/types/CreateAddress';
import {
  UpdateAddress,
  UpdateAddressVariables,
} from 'api/address/types/UpdateAddress';
import { OptionTypeBase } from 'react-select';
// Helpers
import { getAllStatesOptions } from 'helpers/address';
import { getProperErrorMessage } from 'helpers/errors';
import {
  yupStringValidation,
  yupStringRequiredValidation,
  yupInternationalPhoneValidation,
  yupEmailRequiredValidation,
} from 'helpers/validation';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import RadioButton from 'ui3/RadioButton/RadioButton';
import Select from 'ui3/Select/Select';
import Button from 'ui3/Button/Button';
import Input from 'ui3/Input/Input';
import PhoneNumberInput from 'ui3/PhoneInput/PhoneNumberInput';
// Styles
import styles from './ManageAddressForm.module.scss';

type ManageAddressFormProps = {
  address?: GetMyAddresses_getMyAddresses;
  onClose?: () => void;
};

type FormInputs = {
  firstName: string;
  lastName: string;
  country: OptionTypeBase;
  state: OptionTypeBase;
  stateName: string;
  city: string;
  zipCode: string;
  addressLine1: string;
  addressLine2: string | null;
  phoneNumberE164: string;
  email: string | null;
  isDefault: boolean;
};

const getValidationSchema = (ifHaveStates, countryCode) =>
  yup.object().shape({
    firstName: yupStringRequiredValidation('First name'),
    lastName: yupStringRequiredValidation('Last name'),
    country: yup
      .object({
        code: yup.string(),
        name: yup.string(),
      })
      .typeError('"Country" is a required field')
      .required('"Country" is a required field')
      .nullable()
      .test('Is empty?', '"Country" is a required field', (value) => {
        return !!value?.name;
      }),
    state: ifHaveStates
      ? yup
          .object({
            value: yup.string(),
            label: yup.string(),
          })
          .typeError('"State" is a required field')
          .required('"State" is a required field')
          .nullable()
          .test('Is empty?', '"State" is a required field', (value) => {
            return !!value?.value;
          })
      : yupStringValidation('State'),
    stateName: yupStringValidation('State'),
    city: yupStringRequiredValidation('City'),
    zipCode: yup
      .string()
      .test('postalCodeValidation', 'Zip code is invalid', function (value) {
        const { path, createError } = this;
        const isValid =
          !countryCode ||
          postalCodes.validate(countryCode, value || '') === true;
        return isValid || createError({ path });
      })
      .required('"Zip" is a required field'),
    addressLine1: yupStringRequiredValidation('Address line 1'),
    addressLine2: yupStringValidation('Address line 2'),
    phoneNumberE164: yupInternationalPhoneValidation(),
    email: yupEmailRequiredValidation('"Email" is a required field'),
    isDefault: yup.boolean(),
  });

const ManageAddressForm: React.FC<ManageAddressFormProps> = ({
  address,
  onClose,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string>(
    address?.countryCode || ''
  );
  const { data, loading } = useQuery<GetCountryList>(GET_COUNTRY_LIST);
  const [createAddress, { loading: createIsLoading }] = useMutation<
    CreateAddress,
    CreateAddressVariables
  >(CREATE_ADDRESS, {
    refetchQueries: [GET_MY_ADDRESSES],
    update(cache, { data }) {
      cache.modify({
        fields: {
          getMyAddresses(existingAddresses = []) {
            const newAddressRef = cache.writeFragment({
              data: data?.createAddress,
              fragment: AddressFields,
            });

            return [...existingAddresses, newAddressRef];
          },
        },
      });
    },
  });
  const [updateAddress, { loading: updateLoading }] = useMutation<
    UpdateAddress,
    UpdateAddressVariables
  >(UPDATE_ADDRESS, {
    refetchQueries: [GET_MY_ADDRESSES],
  });

  const allStatesOptions = getAllStatesOptions(data?.getCountryList);

  const ifHaveStates = allStatesOptions[selectedCountry]?.length;

  const {
    register,
    handleSubmit,
    errors: formErrors,
    setValue,
    control,
  } = useForm<FormInputs>({
    resolver: useMemo(
      () => yupResolver(getValidationSchema(ifHaveStates, selectedCountry)),
      [ifHaveStates, selectedCountry]
    ),
    defaultValues: {
      firstName: address?.firstName || '',
      lastName: address?.lastName || '',
      country:
        address?.countryCode && address?.country
          ? {
              code: address.countryCode,
              name: address.country,
            }
          : undefined,
      state:
        address?.stateCode && address?.state
          ? {
              value: address.stateCode,
              label: address.state,
            }
          : undefined,
      stateName: address?.state || '',
      city: address?.city || '',
      zipCode: address?.zipCode || '',
      addressLine1: address?.addressLine1 || '',
      addressLine2: address?.addressLine2 || null,
      phoneNumberE164: address?.phone || '',
      email: address?.email || null,
      isDefault: address?.isDefault || false,
    },
  });

  const handleFormSubmit: SubmitHandler<FormInputs> = async (
    values
  ): Promise<void> => {
    const {
      firstName,
      lastName,
      country,
      state,
      stateName,
      city,
      zipCode,
      addressLine1,
      addressLine2,
      phoneNumberE164,
      email,
      isDefault,
    } = values;
    try {
      const input = {
        firstName,
        lastName,
        countryCode: country.code || '',
        country: country.name || '',
        stateCode: ifHaveStates ? state.value : '',
        state: ifHaveStates ? state.label : stateName || '',
        city,
        zipCode,
        addressLine1,
        addressLine2,
        phoneNumberE164,
        email,
        isDefault,
      };

      if (address) {
        await updateAddress({
          variables: {
            input: {
              id: address.id,
              ...input,
            },
          },
        });
      } else {
        await createAddress({
          variables: {
            input,
          },
        });
      }

      if (onClose) {
        onClose();
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getProperErrorMessage(
          error,
          'Looks like something went wrong. Double check all fields and try again'
        ),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className={styles.manageAddressBody}>
        <Input
          name="firstName"
          ref={register}
          error={formErrors?.firstName?.message}
          label="First name"
          placeholder="First name"
        />
        <Input
          name="lastName"
          ref={register}
          error={formErrors?.lastName?.message}
          label="Last name"
          placeholder="Last name"
        />
        <Controller
          name="country"
          control={control}
          render={({ onChange, ...rest }) => (
            <Select
              label="Country"
              placeholder="Choose Country"
              options={data?.getCountryList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.code}
              isLoading={loading}
              isSearchable
              isClearable
              id="country"
              inputId="countrySelect"
              error={(formErrors?.country as any)?.message}
              fieldSize="medium"
              onChange={(data: GetCountryList_getCountryList) => {
                onChange(data);
                setValue('state', '');
                setValue('stateName', '');
                setSelectedCountry(data?.code || '');
              }}
              {...rest}
            />
          )}
        />
        <Controller
          name="state"
          control={control}
          render={(props) => (
            <Select
              className={cn({
                [styles.manageAddressInput]: ifHaveStates,
                [styles.manageAddressHideInput]: !ifHaveStates,
              })}
              label="State or Province"
              placeholder="Choose State or Province"
              options={allStatesOptions[selectedCountry]}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isLoading={loading}
              isSearchable
              isClearable
              id="countryStateSelect"
              inputId="countryStateSelect"
              error={formErrors?.state?.message}
              fieldSize="medium"
              isDisabled={!selectedCountry}
              {...props}
            />
          )}
        />
        <Input
          className={cn({
            [styles.manageAddressInput]: !ifHaveStates,
            [styles.manageAddressHideInput]: ifHaveStates,
          })}
          name="stateName"
          ref={register}
          error={formErrors?.stateName?.message}
          label="State or Province"
          placeholder="Enter State or Province"
          disabled={!selectedCountry}
        />
        <Input
          name="city"
          ref={register}
          error={formErrors?.city?.message}
          label="City"
          placeholder="Enter city"
          disabled={!selectedCountry}
        />
        <Input
          name="zipCode"
          ref={register}
          error={formErrors?.zipCode?.message}
          label="ZIP and Postal code"
          placeholder="Type ZIP and Postal code"
        />
        <Input
          name="addressLine1"
          ref={register}
          error={formErrors?.addressLine1?.message}
          label="Address line 1"
          placeholder="Street address, P.O. box, company name, c/o"
        />
        <Input
          name="addressLine2"
          ref={register}
          error={formErrors?.addressLine2?.message}
          label="Address line 2"
          placeholder="Street address, P.O. box, company name, c/o"
        />
        <Controller
          name="phoneNumberE164"
          control={control}
          render={({ ...props }) => (
            <PhoneNumberInput
              label="Phone Number"
              placeholder="Phone number"
              error={formErrors.phoneNumberE164?.message}
              {...props}
            />
          )}
        />
        <Input
          name="email"
          type="email"
          ref={register}
          error={formErrors?.email?.message}
          label="Email"
          placeholder="email@mail.com"
        />
      </div>

      <div className={styles.manageAddressFooter}>
        <RadioButton name="isDefault" ref={register} label="Make as default" />

        <Button
          type="submit"
          className={styles.manageAddressCta}
          loading={createIsLoading || updateLoading}
          disabled={createIsLoading}
        >
          {address ? 'Save' : 'Add address'}
        </Button>
      </div>
    </form>
  );
};

export default ManageAddressForm;
