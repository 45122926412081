import * as yup from 'yup';

export const formatHashtagInput = (hashtags) => {
  if (hashtags.length) {
    return hashtags.map((hashtag) => ({ name: hashtag }));
  } else {
    return null;
  }
};

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .max(140, 'Max 140 char')
    .required('You need to provide the name of the experience'),
  description: yup
    .string()
    .max(1000, 'Max 1000 char')
    .required('You need to provide the description of the experience'),
  requestedPrice: yup
    .number()
    .required('Please add requested price')
    .test(
      'Is positive?',
      'Price must be a positive number',
      (value) => value !== null && value !== undefined && value > 0
    )
    .typeError('Price must be type of number'),
  images: yup
    .array()
    .of(
      yup.object().shape({
        data_url: yup.string(),
        file: yup.mixed(),
      })
    )
    .required('Please provide at least one image'),
});

export const imagesSortedByMain = (record) =>
  record?.images?.slice().sort((a, b) => {
    if (a.isMainImage === b.isMainImage) {
      return 0;
    } else if (a.isMainImage === true) {
      return -1;
    } else {
      return 1;
    }
  });
