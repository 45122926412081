import React, { useState } from 'react';
import cn from 'classnames';
// Ui3
import Text from 'ui3/Text/Text';
// Styles
import styles from './TextArea.module.scss';

type TextAreaProps = {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  id?: string;
  error?: string;
  maxLength?: number;
  value?: string;
  rows?: number;
} & React.InputHTMLAttributes<HTMLTextAreaElement>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name,
      id = name,
      label,
      className,
      error,
      maxLength,
      value,
      rows = 3,
      onChange,
      ...restProps
    },
    ref
  ) => {
    const hasError = Boolean(error);
    const showCounter = maxLength !== undefined;
    const [counter, setCounter] = useState(value?.length || 0);

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(event);
      setCounter(event.target.value.length);
    };

    return (
      <div className={cn(styles.root, className)}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}
        <textarea
          ref={ref}
          name={name}
          id={id}
          className={cn(styles.textarea, {
            [styles.error]: hasError,
          })}
          maxLength={maxLength}
          value={value}
          onChange={handleOnChange}
          rows={rows}
          {...restProps}
        />

        {showCounter && !hasError && (
          <Text variant="captionRegular" color="lights-low" textAlign="right">
            {`${counter}/${maxLength}`}
          </Text>
        )}

        {hasError && (
          <Text variant="captionRegular" color="error-default">
            {error}
          </Text>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
