import dayjs from 'dayjs';

export const getStartEndOfMonth = (
  selectedDate: Date
): { start: dayjs.Dayjs; end: dayjs.Dayjs } => {
  const periodStart = dayjs(selectedDate).startOf('month').subtract(1, 'week');
  const periodEnd = dayjs(selectedDate).endOf('month').add(1, 'week');

  const now = dayjs();
  const start = now.isBefore(periodStart) ? periodStart : now;

  return {
    start,
    end: periodEnd,
  };
};

export const getExcludedDates = (
  slots: { startTs: string; userId?: string | null }[],
  selectedDate: Date,
  loggedStoreId?: string | null
) => {
  const startEnd = getStartEndOfMonth(selectedDate);

  // Create map of days with slots
  const daysWithSlotsMap = slots.reduce((map, slot) => {
    if (slot?.userId && loggedStoreId) {
      const isNotHostSlot = slot?.userId !== loggedStoreId;
      if (isNotHostSlot) {
        const localTzDate = dayjs(slot.startTs);
        const key = `${localTzDate.get('date')}.${localTzDate.get('month')}`;
        map[key] = true;
      }
    } else {
      const localTzDate = dayjs(slot.startTs);
      const key = `${localTzDate.get('date')}.${localTzDate.get('month')}`;
      map[key] = true;
    }

    return map;
  }, {} as Record<string, boolean>);

  // Iterate from start to end of current period to identify days without slots
  const excludedDates: Date[] = [];
  let start = startEnd.start;
  while (start.isBefore(startEnd.end)) {
    const key = `${start.get('date')}.${start.get('month')}`;
    if (!daysWithSlotsMap[key]) {
      excludedDates.push(start.toDate());
    }
    start = start.add(1, 'day');
  }

  return excludedDates;
};

export function formatDateString(dateString) {
  const dateInET = dayjs.tz(dateString, 'America/New_York');
  const date = dateInET.local();

  const day = date.date();
  const suffix =
    day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
      ? 'nd'
      : day % 10 === 3 && day !== 13
      ? 'rd'
      : 'th';
  const dayWithOrdinal = `${day}${suffix}`;

  const timeFormatted =
    date.minute() === 0 ? date.format('hA') : date.format('h:mmA');

  // Get the current local time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const today = dayjs().startOf('day');
  const tomorrow = today.add(1, 'day');
  const thisYear = today.year();

  const isToday = date.isSame(today, 'day');
  const isTomorrow = date.isSame(tomorrow, 'day');
  const isThisYear = date.year() === thisYear;

  const text = isToday
    ? `at ${timeFormatted}`
    : isTomorrow
    ? `tomorrow at ${timeFormatted}`
    : isThisYear
    ? `on ${date.format('dddd')} ${dayWithOrdinal} ${date.format(
        'MMMM'
      )} at ${timeFormatted}`
    : `on ${date.format('dddd')} ${dayWithOrdinal} ${date.format(
        'MMMM YYYY'
      )} at ${timeFormatted}`;

  const result = text + ' (' + timeZone + ')';

  return result;
}

export function get(dateString) {
  const dateInET = dayjs.tz(dateString, 'America/New_York');
  let date = dateInET.local();

  if (date.isBefore(dayjs())) {
    date = dayjs().add(1, 'day');
  }

  const day = date.date();
  const suffix =
    day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
      ? 'nd'
      : day % 10 === 3 && day !== 13
      ? 'rd'
      : 'th';
  const dayWithOrdinal = day + suffix;

  const timeFormatted = date.format('h:mm A');

  return `${date.format('dddd')} ${dayWithOrdinal} ${date.format(
    'MMMM YYYY [at] '
  )}${timeFormatted}`;
}
