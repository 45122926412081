import { gql } from '@apollo/client';
import { ScheduleFields } from './fragments';

export const GET_INTERVIEW_SCHEDULE = gql`
  query GetInterviewSchedule($input: GetInterviewScheduleInput!) {
    getInterviewSchedule(input: $input) {
      id
      timezone
    }
  }
`;

export const GET_INTERVIEW_AVAILABLE_SLOTS = gql`
  query GetInterviewAvailableSlots($input: GetInterviewAvailableSlotsInput!) {
    getInterviewAvailableSlots(input: $input) {
      schedule {
        id
        store {
          id
          firstName
          lastName
          storeDetails {
            id
            avatarURL
          }
        }
      }
      slots {
        startTs
        endTs
      }
    }
  }
`;

export const GET_SCHEDULE_TIMEZONES = gql`
  query GetScheduleTimezones {
    getScheduleTimezones {
      alias
      iana
    }
  }
`;

export const GET_MY_SCHEDULE = gql`
  query GetMySchedule($input: GetMyScheduleInput!) {
    getMySchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${ScheduleFields}
`;

export const GET_SCHEDULE = gql`
  query GetSchedule($input: GetScheduleInput!) {
    getSchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${ScheduleFields}
`;
