import { useState, useRef } from 'react';
import cn from 'classnames';
import isUrl from 'is-url';
import { Transforms, Selection } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { Tooltip } from 'react-tooltip';
// Helpers
import {
  insertLink,
  removeLink,
  LinkTarget,
} from 'components/common2/RichText/helpers/link';
import { isBlockActive } from 'components/common2/RichText/helpers/basic';
// Hooks
import { useOutsideClick } from 'hooks';
// Types
import { PlacesType } from 'react-tooltip';
// Ui
import Icon from 'ui/Icon/Icon';
// Ui 2
import Button from 'ui3/Button/Button';
import CheckBox from 'ui3/CheckBox/CheckBox';
import Input from 'ui3/Input/Input';
import Text from 'ui3/Text/Text';
// Styles
import 'react-tooltip/dist/react-tooltip.css';
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
  toolbarPosition?: PlacesType;
};

const LinkButton = ({ toolbarPosition = 'top' }: LinkButtonProps) => {
  const editor = useSlate() as ReactEditor;
  const popoverContentRef = useRef(null);

  const [url, setUrl] = useState<string>('');
  const [urlIsValid, setUrlIsValid] = useState<boolean>(true);
  const [showInNewTab, setShowInNewTab] = useState<boolean>(true);
  const [selection, setSelection] = useState<Selection | null>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  useOutsideClick(popoverContentRef, () => setShowPopover(false));

  const handleResetState = () => {
    setUrl('');
    setUrlIsValid(true);
    setShowInNewTab(true);
  };

  const toggleLink = () => {
    setShowPopover(true);
    setSelection(editor.selection as any);
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(target.value);
    setUrlIsValid(isUrl(target.value));
  };

  const handleCheckboxChange = () => {
    setShowInNewTab((prev) => !prev);
  };

  const handleInsertLink = () => {
    Transforms.select(editor, selection as any);
    insertLink(editor, {
      href: url,
      target: showInNewTab ? LinkTarget.Blank : LinkTarget.Self,
    });
    handleResetState();
    setShowPopover(false);
  };

  const handleRemoveLink = () => {
    removeLink(editor);
    handleResetState();
  };

  const isActive = isBlockActive(editor, 'link');

  if (isActive) {
    return (
      <span className={styles.root}>
        <Button
          className={styles.button}
          size="small"
          variant="error"
          onClick={handleRemoveLink}
          disabled={!editor.selection}
          title="Remove the link"
          type="button"
        >
          <Icon name="v2-link-off" />
        </Button>
      </span>
    );
  }

  return (
    <span className={styles.root}>
      <Tooltip
        className={styles.tooltipRoot}
        classNameArrow={cn(styles.tooltipArrow, {
          [styles.rightTooltipArrow]: toolbarPosition,
        })}
        isOpen={showPopover}
        anchorId="link-url"
        clickable={true}
        place={toolbarPosition}
      >
        <div className={styles.content} ref={popoverContentRef}>
          <div className={styles.title}>Add the link</div>

          <span className={styles.inputWrapper}>
            <Input
              className={cn(styles.input, {
                [styles.isInvalid]: !urlIsValid,
              })}
              fieldSize="small"
              value={url}
              name="url"
              placeholder="https://site.com"
              onChange={handleInputChange}
              type="url"
            />

            <Button
              className={styles.button}
              size="small"
              variant="secondary"
              subVariant="outlined"
              onClick={handleInsertLink}
              disabled={!urlIsValid}
              title="Add"
              type="button"
            >
              <Icon name="plus-in-circle" />
            </Button>
          </span>

          <CheckBox
            className={styles.checkbox}
            checked={showInNewTab}
            name="showInNewTab"
            label="Open in new tab"
            onChange={handleCheckboxChange}
          />

          {!urlIsValid && (
            <Text variant="captionRegular" color="error-default">
              Please add valid url address
            </Text>
          )}
        </div>
      </Tooltip>

      <Button
        className={styles.button}
        size="small"
        variant="secondary"
        subVariant="outlined"
        disabled={!editor.selection}
        title="Create the link"
        type="button"
        id="link-url"
        onClick={toggleLink}
      >
        <Icon className={styles.icon} name="v2-link" />
      </Button>
    </span>
  );
};

export default LinkButton;
