// Types
import { Interviewer } from '../helpers/localTypes';
// Ui
import Text from 'ui3/Text/Text';
// Styles
import styles from './InterviewScheduledStep.module.scss';

type InterviewScheduledStepProps = {
  interviewer: Interviewer;
};

const InterviewScheduledStep = ({
  interviewer,
}: InterviewScheduledStepProps) => {
  return (
    <div className={styles.root}>
      <img
        className={styles.micImage}
        alt="Interview"
        src={'/images/Interview-mic-2.png'}
      />

      <div className={styles.basicInfoWrapper}>
        <Text variant="h2" textAlign="center">
          You Are Booked! <br />
        </Text>
        <Text variant="body1Regular16" color="lights-medium" textAlign="center">
          Your interview has been scheduled. <br />
          Prepare for your interview and meet your host.
        </Text>
      </div>

      <div className={styles.hostInfo}>
        <Text variant="h5" textAlign="center" className={styles.hostTitle}>
          Meet Your Host!
        </Text>

        <div className={styles.hostCard}>
          <div className={styles.avatar}>
            <img
              src={interviewer.storeDetails.avatarURL}
              alt="Host Avatar"
              className={styles.avatar}
            />
          </div>
          <div className={styles.hostInfoText}>
            <Text variant="h5">{`${interviewer.firstName} ${interviewer.lastName}`}</Text>
            <Text variant="body2Regular" color="accent-default">
              MILLIONS Host
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewScheduledStep;
