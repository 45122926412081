import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { parse } from 'query-string';
import { initFacebookSDK } from 'lib/facebook-sdk';
import { useMutation } from '@apollo/client';
// Api
import { FB_AUTH } from 'api/auth/mutations';
// Types
import {
  FbAuth,
  FbAuth_fbAuth_AuthV2SignInResponse,
  FbAuth_fbAuth_SocialSignUpResponse,
  FbAuthVariables,
} from 'api/auth/types/FbAuth';
// Helpers
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
} from 'helpers/storage';
import { handleFirstLogin } from 'helpers/user';
// Hooks
import { useDefferInitialization, useAppContext } from 'hooks';

const FacebookSdkWatcher = (): null => {
  const { setToken, setIsSocialSignUp, setSocialSignUpData } = useAppContext();
  const { asPath, push } = useRouter();

  const ready = useDefferInitialization();

  const [fbAuth] = useMutation<FbAuth, FbAuthVariables>(FB_AUTH);

  const parsedUrl = parse(asPath);
  const facebookAccessToken = parsedUrl['/?#access_token'] as string;

  // if 'redirect' login (build-in browsers)
  useEffect(() => {
    if (facebookAccessToken) {
      const login = async (fbAccessToken: string): Promise<void> => {
        try {
          const { data } = await fbAuth({
            variables: { input: { accessToken: fbAccessToken } },
          });

          if (data?.fbAuth) {
            const isSignup = data.fbAuth.__typename === 'SocialSignUpResponse';
            const redirectPath = getRedirectFromLocalStorage();

            if (isSignup) {
              setIsSocialSignUp(true);
              setSocialSignUpData(
                data.fbAuth as FbAuth_fbAuth_SocialSignUpResponse
              );
            } else {
              const response = data.fbAuth as FbAuth_fbAuth_AuthV2SignInResponse;

              setToken({
                id: response.userId,
                accessToken: response.tokens.accessToken,
                refreshToken: response.tokens.refreshToken,
              });

              handleFirstLogin(response.user.role);
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
              });
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      };

      login(facebookAccessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facebookAccessToken]);

  useEffect(() => {
    const appId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;

    /**
     * Load scripts with a delay to improve page speed
     * performance & a metrics score
     */
    if (appId && ready) {
      initFacebookSDK({
        appId,
      }).catch((error) => {
        console.log('initFacebookSDK', error);
      });
    }
  }, [ready]);

  return null;
};

export default FacebookSdkWatcher;
