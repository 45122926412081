// Type
import { SocialMediaType } from 'api/graphql-global-types';
// Ui
import { IconNames } from 'ui3/MillionsIcon/MillionsIcon';

export const mapSocialPlatformToIcon = (
  platform: SocialMediaType
): IconNames => {
  switch (platform) {
    case SocialMediaType.Facebook:
      return 'facebook';
    case SocialMediaType.Instagram:
      return 'instagram';
    case SocialMediaType.Twitter:
      return 'x';
    case SocialMediaType.Tiktok:
      return 'tiktok';
    case SocialMediaType.Youtube:
      return 'youtube';
  }
};

export const mapSocialPlatformToLabel = (platform: SocialMediaType): string => {
  switch (platform) {
    case SocialMediaType.Facebook:
      return 'Facebook';
    case SocialMediaType.Instagram:
      return 'Instagram';
    case SocialMediaType.Twitter:
      return 'X';
    case SocialMediaType.Tiktok:
      return 'TikTok';
    case SocialMediaType.Youtube:
      return 'YouTube';
  }
};
