import { useEffect } from 'react';
// Helpers
import { isStoreRole } from 'helpers/user';
// Hooks
import { useGetCurrUser, useOnboardingContext } from 'hooks';

const OnboardingV2Watcher = () => {
  const { data } = useGetCurrUser();
  const { setShowOnboardingModal } = useOnboardingContext();

  const user = data?.me;

  useEffect(() => {
    if (user && isStoreRole(user.role) && !user.isOnboarded) {
      // Force onboarding
      setShowOnboardingModal(true);
    }
  }, [user, setShowOnboardingModal]);

  return null;
};

export default OnboardingV2Watcher;
