import { createContext, useContext, useMemo } from 'react';

export type TabsListType = 'underline' | 'pills' | 'segmented';

type TabsContext = {
  type: TabsListType;
};

const TabsContext = createContext<TabsContext>({
  type: 'underline',
});

const TabsContextProvider: React.FC<TabsContext> = ({ type, children }) => {
  const value: TabsContext = useMemo(() => ({ type }), [type]);

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

const useTabContext = (): TabsContext => {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('useTabContext must be used within a TabsContextProvider');
  }

  return context;
};

export { TabsContextProvider, useTabContext };
