import React from 'react';
import { BaseCardProps } from './BaseCard/BaseCard';
import BaseCard from './BaseCard/BaseCard';
import CardLabel, { LabelVariant } from './CardLabel/CardLabel';
import {
  StreamIntervalEnum,
  StreamStatus,
  StreamType,
} from 'api/graphql-global-types';
import { getStreamCardDate } from 'helpers/streams';

export enum ContentType {
  IMAGE = 'image',
  VIDEO = 'video',
  NEWS = 'news',
}

export enum ContentStreamStatus {
  HIDDEN = 'hidden',
}

type StatusConfig = {
  text: string;
  variant: LabelVariant;
};

interface ContentCardProps extends BaseCardProps {
  type: ContentType | StreamType;
  status?: StreamStatus | ContentStreamStatus | StreamIntervalEnum | undefined;
  price?: string;
  tzCode?: string | null;
  hideCategory?: boolean;
}

const statusLabelConfig: Record<string, StatusConfig> = {
  [StreamStatus.Active]: { text: 'live', variant: 'live' },
  [ContentStreamStatus.HIDDEN]: {
    text: ContentStreamStatus.HIDDEN,
    variant: 'disabled',
  },
  [StreamStatus.Ended]: { text: 'past', variant: 'primary' },
};

const intervalLabelConfig: Record<string, StatusConfig> = {
  [StreamIntervalEnum.biweekly]: {
    text: 'Every other week',
    variant: 'accent',
  },
  [StreamIntervalEnum.monthly]: {
    text: StreamIntervalEnum.monthly,
    variant: 'accent',
  },
  [StreamIntervalEnum.weekly]: {
    text: StreamIntervalEnum.weekly,
    variant: 'accent',
  },
};

const TopLabels: React.FC<Partial<ContentCardProps>> = ({
  type,
  status,
  size,
  dateTime,
  tzCode,
  hideCategory,
}) => {
  const time = getStreamCardDate(dateTime as string, tzCode || null, 'label');

  const isStreamType = (t: ContentType | StreamType): t is StreamType => {
    return Object.values(StreamType).includes(t as StreamType);
  };

  if (type && isStreamType(type)) {
    if (type !== StreamType.Stream && type !== StreamType.Interview) {
      return <CardLabel text={'interview'} size={size} variant="secondary" />;
    }

    if (status && status in statusLabelConfig) {
      const config =
        statusLabelConfig[status as keyof typeof statusLabelConfig];
      return (
        <CardLabel text={config.text} size={size} variant={config.variant} />
      );
    }

    if (status && status in intervalLabelConfig) {
      const config =
        intervalLabelConfig[status as keyof typeof intervalLabelConfig];
      return (
        <>
          <CardLabel text={config.text} size={size} variant={config.variant} />
          <CardLabel text={time} size={size} variant="secondary" />
        </>
      );
    }
  }

  return hideCategory ? null : (
    <CardLabel text={type} size={size} variant="secondary" />
  );
};

const BottomLabels: React.FC<Partial<ContentCardProps>> = ({ price, size }) => {
  return <CardLabel text={price} size={size} isCurrency />;
};

const ContentCard: React.FC<ContentCardProps> = ({
  type,
  status,
  size,
  title,
  user,
  dateTime,
  tzCode,
  price,
  imageUrl,
  linkUrl,
  userUrl,
  isPlay,
  width,
  height,
  className,
  descriptionClassName,
  imageWrapperClassname,
  isArticleCard = false,
  hideCategory = false,
}) => {
  const topLabels = TopLabels({
    type,
    status,
    dateTime,
    tzCode,
    size,
    hideCategory,
  });

  const bottomLabels = BottomLabels({ price, size });

  return (
    <BaseCard
      imageUrl={imageUrl}
      title={title}
      user={user}
      dateTime={
        dateTime
          ? getStreamCardDate(dateTime as string, tzCode || null, 'description')
          : ''
      }
      size={size}
      linkUrl={linkUrl}
      userUrl={userUrl}
      topLabels={topLabels}
      bottomLabels={bottomLabels}
      isPlay={isPlay}
      width={width}
      height={height}
      className={className}
      descriptionClassName={descriptionClassName}
      imageWrapperClassname={imageWrapperClassname}
      isArticleCard={isArticleCard}
    />
  );
};

export default ContentCard;
