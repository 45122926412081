import { forwardRef } from 'react';
import cn from 'classnames';
// Styles
import styles from './UsernameInput.module.scss';

type UsernameInputProps = {
  label?: string;
  error?: string | boolean;
  valid?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const UsernameInput = forwardRef<HTMLInputElement, UsernameInputProps>(
  ({ label, name, error, valid, className, ...restProps }, ref) => {
    const isError = Boolean(error);
    const isErrorLabelVisible = typeof error === 'string' && !!error.length;
    const errorId = 'username-error-text';

    return (
      <div className={cn(styles.root, className)}>
        {label && (
          <label htmlFor={name} className={styles.label}>
            {label}
          </label>
        )}
        <input
          ref={ref}
          type="text"
          id={name}
          name={name}
          placeholder="Username"
          className={cn(styles.input, {
            [styles.error]: isError,
            [styles.valid]: valid,
          })}
          {...restProps}
        />
        {isErrorLabelVisible && (
          <span
            id={errorId}
            className={styles.errorLabel}
            role="alert"
            aria-label={errorId}
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

UsernameInput.displayName = 'UsernameInput';

export default UsernameInput;
