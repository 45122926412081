import React, { FC, useEffect, useRef, useState } from 'react';
// Types
import { ContactType } from 'api/graphql-global-types';
// Ui
import { Mention } from 'ui3/Mentions/Mentions';
import Button from 'ui3/Button/Button';
import Text from 'ui3/Text/Text';
// Components
import UnregisteredMentionsSelect from '../UnregisteredMentionsSelect/UnregisteredMentionsSelect';
// Styles
import styles from './OutsideMillionsBrandsSelect.module.scss';

interface OutsideMillionsSelectProps {
  mentions: Mention[];
  onChange: (values: Mention[]) => void;
}

export const OutsideMillionsBrandsSelect: FC<OutsideMillionsSelectProps> = ({
  mentions,
  onChange,
}) => {
  const [unregisteredBrandsMentions, setUnregisteredBrandsMentions] = useState<
    Mention[]
  >(mentions);
  const previousMentionsRef = useRef(unregisteredBrandsMentions);

  useEffect(() => {
    if (mentions && mentions.length > 0) {
      setUnregisteredBrandsMentions(mentions);
    }
  }, [mentions]);

  const handleUnregisteredBrandsChange = (mention, index) => {
    setUnregisteredBrandsMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions[index] = mention;

      return updatedMentions;
    });
  };

  useEffect(() => {
    // Compare the previous and current mentions arrays
    if (
      JSON.stringify(previousMentionsRef.current) !==
      JSON.stringify(unregisteredBrandsMentions)
    ) {
      // Only trigger onChange if the values have actually changed
      onChange(unregisteredBrandsMentions);
      previousMentionsRef.current = unregisteredBrandsMentions; // Update the ref with the current mentions
    }
  }, [unregisteredBrandsMentions, onChange]);

  const addNewBrandsMention = () => {
    setUnregisteredBrandsMentions((prevMentions) => [
      ...prevMentions,
      {
        id: null,
        targetType: null,
        user: null,
        unregisteredContact: null,
      },
    ]);
  };

  const handleOtherAthleteMentionRemove = (index: number) => {
    setUnregisteredBrandsMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions.splice(index, 1);
      return updatedMentions;
    });
  };

  return (
    <div className={styles.otherAthletes}>
      <Text variant="subtitle2">Brands/Organizations outside of MILLIONS</Text>

      <Text variant="body2Regular" color="lights-medium">
        Mention brands by using their names and links to directly engage them
        and increase interaction on your content.
      </Text>

      <div className={styles.athletesSelectContainer}>
        {unregisteredBrandsMentions.length === 0 ? (
          <UnregisteredMentionsSelect
            mention={null}
            index={0}
            type={ContactType.BRAND}
            onChange={handleUnregisteredBrandsChange}
            handleMillionsMentionRemove={handleOtherAthleteMentionRemove}
          />
        ) : (
          unregisteredBrandsMentions.map((mention, index) => (
            <div key={index} className={styles.selectContainer}>
              <UnregisteredMentionsSelect
                mention={mention}
                index={index}
                type={ContactType.BRAND}
                onChange={handleUnregisteredBrandsChange}
                handleMillionsMentionRemove={handleOtherAthleteMentionRemove}
              />
            </div>
          ))
        )}

        {unregisteredBrandsMentions.length < 5 && (
          <div>
            <Button
              type="button"
              variant="primary"
              subVariant="text"
              icon="plus"
              className={styles.addNewButton}
              onClick={addNewBrandsMention}
            >
              Add new
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutsideMillionsBrandsSelect;
