import router from 'next/router';
// Hooks
import { useOnboardingContext } from 'hooks';
// Constants
import { DASHBOARD } from 'constants/routes';
// Components
import FullScreenStepperModal from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import StartWithMillionsStep from './StartWithMillionsStep/StartWithMillionsStep';
import EcommerceSetup from './EcommerceSetup/EcommerceSetup';
import StreamingSetup from './StreamingSetup/StreamingSetup';
import BrandDealsSetup from './BrandDealsSetup/BrandDealsSetup';

const CreatorSetup = () => {
  const {
    showCreatorSetupModal,
    setShowCreatorSetupModal,
  } = useOnboardingContext();

  const handleClose = () => {
    setShowCreatorSetupModal(false);
    router.push(DASHBOARD);
  };

  return (
    <FullScreenStepperModal
      title="START WITH MILLIONS"
      open={showCreatorSetupModal}
      onClose={handleClose}
      footer={{
        showPrevButton: false,
        submitButtonText: 'Skip',
        submitButtonProps: {
          onClick: handleClose,
          variant: 'primary',
          subVariant: 'text',
        },
      }}
    >
      <>
        <StartWithMillionsStep />

        <EcommerceSetup />

        <StreamingSetup />

        <BrandDealsSetup />
      </>
    </FullScreenStepperModal>
  );
};

export default CreatorSetup;
