import { forwardRef } from 'react';
import cn from 'classnames';
// Styles
import styles from './Switch.module.scss';

type SwitchProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'className' | 'name'
> & {
  name: string;
  label?: string;
  id?: string;
  boldLabel?: boolean;
  wrapperClassName?: string;
};

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ label, wrapperClassName, name, id = name, boldLabel, ...rest }, ref) => {
    return (
      <div className={cn(styles.root, wrapperClassName)}>
        <input
          className={styles.checkbox}
          ref={ref}
          id={id}
          name={name}
          aria-label={`${name} checkbox`}
          type="checkbox"
          {...rest}
        />

        <label
          htmlFor={id}
          className={cn(styles.switchWrapper, {
            [styles.withText]: label?.length,
            [styles.bold]: boldLabel,
          })}
        >
          {label}
        </label>
      </div>
    );
  }
);

Switch.displayName = 'Switch';

export default Switch;
