import cn from 'classnames';
// Hooks
import { useOnboardingContext } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
// Styles
import styles from './StartWithMillionsStep.module.scss';

const StartWithMillionsStep = () => {
  const {
    ecommerceSetupDone,
    streamingSetupDone,
    brandDealsSetupDone,
    setShowEcommerceSetupModal,
    setShowStreamingSetupModal,
    setShowBrandDealsSetupModal,
  } = useOnboardingContext();

  return (
    <div className={styles.root}>
      <div className={styles.textContainer}>
        <Text textAlign="center" className={styles.title}>
          What Do You Want To Use <br /> MILLIONS For?
        </Text>

        <Text
          color="lights-medium"
          textAlign="center"
          className={styles.subtitle}
        >
          Let&apos;s set up your profile to start using more of MILLIONS
        </Text>
      </div>

      <div className={styles.buttonsContainer}>
        <button
          type="button"
          disabled={ecommerceSetupDone}
          className={cn(styles.button, {
            [styles.finished]: ecommerceSetupDone,
          })}
          onClick={() => setShowEcommerceSetupModal(true)}
        >
          <img src={'/images/ecommerce.png'} alt="Ecommerce" />
          <Text variant="h6" uppercase>
            E-commerce
          </Text>

          <Text variant="body2Regular" color="lights-medium">
            Create new merch, memorabilia or products and sell it.
          </Text>
        </button>

        <button
          type="button"
          disabled={streamingSetupDone}
          className={cn(styles.button, {
            [styles.finished]: streamingSetupDone,
          })}
          onClick={() => setShowStreamingSetupModal(true)}
        >
          <img src={'/images/streaming.png'} alt="Streaming & Media" />
          <Text variant="h6" uppercase>
            Streaming & Media
          </Text>

          <Text variant="body2Regular" color="lights-medium">
            Create content, host or join interviews, create podcasts or streams
          </Text>
        </button>

        <button
          type="button"
          disabled={brandDealsSetupDone}
          className={cn(styles.button, {
            [styles.finished]: brandDealsSetupDone,
          })}
          onClick={() => setShowBrandDealsSetupModal(true)}
        >
          <img src={'/images/brand-deals.png'} alt="Brand Deals" />
          <Text variant="h6" uppercase>
            Brand Deals
          </Text>

          <Text variant="body2Regular" color="lights-medium">
            Apply to brand campaigns, get offers, and earn money.
          </Text>
        </button>
      </div>
    </div>
  );
};

export default StartWithMillionsStep;
