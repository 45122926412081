// Hooks
import { useOnboardingContext } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
import ProfileStepForm from './ProfileStepForm/ProfileStepForm';
// Styles
import styles from './ProfileStep.module.scss';

const ProfileStep = () => {
  const { onboardingStep } = useOnboardingContext();

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          <StepChip step={onboardingStep} />

          <Text variant="h2">Build Your Profile</Text>

          <Text variant="body1Regular16" color="lights-medium">
            Set up your profile to start engaging with fans and brands.
          </Text>
        </div>
      }
      right={<ProfileStepForm />}
    />
  );
};

export default ProfileStep;
