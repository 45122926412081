import { forwardRef, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './CheckBox.module.scss';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  labelClassName?: string;
  boldLabel?: boolean;
  alignLabelTop?: boolean;
  id?: string;
};

const CheckBox = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      labelClassName,
      boldLabel,
      className,
      alignLabelTop,
      id = name,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={cn(styles.checkBoxContainer, className)}>
        <input
          className={styles.checkBoxInput}
          ref={ref}
          name={name}
          id={id}
          type="checkbox"
          {...rest}
        />

        <label
          htmlFor={id}
          className={cn(
            styles.checkBoxLabel,
            { [styles.bold]: boldLabel, [styles.alignTop]: alignLabelTop },
            labelClassName
          )}
        >
          {label}
        </label>
      </div>
    );
  }
);

CheckBox.displayName = 'CheckBox';

export default CheckBox;
