import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { parse } from 'query-string';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// Lib
import { initGoogleSDK } from 'lib/google-sdk';
// Api
import { GOOGLE_AUTH } from 'api/auth/mutations';
// Types
import {
  GoogleAuth,
  GoogleAuth_googleAuth_AuthV2SignInResponse,
  GoogleAuth_googleAuth_SocialSignUpResponse,
  GoogleAuthVariables,
} from 'api/auth/types/GoogleAuth';
// Helpers
import { getProperErrorMessage } from 'helpers/errors';
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
  clearStoreSocialSignupFromLocalStorage,
} from 'helpers/storage';
import { handleFirstLogin } from 'helpers/user';
// Hooks
import { useDefferInitialization, useAppContext } from 'hooks';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';

const GoogleSdkWatcher = (): null | JSX.Element => {
  const {
    setToken,
    setGoogleSkdFailedStatus,
    setIsSocialSignUp,
    setSocialSignUpData,
  } = useAppContext();
  const { asPath, push } = useRouter();
  const [isInitialized, setInitialization] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const ready = useDefferInitialization();

  const [googleAuth] = useMutation<GoogleAuth, GoogleAuthVariables>(
    GOOGLE_AUTH
  );

  const parsedUrl = parse(asPath);
  const code = parsedUrl['/#code'] as string;

  useEffect(() => {
    if (code) {
      setLoading(true);
    }

    if (code && isInitialized) {
      const login = async (): Promise<void> => {
        try {
          const { data } = await googleAuth({
            variables: {
              input: {
                code,
                // setting redirectUrl only for Local env, for others it retrieved from ENV on BE side
                ...(process.env.NODE_ENV === 'development' && {
                  redirectUrl: 'http://localhost:4000',
                }),
              },
            },
          });

          if (data?.googleAuth) {
            const isSignup =
              data.googleAuth.__typename === 'SocialSignUpResponse';
            const redirectPath = getRedirectFromLocalStorage();

            if (isSignup) {
              setIsSocialSignUp(true);
              setSocialSignUpData(
                data.googleAuth as GoogleAuth_googleAuth_SocialSignUpResponse
              );
              setLoading(false);
            } else {
              const response = data.googleAuth as GoogleAuth_googleAuth_AuthV2SignInResponse;

              setToken({
                id: response.userId,
                accessToken: response.tokens.accessToken,
                refreshToken: response.tokens.refreshToken,
              });

              handleFirstLogin(response.user.role);
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
              });
            } else {
              push({
                query: '',
              });
            }
          }
        } catch (error) {
          console.log(error);
          push({
            query: '',
          });

          showToast({
            message: getProperErrorMessage(
              error,
              'Looks like something went wrong. Please try again later.'
            ),
            type: 'error',
          });
          console.log('error', error);
          Sentry.captureMessage(`Google sign up: ${JSON.stringify(error)}`, {
            tags: {
              section: 'auth',
            },
          });
          clearStoreSocialSignupFromLocalStorage();
        }

        setLoading(false);
      };

      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, isInitialized]);

  useEffect(() => {
    const appId = process.env.NEXT_PUBLIC_GOOGLE_APP_ID;

    /**
     * Load scripts with a delay to improve page speed
     * performance & a metrics score
     */
    if (appId && ready) {
      initGoogleSDK({
        appId,
      })
        .then(() => {
          setInitialization(true);
        })
        .catch((error) => {
          setGoogleSkdFailedStatus(true);
          setLoading(false);
          console.log('initGoogleSDK', error);
        });
    }
  }, [ready, setGoogleSkdFailedStatus]);

  if (loading) {
    return <FourDotsLoader />;
  }

  return null;
};

export default GoogleSdkWatcher;
