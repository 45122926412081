import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import startCase from 'lodash/startCase';
import cn from 'classnames';
import * as yup from 'yup';
import dayjs from 'dayjs';
import router from 'next/router';
// Api
import {
  ESTIMATE_PRODUCT_PRICE,
  GET_MEMORABILIA,
  GET_SHIPMENT_CATEGORIES,
} from 'api/memorabilia/queries';
import { CreateMemorabiliaVariables } from 'api/memorabilia/types/CreateMemorabilia';
import {
  CREATE_MEMORABILIA,
  CREATE_PRESIGNED_URL,
  UPDATE_MEMORABILIA,
} from 'api/memorabilia/mutations';
// Types
import {
  CreateMemorabiliaInput,
  MemorabiliaFulfillment,
  MemorabiliaProductType,
  SortDirection,
  UpdateMemorabiliaInput,
} from 'api/graphql-global-types';
import {
  GetShipmentCategories,
  GetShipmentCategories_getShipmentCategories,
} from 'api/memorabilia/types/GetShipmentCategories';
import {
  GetMemorabiliaAuthorized_getMemorabilia_entities,
  GetMemorabiliaAuthorized_getMemorabilia_entities_originAddress,
} from 'api/memorabilia/types/GetMemorabiliaAuthorized';
import { AddressFields } from 'api/address/types/AddressFields';
import {
  EstimateProductPrice,
  EstimateProductPriceVariables,
} from 'api/memorabilia/types/EstimateProductPrice';
import { UpdateMemorabiliaVariables } from 'api/memorabilia/types/UpdateMemorabilia';
import {
  CreatePresignedUrl,
  CreatePresignedUrl_createPresignedUrl,
  CreatePresignedUrlVariables,
} from 'api/memorabilia/types/CreatePresignedUrl';
// Helpers
import { UploadImage } from 'helpers/mediaPost';
import { getDelta } from 'helpers/getDelta';
import { formatPrice } from 'helpers/formatPrice';
// Hooks
import { useGetCurrUser, useGetMyAddresses, useOnboardingContext } from 'hooks';
// Constants
import { DASHBOARD } from 'constants/routes';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Select from 'ui3/Select/Select';
import Input from 'ui3/Input/Input';
import TextArea from 'ui3/TextArea/TextArea';
import DatePicker from 'ui3/DatePicker/DatePicker';
import TagsInput from 'ui3/TagsInput/TagsInput';
import Button from 'ui3/Button/Button';
import Text from 'ui3/Text/Text';
// Components
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import AddressCard from 'components/common3/AddressCard/AddressCard';
import UploadMultipleImages from 'components/common3/UploadImage/UploadMultipleImages/UploadMultipleImages';
import ManageAddressModal from 'components/common3/ManageAddressModal/ManageAddressModal';
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './MemorabiliaSetup.module.scss';

type FormValues = {
  title: string;
  description: string;
  fulfillment: MemorabiliaFulfillment;
  memorabiliaProductType: MemorabiliaProductType;
  requestedPrice: number | null;
  numberOfUnits: number | null;
  shipmentCategory: any;
  address: GetMemorabiliaAuthorized_getMemorabilia_entities_originAddress | null;
  customsDescription: string;
  hashtags?: string[];
  isForAuction: boolean;
  onAuctionFrom: Date | null;
  onAuctionTo: Date | null;
  images: UploadImage[];
};

type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | string;
};

const formatHashtagInput = (hashtags) => {
  if (hashtags?.length) {
    return hashtags?.map((hashtag) => ({ name: hashtag }));
  } else {
    return null;
  }
};

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .max(140, 'Max 140 char')
    .required('You need to provide the name of the product'),
  description: yup
    .string()
    .max(1000, 'Max 1000 char')
    .required('You need to provide the description of the product'),
  shipmentCategory: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required('"Shipment category" is a required field')
    .nullable(),
  requestedPrice: yup
    .number()
    .required('Please add requested price')
    .test(
      'Is positive?',
      'Price must be a positive number',
      (value) => value !== null && value !== undefined && value > 0
    )
    .typeError('Price must be type of number'),
  numberOfUnits: yup
    .number()
    .required('Please add number of units')
    .test(
      'Is positive?',
      'Number of units must be a positive number',
      (value) => value !== null && value !== undefined && value > 0
    )
    .typeError('Number of units must be type of number'),
  customsDescription: yup
    .string()
    .max(200, 'Max 200 char')
    .required('Please provide the customs description (Max 200 characters)'),
  isForAuction: yup.boolean(),
  onAuctionFrom: yup
    .date()
    .typeError('On auction from must be type of date')
    .when('isForAuction', {
      is: true,
      then: yup
        .date()
        .required('On auction from is required')
        .typeError('On auction to must be type of date'),
    }),
  onAuctionTo: yup
    .date()
    .typeError('On auction to must be type of date')
    .when('isForAuction', {
      is: true,
      then: yup
        .date()
        .required('On auction to is required')
        .typeError('On auction to must be type of date'),
    }),
  images: yup
    .array()
    .of(
      yup.object().shape({
        data_url: yup.string(),
        file: yup.mixed(),
      })
    )
    .required('Please provide at least one image'),
});

type MemorabiliaSetupProps = {
  record?: GetMemorabiliaAuthorized_getMemorabilia_entities | null;
  onClose?: () => void;
};

const MemorabiliaSetup = ({ record, onClose }: MemorabiliaSetupProps) => {
  const {
    productType: type,
    showCreateProductMemorabiliaModal,
    setShowCreateProductMemorabiliaModal,
    setCreateMemorabiliaDone,
    setCreateProductDone,
  } = useOnboardingContext();
  const { data: currentUserData } = useGetCurrUser();
  const currentUser = currentUserData?.me;

  const [productType, setProductType] = useState<MemorabiliaProductType>(
    record?.memorabiliaProductType || type
  );
  const isEdit = !!record;

  const imagesSortedByMain = record?.images?.slice().sort((a, b) => {
    if (a.isMainImage === b.isMainImage) {
      return 0;
    } else if (a.isMainImage === true) {
      return -1;
    } else {
      return 1;
    }
  });

  const initialFormValues: FormValues = {
    title: record?.title || '',
    description: record?.description || '',
    fulfillment: record?.fulfillment || MemorabiliaFulfillment.Athlete,
    memorabiliaProductType: productType,
    requestedPrice: record?.requestedPrice || null,
    numberOfUnits: record?.numberOfUnits || null,
    shipmentCategory: record?.shipmentCategory
      ? {
          slug: record.shipmentCategory,
          name: startCase(record.shipmentCategory),
        }
      : '',
    customsDescription: record?.customsDescription || '',
    address: record?.originAddress || null,
    isForAuction: record?.isForAuction || false,
    hashtags: record?.hashtags.map((tag) => tag.name) || [],
    onAuctionFrom: record?.onAuctionFrom
      ? new Date(record.onAuctionFrom)
      : null,
    onAuctionTo: record?.onAuctionTo ? new Date(record.onAuctionTo) : null,
    images:
      imagesSortedByMain?.map((item) => ({
        data_url: item.url || undefined,
        data_key: item.key || undefined,
      })) || [],
  };

  const {
    errors,
    control,
    register,
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const memorabiliaProductType = watch('memorabiliaProductType');

  const [isForAuction, setIsForAuction] = useState<boolean>(
    initialFormValues.isForAuction
  );

  const [
    shippingAddress,
    setShippingAddress,
  ] = useState<Partial<AddressFields> | null>(initialFormValues.address);
  const [mainImageIndex, setMainImageIndex] = useState<number>(0);
  const [addressModalVisibility, setAddressModalVisibility] = useState<boolean>(
    false
  );
  const [imagesUploading, setImagesUploading] = useState<boolean>(false);
  const [finalPrice, setFinalPrice] = useState<number>(0);

  const currentPrice = watch('requestedPrice');
  const currentNumberOfItems = watch('numberOfUnits');
  const onAuctionFrom = watch('onAuctionFrom');
  const onAuctionTo = watch('onAuctionTo');

  const { data: addressData, loading: loadingAddresses } = useGetMyAddresses();
  const myAddresses = addressData?.getMyAddresses;

  const handleClose = useCallback(() => {
    setShowCreateProductMemorabiliaModal(false, type);

    if (onClose) {
      onClose();
    }
  }, [onClose, setShowCreateProductMemorabiliaModal, type]);

  const handleSuccess = useCallback(() => {
    if (memorabiliaProductType === MemorabiliaProductType.Memorabilia) {
      setCreateMemorabiliaDone(true);
    } else {
      setCreateProductDone(true);
    }
    handleClose();
    router.push(DASHBOARD);
  }, [
    handleClose,
    memorabiliaProductType,
    setCreateMemorabiliaDone,
    setCreateProductDone,
  ]);

  const [
    estimateProductPrice,
    { loading: loadingPriceEstimate },
  ] = useLazyQuery<EstimateProductPrice, EstimateProductPriceVariables>(
    ESTIMATE_PRODUCT_PRICE,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const fetchPrice = async () => {
      if (currentPrice && currentNumberOfItems) {
        const { data } = await estimateProductPrice({
          variables: {
            input: {
              fulfillment: MemorabiliaFulfillment.Athlete,
              numberOfUnits: Number(currentNumberOfItems),
              requestedPrice: Number(currentPrice),
            },
          },
        });

        if (data?.estimateProductPrice) {
          setFinalPrice(data.estimateProductPrice.price);
        }
      }
    };
    fetchPrice();
  }, [currentPrice, currentNumberOfItems, estimateProductPrice]);

  const { data: shipmentCategories } = useQuery<GetShipmentCategories>(
    GET_SHIPMENT_CATEGORIES
  );

  const [createMemorabilia, { loading: createLoading }] = useMutation<
    CreateMemorabiliaInput,
    CreateMemorabiliaVariables
  >(CREATE_MEMORABILIA, {
    refetchQueries: [
      {
        query: GET_MEMORABILIA,
        variables: {
          input: {
            direction: SortDirection.DESC,
            isOrphanPage: null,
            limit: 20,
            offset: 0,
            orderBy: 'createdAt',
            storeIds: currentUser?.id ? [currentUser.id] : [],
            storeStatuses: null,
          },
        },
      },
    ],
  });

  const [updateMemorabilia, { loading: updateLoading }] = useMutation<
    UpdateMemorabiliaInput,
    UpdateMemorabiliaVariables
  >(UPDATE_MEMORABILIA);

  const [
    createPresignedUrl,
    { loading: createPresignedUrlLoading },
  ] = useMutation<CreatePresignedUrl, CreatePresignedUrlVariables>(
    CREATE_PRESIGNED_URL
  );

  const getImagesToUpload = (
    preSignedUrls: CreatePresignedUrl_createPresignedUrl[],
    images: UploadImage[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach(({ file }, index) => {
      const preSignedUrl = preSignedUrls?.[index];

      const imageExtension = file
        ? file.name.split('.')[file.name.split('.').length - 1]
        : '';

      const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

      res.push({
        fields: preSignedUrl?.fields || '',
        url: preSignedUrl?.url || '',
        name: imageName,
        type: file?.type || '',
        file: file || '',
      });
    });

    return res;
  };

  const uploadImages = async (
    preSignedUrls: CreatePresignedUrl_createPresignedUrl[],
    images: UploadImage[]
  ): Promise<string[]> => {
    const imagesToUpload = getImagesToUpload(preSignedUrls, images);
    setImagesUploading(true);

    for (const image of imagesToUpload) {
      const { fields, url, name, type, file } = image;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', name);
      formData.append('Content-Type', type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    }

    setImagesUploading(false);

    return imagesToUpload.map(({ name }) => name);
  };

  const checkAndAdjustAuctionStartTime = (onAuctionFrom) => {
    if (dayjs(onAuctionFrom).isBefore(dayjs())) {
      return dayjs(onAuctionFrom).add(1, 'minute');
    }

    return onAuctionFrom;
  };

  const handleCreateMemorabilia = async (values: FormValues) => {
    const {
      title,
      description,
      requestedPrice,
      numberOfUnits,
      shipmentCategory,
      customsDescription,
      onAuctionFrom,
      onAuctionTo,
      images,
      hashtags,
    } = values;

    try {
      const { data } = await createPresignedUrl({
        variables: {
          input: {
            numberOfImages: images.length,
          },
        },
      });

      if (data) {
        try {
          const imagesUrls = await uploadImages(
            data.createPresignedUrl,
            images
          );

          if (imagesUrls) {
            const millionsFulfillmentInput = {
              title,
              description,
              images: imagesUrls.map((item) => ({
                isMainImage: imagesUrls.indexOf(item) === mainImageIndex,
                memorabiliaImageFileKey: item,
              })),
              fulfillment: MemorabiliaFulfillment.Athlete,
              memorabiliaProductType: memorabiliaProductType,
              requestedPrice: requestedPrice || 0,
              numberOfUnits: numberOfUnits || 0,
              storeId: currentUser?.id || '',
              hashtagInputs: formatHashtagInput(hashtags),
              width: 25.4,
              height: 25.4,
              length: 25.4,
              weight: 0.907185,
              shipmentCategory: shipmentCategory.slug,
              customsDescription,
              auctionDateRange: isForAuction
                ? {
                    onAuctionFrom: checkAndAdjustAuctionStartTime(
                      onAuctionFrom
                    ),
                    onAuctionTo,
                  }
                : null,
            };

            const athleteFulfillment = {
              ...millionsFulfillmentInput,
              addressId: shippingAddress?.id,
            };

            try {
              await createMemorabilia({
                variables: {
                  input: athleteFulfillment,
                },
              });
              showToast({
                message: 'New item successfully created',
              });

              handleSuccess();
            } catch (error) {
              showToast({
                message: error?.message,
                type: 'error',
              });
            }
          }
        } catch (error) {
          showToast({
            message: error?.message,
            type: 'error',
          });
        }
      }
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  };

  const handleEditMemorabilia = async (values: FormValues) => {
    const {
      title,
      description,
      requestedPrice,
      numberOfUnits,
      shipmentCategory,
      customsDescription,
      images,
      hashtags,
    } = values;

    const newImages = images.filter((item: UploadImage) => {
      return item.file;
    });

    const existingImages = images.filter((item: UploadImage) => {
      return !item.file;
    });

    try {
      const { data } = await createPresignedUrl({
        variables: {
          input: {
            numberOfImages: newImages.length,
          },
        },
      });

      if (data) {
        try {
          const imageKeys = await uploadImages(
            data.createPresignedUrl,
            newImages
          );

          if (imageKeys) {
            // here we combine old images and new ones, uploading only new ones to s3
            const newAndExistingImages = [
              ...existingImages.map((item: any) => item.data_key),
              ...imageKeys,
            ];

            try {
              const currenNumberOfUnits = record?.numberOfUnits || 0;

              await updateMemorabilia({
                variables: {
                  input: {
                    id: record?.id || '',
                    storeId: record?.storeId || '',
                    addressId: shippingAddress?.id,
                    title,
                    description,
                    customsDescription,
                    hashtagInputs: formatHashtagInput(hashtags),
                    shipmentCategory:
                      shipmentCategory.slug ?? record?.shipmentCategory,
                    requestedPrice: requestedPrice || 0,
                    changedStock: getDelta(
                      currenNumberOfUnits,
                      numberOfUnits || 0
                    ),
                    images: newAndExistingImages.map((item) => ({
                      isMainImage:
                        newAndExistingImages.indexOf(item) === mainImageIndex,
                      memorabiliaImageFileKey: item,
                    })),
                  },
                },
              });
              showToast({
                message: 'New item successfully updated',
              });
              handleSuccess();
            } catch (error) {
              showToast({
                message: error?.message,
                type: 'error',
              });
            }
          }
        } catch (error) {
          showToast({
            message: error?.message,
            type: 'error',
          });
        }
      }
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  };

  const handleFinish = handleSubmit(async (values: FormValues) => {
    if (!shippingAddress?.id) {
      showToast({
        message: 'Please select an address',
        type: 'error',
      });
    } else if (record?.id) {
      await handleEditMemorabilia(values);
    } else {
      await handleCreateMemorabilia(values);
    }
  });

  const handleAddressModalOpen = () => {
    setAddressModalVisibility(true);
  };

  const handleAddressModalClose = () => {
    setAddressModalVisibility(false);
  };

  const handleSetShippingAddress = (address: AddressFields | null = null) => {
    setShippingAddress(address);
  };

  const changeTypeToMemorabilia = () => {
    setProductType(MemorabiliaProductType.Memorabilia);
  };

  const changeTypeToProduct = () => {
    setProductType(MemorabiliaProductType.Product);
  };

  const changePriceToAuction = () => {
    if (!isForAuction) {
      setIsForAuction(true);
      setValue('numberOfUnits', 1);
    }
  };

  const changePriceToFixed = () => {
    if (isForAuction) {
      setIsForAuction(false);
    }
  };

  const modalTitle = useMemo(() => {
    switch (memorabiliaProductType) {
      case MemorabiliaProductType.Memorabilia:
        return isEdit ? 'EDIT MEMORABILIA' : 'NEW MEMORABILIA';
      case MemorabiliaProductType.Product:
        return isEdit ? 'EDIT PRODUCT' : 'NEW PRODUCT';
      default:
        return '';
    }
  }, [memorabiliaProductType, isEdit]);

  const onAuctionFromMinTime =
    (onAuctionFrom && dayjs(onAuctionFrom).isSame(dayjs(), 'day')) ||
    !onAuctionFrom
      ? dayjs().toDate()
      : undefined;
  const onAuctionFromMaxTime =
    (onAuctionFrom && dayjs(onAuctionFrom).isSame(dayjs(), 'day')) ||
    !onAuctionFrom
      ? dayjs().endOf('day').toDate()
      : undefined;
  const onAuctionToMinTime =
    onAuctionFrom &&
    onAuctionTo &&
    dayjs(onAuctionTo).isSame(dayjs(onAuctionFrom).add(7, 'days'), 'day')
      ? dayjs(onAuctionTo).startOf('day').toDate()
      : onAuctionFrom && !onAuctionTo
      ? dayjs(dayjs(onAuctionFrom).add(15, 'minutes')).toDate()
      : undefined;
  const onAuctionToMaxTime =
    onAuctionFrom &&
    onAuctionTo &&
    dayjs(onAuctionTo).isSame(dayjs(onAuctionFrom).add(7, 'days'), 'day')
      ? dayjs(dayjs(onAuctionFrom).add(7, 'days')).toDate()
      : onAuctionFrom && !onAuctionTo
      ? dayjs().endOf('day').toDate()
      : undefined;

  const loading =
    loadingAddresses ||
    createLoading ||
    updateLoading ||
    imagesUploading ||
    createPresignedUrlLoading ||
    loadingPriceEstimate;

  const footer: FullScreenStepperModalFooter = useMemo(() => {
    return {
      submitButtonText: 'Publish',
      submitButtonProps: {
        onClick: handleFinish,
        variant: 'primary',
        loading: loading,
      },
      showPrevButton: false,
      leftSection: (
        <div className={styles.finalPrice}>
          <span className={styles.finalPriceLabel}>
            <Text variant="subtitle2">Final price</Text>
            <MillionsIcon name="infoCircle" size={16} />
          </span>
          <Text variant="h5">{formatPrice(finalPrice)}</Text>
        </div>
      ),
    };
  }, [finalPrice, handleFinish, loading]);

  return (
    <FullScreenStepperModal
      title={modalTitle}
      open={showCreateProductMemorabiliaModal}
      onClose={handleClose}
      footer={footer}
    >
      <SplitLeftRightView
        left={
          <div>
            <Text variant="h6">Item Type</Text>
            <form className={styles.form}>
              <div className={cn(styles.section)}>
                <Text variant="body1Regular16" color="lights-medium">
                  Is your item a product or memorabilia.
                </Text>

                <Controller
                  name="memorabiliaProductType"
                  control={control}
                  render={({ onChange, value }) => {
                    const onClick = (type: MemorabiliaProductType) => {
                      if (type === MemorabiliaProductType.Memorabilia) {
                        changeTypeToMemorabilia();
                      } else {
                        changeTypeToProduct();
                      }
                      onChange(type);
                    };
                    return (
                      <div className={styles.radioGroup}>
                        <Button
                          type="button"
                          variant={
                            value === MemorabiliaProductType.Product
                              ? 'primary'
                              : 'secondary'
                          }
                          subVariant={
                            value === MemorabiliaProductType.Product
                              ? 'outlined'
                              : undefined
                          }
                          disabled={isEdit}
                          onClick={() =>
                            onClick(MemorabiliaProductType.Product)
                          }
                        >
                          Product
                        </Button>
                        <div className={styles.radioButton}>
                          <Button
                            type="button"
                            variant={
                              value === MemorabiliaProductType.Memorabilia
                                ? 'primary'
                                : 'secondary'
                            }
                            subVariant={
                              value === MemorabiliaProductType.Memorabilia
                                ? 'outlined'
                                : undefined
                            }
                            disabled={isEdit}
                            onClick={() =>
                              onClick(MemorabiliaProductType.Memorabilia)
                            }
                          >
                            Memorabilia
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>

              <div className={styles.section}>
                <Text variant="h6">Details</Text>

                <Text variant="body1Regular16" color="lights-medium">
                  Please provide detailed information about your item.
                </Text>

                <Controller
                  name="shipmentCategory"
                  control={control}
                  render={({ onChange, ...rest }) => (
                    <Select
                      label="Product Category"
                      placeholder="Select product category"
                      options={shipmentCategories?.getShipmentCategories}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.slug}
                      isLoading={loading}
                      isSearchable
                      id="shipmentCategory"
                      inputId="shipmentCategorySelect"
                      error={errors?.shipmentCategory?.message}
                      fieldSize="large"
                      onChange={(
                        data: GetShipmentCategories_getShipmentCategories
                      ) => onChange(data)}
                      {...rest}
                    />
                  )}
                />

                <div className={styles.row}>
                  <Input
                    ref={register}
                    className={styles.productName}
                    label="Item title"
                    name="title"
                    placeholder="Add title"
                    error={errors?.title?.message}
                  />
                </div>

                <div className={styles.row}>
                  <TextArea
                    ref={register}
                    label="Description"
                    name="description"
                    placeholder="Add description"
                    error={errors?.description?.message}
                    rows={isMobile ? 5 : 3}
                    type="textarea"
                  />
                </div>

                <Controller
                  name="hashtags"
                  control={control}
                  render={({ onChange, value: tags }) => (
                    <TagsInput
                      tags={tags}
                      label="Tagging"
                      onChange={onChange}
                      name="hashtags"
                    />
                  )}
                />
              </div>

              <div className={styles.section}>
                <Text variant="h6">Pricing</Text>
                <Text variant="body1Regular16" color="lights-medium">
                  Please select type of sale and enter the price.
                </Text>
                <Controller
                  name="isForAuction"
                  control={control}
                  render={({ onChange, value }) => {
                    const onClick = (isForAuction: boolean) => {
                      if (isForAuction) {
                        changePriceToAuction();
                      } else {
                        changePriceToFixed();
                      }
                      onChange(isForAuction);
                    };

                    return (
                      <div className={styles.radioGroup}>
                        <div className={styles.radioButton}>
                          <Button
                            disabled={isEdit}
                            variant={!value ? 'primary' : 'secondary'}
                            subVariant={!value ? 'outlined' : undefined}
                            onClick={() => onClick(false)}
                          >
                            Fixed price
                          </Button>
                        </div>
                        <div className={styles.radioButton}>
                          <Button
                            disabled={isEdit}
                            variant={value ? 'primary' : 'secondary'}
                            subVariant={value ? 'outlined' : undefined}
                            onClick={() => onClick(true)}
                          >
                            Auction
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                />

                {isForAuction && (
                  <div className={styles.twoItemsInRow}>
                    <div className={styles.item}>
                      <Controller
                        control={control}
                        name="onAuctionFrom"
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <DatePicker
                              showYearDropdown
                              showMonthDropdown
                              yearDropdownItemNumber={70}
                              selected={value}
                              disabled={isEdit}
                              onBlur={onBlur}
                              onChange={onChange}
                              showTimeSelect
                              placeholderText="Type here"
                              minDate={dayjs().toDate()}
                              minTime={onAuctionFromMinTime}
                              maxTime={onAuctionFromMaxTime}
                              customInput={
                                <Input
                                  aria-label="Choose auction start time"
                                  name="dateTimeInput"
                                  label="Auction Start Date"
                                  error={errors?.onAuctionFrom?.message}
                                />
                              }
                            />
                          );
                        }}
                      />
                    </div>

                    <div className={styles.item}>
                      <Controller
                        control={control}
                        name="onAuctionTo"
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <DatePicker
                              showYearDropdown
                              showMonthDropdown
                              yearDropdownItemNumber={70}
                              disabled={!onAuctionFrom || isEdit}
                              selected={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              showTimeSelect
                              placeholderText="Type here"
                              minDate={
                                onAuctionFrom
                                  ? dayjs(onAuctionFrom).toDate()
                                  : dayjs().toDate()
                              }
                              maxDate={
                                onAuctionFrom
                                  ? dayjs(onAuctionFrom).add(7, 'days').toDate()
                                  : dayjs().add(7, 'days').toDate()
                              }
                              minTime={onAuctionToMinTime}
                              maxTime={onAuctionToMaxTime}
                              customInput={
                                <Input
                                  aria-label="Choose auction end time"
                                  label="Auction End Date"
                                  name="dateTimeInput"
                                  error={errors?.onAuctionTo?.message}
                                />
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className={styles.twoItemsInRow}>
                  <div className={styles.item}>
                    <Input
                      type="number"
                      ref={register}
                      name="requestedPrice"
                      label="Price"
                      placeholder="Price"
                      readOnly={isEdit && isForAuction}
                      error={errors?.requestedPrice?.message}
                      hint="Platform fee will be added on top."
                    />
                  </div>
                  <div className={styles.item}>
                    <Input
                      type="number"
                      ref={register}
                      label="Number of units"
                      name="numberOfUnits"
                      placeholder="Number of units"
                      readOnly={isForAuction}
                      error={errors?.numberOfUnits?.message}
                      hint="How many units of this item are you selling?"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <Text variant="h6">Shipment & Customs</Text>
                <Text variant="body1Regular16" color="lights-medium">
                  Please describe the product for customs in the case of
                  international orders of your items. Please put your shipping
                  address where you&apos;ll ship the units from. Shipping labels
                  will be provided to you after an order is placed.
                </Text>

                <TextArea
                  ref={register}
                  className={styles.row}
                  label="Customs Description"
                  name="customsDescription"
                  placeholder={`Add description as instruction to customs.`}
                  error={errors?.customsDescription?.message}
                  type="textarea"
                  rows={isMobile ? 5 : 3}
                />

                <section className={styles.row}>
                  {!myAddresses?.length ? (
                    <div className={styles.emptyPlaceholder}>
                      <p className={styles.emptyText}>
                        {"You don't have shipping address yet. You can "}
                        <button
                          className={styles.emptyTextCta}
                          onClick={handleAddressModalOpen}
                          type="button"
                        >
                          add it now
                        </button>
                      </p>
                    </div>
                  ) : (
                    <ul className={styles.addressList}>
                      {myAddresses?.map((address) => (
                        <li key={address.id}>
                          <AddressCard
                            showPhoneAndEmail
                            address={address}
                            onSelect={handleSetShippingAddress}
                            isSelected={shippingAddress?.id === address.id}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </section>

                <div className={styles.addressButtonContainer}>
                  <Button
                    type="button"
                    icon="plus"
                    onClick={handleAddressModalOpen}
                  >
                    Add New Address
                  </Button>
                </div>
              </div>
            </form>

            <ManageAddressModal
              isOpen={addressModalVisibility}
              onClose={handleAddressModalClose}
            />
          </div>
        }
        right={
          <div>
            <Controller
              name="images"
              control={control}
              render={({ onChange, value }) => (
                <>
                  <UploadMultipleImages
                    images={value}
                    setImages={(images) => onChange(images)}
                    mainImageIndex={mainImageIndex}
                    setMainImageIndex={setMainImageIndex}
                  />
                </>
              )}
            />

            {(errors?.images as any)?.message && (
              <Text variant="captionRegular" color="error-default">
                {(errors?.images as any)?.message}
              </Text>
            )}
          </div>
        }
      />
    </FullScreenStepperModal>
  );
};

export default MemorabiliaSetup;
