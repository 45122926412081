import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
// Api
import { CHECK_USERNAME_AVAILABILITY } from 'api/auth/queries';
// Types
import {
  CheckUsernameAvailability,
  CheckUsernameAvailabilityVariables,
} from 'api/auth/types/CheckUsernameAvailability';
// Context
import { OnboardingFormData } from 'context/OnboardingContext';
// Hooks
import { useGetCurrUser } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
import Input from 'ui3/Input/Input';
// Components
import UploadProfileImage from 'components/common3/UploadProfileImage/UploadProfileImage';
import UsernameInput from 'ui3/UsernameInput/UsernameInput';
// Helpers
import { setUsernameAvailability } from 'helpers/usernameValidation';
// Styles
import styles from './ProfileStepForm.module.scss';

const ProfileStepForm = () => {
  const {
    control,
    errors,
    setError,
    clearErrors,
    register,
    watch,
  } = useFormContext<OnboardingFormData>();

  const { data: userData } = useGetCurrUser();
  const existingUsername = userData?.me?.slug;
  const username = watch('username');

  const [checkUsernameAvailability, { data }] = useLazyQuery<
    CheckUsernameAvailability,
    CheckUsernameAvailabilityVariables
  >(CHECK_USERNAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (username) {
      clearErrors('username');
    }
  }, [username, clearErrors]);

  useEffect(() => {
    if (!username || username === existingUsername) {
      return;
    }

    // Set up a debounced query call.
    const handler = setTimeout(() => {
      checkUsernameAvailability({
        variables: {
          input: { username },
        },
      });
    }, 300);

    return () => clearTimeout(handler);
  }, [username, existingUsername, checkUsernameAvailability]);

  useEffect(() => {
    if (data?.checkUsernameAvailability) {
      setUsernameAvailability(data.checkUsernameAvailability.isAvailable);

      if (!data.checkUsernameAvailability.isAvailable) {
        setError('username', {
          type: 'manual',
          message: 'Username is already taken',
        });
      } else {
        clearErrors('username');
      }
    }
  }, [data, setError, clearErrors]);

  return (
    <div className={styles.root}>
      <Controller
        name="avatar"
        control={control}
        render={({ onChange, value }) => (
          <div>
            <UploadProfileImage
              onFileChange={onChange}
              avatar={typeof value !== 'string' ? value : null}
              imageUrl={typeof value === 'string' ? value : undefined}
            />
            {errors.avatar && (
              <Text variant="captionRegular" color="error-default">
                {errors.avatar.message}
              </Text>
            )}
          </div>
        )}
      />

      <Input
        name="profileName"
        ref={register}
        label="Profile Name"
        placeholder="Enter profile name"
        error={errors.profileName?.message}
      />

      <UsernameInput
        ref={register}
        name="username"
        label="Choose a unique username"
        placeholder="Username"
        error={errors.username?.message}
        className={styles.usernameInput}
      />
    </div>
  );
};

export default ProfileStepForm;
