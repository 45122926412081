import { ContactType, TargetType } from 'api/graphql-global-types';

export const formattedUnregisteredMention = (mention, type) => ({
  label:
    type === ContactType.ATHLETE
      ? mention?.unregisteredContact?.name || ''
      : mention?.unregisteredContact?.representativeName || '',
  value: mention?.unregisteredContact?.id || '',
  avatarURL: '',
  email: mention?.email || '',
  url: mention?.url || '',
  socialMediaLink: mention?.socialMediaLink || '',
});

export const reformattedUnregisteredMention = (option, type) => ({
  id: option.value,
  store: null,
  mentionType: 'MEDIA_POST',
  targetType: TargetType.UNREGISTERED,
  unregisteredContact: {
    id: option.value,
    representativeName: type === ContactType.ATHLETE ? null : option.label,
    name: type === ContactType.BRAND ? null : option.label,
    type: type,
    email: option.email,
    url: option.url,
    socialMediaLink: option?.socialMediaLink || '',
  },
});

export const formatMentionsInput = (mentions) => {
  return mentions.map((mention) => {
    if (mention.targetType === TargetType.REGISTERED) {
      return {
        storeId: mention?.store?.storeDetails?.storeId,
      };
    } else if (mention.targetType === TargetType.UNREGISTERED) {
      return {
        unregisteredContactId: mention?.unregisteredContact?.id,
      };
    }
  });
};
