import { useMemo } from 'react';
// Types
import { SocialMediaType } from 'api/graphql-global-types';
// Context
import { SocialAccount } from 'context/OnboardingContext';
// Ui
import Select from 'ui3/Select/Select';
import Input from 'ui3/Input/Input';
// Styles
import styles from './SocialsInput.module.scss';

export type PlatformOption = {
  value: SocialMediaType;
  label: string;
  disabled: boolean;
};

type SocialsInputProps = {
  platformOptions: PlatformOption[];
  value?: Partial<SocialAccount>;
  platformError?: string;
  linkError?: string;
  onChange: (value: Partial<SocialAccount>) => void;
};

const SocialsInput = ({
  platformOptions,
  value,
  platformError,
  linkError,
  onChange,
}: SocialsInputProps) => {
  const placeholder = useMemo(() => {
    switch (value?.platform) {
      case SocialMediaType.Instagram:
        return 'https://instagram.com/';
      case SocialMediaType.Twitter:
        return 'https://x.com/';
      case SocialMediaType.Facebook:
        return 'https://facebook.com/';
      case SocialMediaType.Tiktok:
        return 'https://tiktok.com/';
      case SocialMediaType.Youtube:
        return 'https://youtube.com/';
      default:
        return 'https://';
    }
  }, [value?.platform]);

  return (
    <div className={styles.root}>
      <Select
        name="platform"
        label="Social Platform"
        placeholder="Select"
        error={platformError}
        options={platformOptions}
        value={
          value ? platformOptions.find((o) => o.value === value.platform) : null
        }
        onChange={(platform: PlatformOption) =>
          onChange({ platform: platform.value })
        }
      />

      <Input
        name="link"
        type="url"
        label="Link To Profile"
        placeholder={placeholder}
        error={linkError}
        value={value?.link}
        hint="Type or paste the URL of your social account."
        onChange={(e) => onChange({ link: e.target.value })}
      />
    </div>
  );
};

export default SocialsInput;
