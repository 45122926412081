import { gql } from '@apollo/client';

export const AddressFields = gql`
  fragment AddressFields on Address {
    id
    firstName
    lastName
    company
    countryCode
    country
    stateCode
    state
    city
    addressLine1
    addressLine2
    zipCode
    phone
    email
    isDefault
  }
`;
