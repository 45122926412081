import { gql } from '@apollo/client';

export const ACCEPT_INTERVIEW_INVITE = gql`
  mutation AcceptInterviewInvite($input: AcceptInterviewInviteInput!) {
    acceptInterviewInvite(input: $input) {
      interviewLink {
        streamCohostToken
        streamId
      }
    }
  }
`;

export const SCHEDULE_INTERVIEW_ATTEMPT = gql`
  mutation ScheduleInterviewAttempt($input: ScheduleInterviewAttemptInput!) {
    scheduleInterviewAttempt(input: $input)
  }
`;

export const SCHEDULE_USER_INITIATED_INTERVIEW_ATTEMPT = gql`
  mutation CreateUserInitiatedInterview(
    $input: CreateUserInitiatedInterviewInput!
  ) {
    createUserInitiatedInterview(input: $input) {
      id
    }
  }
`;

export const CREATE_USER_TO_USER_INITIATE_INTERVIEW = gql`
  mutation CreateUserToUserInitiatedInterview(
    $input: CreateUserToUserInitiatedInterviewInput!
  ) {
    createUserToUserInitiatedInterview(input: $input) {
      id
    }
  }
`;

export const CANCEL_INTERVIEW = gql`
  mutation CancelInterview($input: CancelInterviewInput!) {
    cancelInterview(input: $input) {
      id
      status
    }
  }
`;
