import React from 'react';
// Ui
import Modal from 'ui3/Modal/Modal';
// Components
import ManageAddressForm from './components/ManageAddressForm/ManageAddressForm';
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './ManageAddressModal.module.scss';

type ManageAddressModalProps = {
  address?: any;
  isOpen: boolean;
  onClose: () => void;
};

const ManageAddressModal: React.FC<ManageAddressModalProps> = ({
  address,
  isOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose();

    showToast({
      type: 'success',
      message: `You have ${address ? 'edited' : 'added a new'} address`,
    });
  };

  return (
    <>
      <Modal
        title={`${address ? 'Edit' : 'Add'} address`}
        open={isOpen}
        onClose={handleClose}
        size="large"
        className={styles.modal}
      >
        <ManageAddressForm address={address} onClose={handleClose} />
      </Modal>
    </>
  );
};

export default ManageAddressModal;
