import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import { ExportInterface } from 'react-images-uploading/dist/typings';
import cn from 'classnames';
// Components
import MultipleImagesList from './MultipleImagesList/MultipleImagesList';
// Styles
import styles from './UploadMultipleImages.module.scss';
import Text from 'ui3/Text/Text';

export type UploadImage = {
  data_url?: string;
  data_key?: string;
  file?: File;
};

type UploadMultipleImagesProps = {
  images: UploadImage[];
  setImages: Dispatch<SetStateAction<UploadImage[]>>;
  setMainImageIndex: Dispatch<SetStateAction<number>>;
  mainImageIndex: number | null;
  maxNumber?: number;
};

const UploadMultipleImages = ({
  images,
  setImages,
  setMainImageIndex,
  mainImageIndex,
  maxNumber = 10,
}: UploadMultipleImagesProps): JSX.Element => {
  const onChange = (imageList: UploadImage[]) => {
    setImages(imageList);
  };

  const createUrl = (image: File | undefined) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    } else return '';
  };

  const initialPreviewImage = mainImageIndex
    ? images[mainImageIndex].data_url ?? images[mainImageIndex].file
      ? createUrl(images[mainImageIndex].file)
      : ''
    : '';

  const [previewImage, setPreviewImage] = useState<string>(initialPreviewImage);

  const hasPreviewImage = previewImage !== '';

  useEffect(() => {
    if (images.length && (mainImageIndex === 0 || mainImageIndex)) {
      if (images[mainImageIndex].data_url) {
        setPreviewImage(images[mainImageIndex].data_url || '');
      } else {
        setPreviewImage(createUrl(images[mainImageIndex].file) || '');
      }
    } else {
      setPreviewImage('');
    }
  }, [images, mainImageIndex]);

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemove }: ExportInterface) => (
          <div className={styles.uploadImageWrapper}>
            <Text variant="h6">Image Upload</Text>
            <Text variant="body1Regular16" color="lights-medium">
              Upload up to 10 images.
            </Text>
            <div className={styles.displayBlock}>
              {hasPreviewImage && (
                <div
                  className={cn(styles.previewImageContainer, {
                    [styles.previewVisible]: hasPreviewImage,
                  })}
                >
                  <img
                    className={styles.previewImage}
                    src={previewImage}
                    alt="mainImage"
                  />
                  <p className={styles.isMainPreviewText}>main image</p>
                </div>
              )}
              <div className={styles.imagesBlock}>
                <MultipleImagesList
                  onImageUpload={onImageUpload}
                  imageList={imageList}
                  onImageRemove={onImageRemove}
                  setMainImageIndex={setMainImageIndex}
                  mainImageIndex={mainImageIndex}
                />
              </div>
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default UploadMultipleImages;
