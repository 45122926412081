import dayjs, { Dayjs } from 'dayjs';
// Types
import { WeekDay } from 'api/graphql-global-types';
import {
  GetSchedule_getSchedule_rules,
  GetSchedule_getSchedule_rules_ScheduleDateRule,
  GetSchedule_getSchedule_rules_ScheduleDateRule_intervals,
  GetSchedule_getSchedule_rules_ScheduleWeekDayRule,
} from 'api/schedule/types/GetSchedule';
import { GetScheduleTimezones } from 'api/schedule/types/GetScheduleTimezones';

export const weekdays: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export type CheckboxState = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type TimeRanges = {
  monday: TimeRange[];
  tuesday: TimeRange[];
  wednesday: TimeRange[];
  thursday: TimeRange[];
  friday: TimeRange[];
  saturday: TimeRange[];
  sunday: TimeRange[];
};

export type TimeRange = {
  from: Dayjs | null;
  to: Dayjs | null;
};

export type DateSpecificHours = {
  date: Dayjs | null;
  timeRanges: TimeRange[];
};

export const disabledDate = (current: Dayjs) => {
  // Cannot select days before today and today
  return current && current.isBefore(dayjs().startOf('day'));
};

export const getTimezoneOptions = (
  timezones: GetScheduleTimezones | undefined
): { tzCode: string; tzName: string; label: string }[] => {
  if (!timezones?.getScheduleTimezones) return [];

  return timezones.getScheduleTimezones.map((item) => ({
    tzCode: item.alias,
    tzName: item.iana,
    label: item.iana,
  }));
};

export function isScheduleDateRule(
  rule: GetSchedule_getSchedule_rules
): rule is GetSchedule_getSchedule_rules_ScheduleDateRule {
  return (
    (rule as GetSchedule_getSchedule_rules_ScheduleDateRule).date !== undefined
  );
}

export function isScheduleWeekDayRule(
  rule: GetSchedule_getSchedule_rules
): rule is GetSchedule_getSchedule_rules_ScheduleWeekDayRule {
  return (
    (rule as GetSchedule_getSchedule_rules_ScheduleWeekDayRule).weekday !==
    undefined
  );
}

export function getRuleForDay(
  day: string,
  rules: GetSchedule_getSchedule_rules[]
): GetSchedule_getSchedule_rules_ScheduleWeekDayRule | null {
  const foundRule = rules.find(
    (rule) => isScheduleWeekDayRule(rule) && rule.weekday === day
  );
  return (
    (foundRule as GetSchedule_getSchedule_rules_ScheduleWeekDayRule) || null
  );
}

export const getWeekDayRule = (
  day: string,
  rules: GetSchedule_getSchedule_rules_ScheduleWeekDayRule[]
): GetSchedule_getSchedule_rules_ScheduleWeekDayRule | null => {
  return rules?.find((rule) => rule.weekday === day) || null;
};

export const convertTimeToTodayDate = (time: string): dayjs.Dayjs => {
  const today = new Date();
  const [hours, minutes] = time.split(':').map(Number);

  today.setHours(hours, minutes, 0, 0);

  return dayjs(today);
};

const getRangeIntervals = (
  intervals: GetSchedule_getSchedule_rules_ScheduleDateRule_intervals[]
): TimeRange[] => {
  const result: TimeRange[] = [];
  for (const { from, to } of intervals) {
    result.push({
      from: convertTimeToTodayDate(from),
      to: convertTimeToTodayDate(to),
    });
  }

  return result;
};

export const getInitialDateRanges = (
  rules: GetSchedule_getSchedule_rules_ScheduleDateRule[]
): DateSpecificHours[] => {
  const result: DateSpecificHours[] = [];
  for (const { date, intervals } of rules || []) {
    result.push({
      date: dayjs(date),
      timeRanges: getRangeIntervals(intervals),
    });
  }

  return result;
};

export const getInitialWeekdayRanges = (
  rules: GetSchedule_getSchedule_rules_ScheduleWeekDayRule[]
): TimeRanges => {
  const result: TimeRanges = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  for (const rule of rules || []) {
    for (const { from, to } of rule.intervals || []) {
      result[rule.weekday].push({
        from: convertTimeToTodayDate(from),
        to: convertTimeToTodayDate(to),
      });
    }
  }

  if (Object.values(result).some((day) => !day.length)) {
    for (const day in result) {
      if (!result[day].length) {
        result[day].push({ from: null, to: null });
      }
    }
  }

  return result;
};

type WeekDayAvailabilityType = {
  weekday: WeekDay;
  intervals: {
    from: string;
    to: string;
  }[];
};

export const getWeekDayAvailability = (
  timeRanges: TimeRanges,
  checkboxState: CheckboxState
): WeekDayAvailabilityType[] => {
  return (Object.keys(timeRanges) as Array<keyof TimeRanges>)
    .filter((weekday) => checkboxState[weekday as keyof CheckboxState])
    .map((weekday) => ({
      weekday: weekday as WeekDay,
      intervals: timeRanges[weekday].map((interval) => ({
        from: interval.from ? dayjs(interval.from).format('HH:mm') : '',
        to: interval.to ? dayjs(interval.to).format('HH:mm') : '',
      })),
    }))
    .filter((day) =>
      day.intervals.some(
        (interval) => interval.from !== '' || interval.to !== ''
      )
    );
};

type DatesAvailability = {
  date: string;
  intervals: {
    from: string;
    to: string;
  }[];
};

export const getDatesAvailability = (
  specificHoursList: DateSpecificHours[]
): DatesAvailability[] => {
  return specificHoursList.map((item) => ({
    date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : '',
    intervals: item.timeRanges.map((interval) => ({
      from: interval.from ? dayjs(interval.from).format('HH:mm') : '',
      to: interval.to ? dayjs(interval.to).format('HH:mm') : '',
    })),
  }));
};

export const createDateTimeObject = (input: any) => {
  if (typeof input === 'string') {
    // Handle string format: "hh:mm:ss"
    const [hours, minutes, seconds] = input.split(':').map(Number);
    return new Date(0, 0, 0, hours, minutes, seconds);
  } else if (input instanceof Date) {
    // Handle Date object
    return new Date(input.getTime());
  } else if (dayjs.isDayjs(input)) {
    return input.toDate();
  } else if (input === null) {
    return;
  } else {
    // Handle invalid input
    console.error('Invalid input format');
  }
};

export type Weekdays =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
