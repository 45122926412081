import { useEffect, useState } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { isEqual } from 'lodash';
// Constants
import { SOCIALS_OPTIONS } from 'constants/socials';
// Context
import { SocialAccount } from 'context/OnboardingContext';
// Ui
import Button from 'ui3/Button/Button';
import SocialsInput from 'ui3/SocialsInput/SocialsInput';
import SocialsRow from 'ui3/SocialsRow/SocialsRow';
// Styles
import styles from './SocialMediaFields.module.scss';

type SocialMediaFieldsProps = {
  socialAccounts?: SocialAccount[];
  errors: DeepMap<SocialAccount[] | undefined, FieldError> | FieldError;
  onChange: (socialAccounts: SocialAccount[]) => void;
};

const SocialMediaFields = ({
  socialAccounts,
  errors,
  onChange,
}: SocialMediaFieldsProps) => {
  const [localSocialAccounts, setLocalSocialAccounts] = useState<
    Partial<SocialAccount>[]
  >(
    socialAccounts || [
      {
        platform: undefined,
        link: '',
        isAdded: false,
      },
    ]
  );

  useEffect(() => {
    if (!isEqual(localSocialAccounts, socialAccounts)) {
      onChange(localSocialAccounts as SocialAccount[]);
    }
  }, [localSocialAccounts, socialAccounts, onChange]);

  const handleAddSocialAccount = () => {
    const lastAddedSocialAccount =
      localSocialAccounts[localSocialAccounts.length - 1];

    if (
      lastAddedSocialAccount.link &&
      lastAddedSocialAccount.platform &&
      !lastAddedSocialAccount.isAdded
    ) {
      setLocalSocialAccounts((prevState) =>
        prevState.map((account) => ({
          ...account,
          isAdded: true,
        }))
      );
    } else if (lastAddedSocialAccount.link && lastAddedSocialAccount.platform) {
      setLocalSocialAccounts((prevState) => [
        ...prevState,
        {
          platform: undefined,
          link: '',
          isAdded: false,
        },
      ]);
    }
  };

  const handleRemoveSocialAccount = (socialAccount: SocialAccount) => {
    if (localSocialAccounts.length === 1) {
      setLocalSocialAccounts([
        {
          platform: undefined,
          link: '',
          isAdded: false,
        },
      ]);
    } else {
      setLocalSocialAccounts((prevState) =>
        prevState.filter(
          (account) => account.platform !== socialAccount.platform
        )
      );
    }
  };

  const reversedIndex = localSocialAccounts
    .slice()
    .reverse()
    .findIndex((account) => account.isAdded);
  const lastAddedRowIndex =
    reversedIndex === -1 ? -1 : localSocialAccounts.length - 1 - reversedIndex;

  return (
    <div className={styles.root}>
      {localSocialAccounts?.map((socialAccount, index) =>
        socialAccount.isAdded ? (
          <SocialsRow
            key={socialAccount.platform}
            socialAccount={socialAccount as SocialAccount}
            firstRow={index === 0}
            lastRow={index === lastAddedRowIndex}
            onRemove={() =>
              handleRemoveSocialAccount(socialAccount as SocialAccount)
            }
          />
        ) : (
          <SocialsInput
            key={socialAccount.platform}
            platformOptions={SOCIALS_OPTIONS.map((option) => {
              return {
                value: option.value,
                label: option.label,
                disabled: localSocialAccounts.some(
                  (account) => account.platform === option.value
                ),
              };
            })}
            platformError={errors?.[index]?.platform?.message}
            linkError={errors?.[index]?.link?.message}
            value={socialAccount}
            onChange={(newValue) => {
              setLocalSocialAccounts((prevLocalSocialAccounts) =>
                prevLocalSocialAccounts.map((account, i) => {
                  if (i === index) {
                    return {
                      ...account,
                      ...newValue,
                    };
                  }
                  return account;
                })
              );
            }}
          />
        )
      )}

      {localSocialAccounts.length < SOCIALS_OPTIONS.length && (
        <Button
          variant="primary"
          subVariant="text"
          icon="plus"
          onClick={handleAddSocialAccount}
        >
          Add Social Account
        </Button>
      )}
    </div>
  );
};

export default SocialMediaFields;
