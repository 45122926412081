import type React from 'react';
// Ui3
import Text from 'ui3/Text/Text';
import Input from 'ui3/Input/Input';
import Switch from 'ui3/Switch/Switch';
// Styles
import styles from '../BrandDealSteps.module.scss';

type MinimumPriceStepProps = {
  register: any;
  setValue: any;
  watch: any;
  errors: any;
};

const MinimumPriceStep = ({
  register,
  setValue,
  watch,
  errors,
}: MinimumPriceStepProps): JSX.Element => {
  const minimumPrice = watch('minimumPrice', null);
  const ugcPrice = watch('ugcPrice', null);
  const streamPrice = watch('streamPrice', null);
  const appearancePrice = watch('appearancePrice', null);
  const viewAllDeals = watch('viewAllDeals', false);

  const handleBooleanToggle = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const newValue = e.target.checked;

    setValue(fieldName, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericPrice = Number.parseFloat(value);

    if (!isNaN(numericPrice)) {
      const formattedPrice = Number.parseFloat(numericPrice.toFixed(2));
      setValue('minimumPrice', formattedPrice);
    } else {
      setValue('minimumPrice', 0);
    }
  };

  const handleCategoryPriceChange = (field: string, value: string) => {
    if (field === 'ugcPrice') {
      setValue('ugcPrice', value);
    } else if (field === 'streamPrice') {
      setValue('streamPrice', value);
    } else if (field === 'appearancePrice') {
      setValue('appearancePrice', value);
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.toggleContainer}>
        <Text variant="body1Regular16">View Deals of All Sizes</Text>
        <Switch
          name="viewAllDeals"
          checked={viewAllDeals}
          onChange={(e) => handleBooleanToggle(e, 'viewAllDeals')}
          ref={register}
          wrapperClassName="switchClass"
        />
      </div>
      {viewAllDeals ? (
        <div className={styles.basicSlider}>
          <Text
            color="lights-low"
            variant="body2Regular"
            className={styles.helperText}
          >
            Set a minimum price for brand deals to view only the offers you
            want.
          </Text>
        </div>
      ) : (
        <div className={styles.detailedForm}>
          <Input
            name="minimumPrice"
            type="number"
            fieldSize="large"
            icon="dollar"
            placeholder="USD"
            value={minimumPrice}
            error={errors?.minimumPrice?.message}
            onChange={(e) => handlePriceChange(e)}
            className={styles.priceInput}
            ref={register}
          />
          <Text
            color="lights-low"
            variant="body2Regular"
            className={styles.helperText}
          >
            Set a minimum price for brand deals to view only the offers you
            want.
          </Text>

          <div className={styles.categorySection}>
            <Text variant="body1Regular16">UGC & Social Campaigns</Text>
            <Text color="lights-low" variant="body2Regular">
              Get paid to make content and promote brands through posts on your
              social media channels (Instagram, Facebook, etc.).
            </Text>
            <div className={styles.currencyInput}>
              <Input
                name="ugcPrice"
                type="number"
                icon="dollar"
                placeholder="USD"
                error={errors?.ugcPrice?.message}
                value={ugcPrice}
                onChange={(e) =>
                  handleCategoryPriceChange('ugcPrice', e.target.value)
                }
                ref={register}
              />
            </div>
          </div>

          <div className={styles.categorySection}>
            <Text variant="body1Regular16">Sponsored Stream or Interview</Text>
            <Text color="lights-low" variant="body2Regular">
              Earn by featuring brands during your live streams, podcasts, or
              interviews.
            </Text>
            <div className={styles.currencyInput}>
              <Input
                name="streamPrice"
                type="number"
                icon="dollar"
                placeholder="USD"
                value={streamPrice}
                error={errors?.streamPrice?.message}
                onChange={(e) =>
                  handleCategoryPriceChange('streamPrice', e.target.value)
                }
                ref={register}
              />
            </div>
          </div>

          <div className={styles.categorySection}>
            <Text variant="body1Regular16">Appearance</Text>
            <Text color="lights-low" variant="body2Regular">
              Get booked for virtual or in person events, appearances, signings,
              or meet-and-greets to represent a brand.
            </Text>
            <div className={styles.currencyInput}>
              <Input
                name="appearancePrice"
                type="number"
                icon="dollar"
                placeholder="USD"
                value={appearancePrice}
                error={errors?.appearancePrice?.message}
                onChange={(e) =>
                  handleCategoryPriceChange('appearancePrice', e.target.value)
                }
                ref={register}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MinimumPriceStep;
