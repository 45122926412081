import { gql } from '@apollo/client';

export const GET_AVAILABLE_INTERVIEWER = gql`
  query GetAvailableInterviewer($input: GetAvailableInterviewerInput!) {
    getAvailableInterviewer(input: $input) {
      id
      firstName
      lastName
      storeDetails {
        avatarURL
      }
    }
  }
`;

export const GET_AVAILABLE_INTERVIEWERS = gql`
  query getAvailableInterviewers($input: GetAvailableInterviewersInput!) {
    getAvailableInterviewers(input: $input) {
      id
      firstName
      lastName
      storeDetails {
        avatarURL
      }
    }
  }
`;

export const GET_AVAILABLE_SCHEDULE_SLOTS = gql`
  query GetAvailableScheduleSlots($input: GetAvailableScheduleSlotsInput!) {
    getAvailableScheduleSlots(input: $input) {
      slots {
        startTs
        endTs
      }
      schedule {
        slotStepMinutes
        store {
          storeDetails {
            avatarURL
          }
        }
      }
    }
  }
`;

export const GET_ALL_AVAILABLE_SCHEDULE_SLOTS = gql`
  query GetAllAvailableScheduleSlots(
    $input: GetAllAvailableSchedulesSlotsInput!
  ) {
    getAllAvailableScheduleSlots(input: $input) {
      slots {
        startTs
        endTs
        userId
      }
      schedules {
        slotStepMinutes
        store {
          storeDetails {
            avatarURL
          }
        }
      }
    }
  }
`;

export const GET_STORES_BY_HOST_ID = gql`
  query GetStoresByHostId($input: StoresFilterInput) {
    stores(input: $input, storeRoles: [Athlete, Organization, ContentCreator]) {
      entities {
        id
        lastName
        firstName
        storeDetails {
          avatarURL
        }
      }
      total
      offset
      limit
    }
  }
`;
