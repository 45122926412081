export const BLOG = 'https://blog.millions.co/';
export const CAREERS = 'https://careers.millions.co/';
export const ABOUT_US = 'https://follow.millions.co/about-us';
export const ADVERTISE = 'https://advertising.millions.co/';
export const HOST_A_PPV = 'https://follow.millions.co/ppv-services';
export const CREATOR = 'https://info.millions.co/creators/';
export const FANS = 'https://follow.millions.co/what-does-millions-do';
export const INFO = 'https://info.millions.co/';
export const EVENTS = 'https://info.millions.co/events/';
export const CREATORS = 'https://info.millions.co/creators/';
export const CONTACT = 'https://info.millions.co/contact/';
export const ABOUT = 'https://info.millions.co/about-us/';
