import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// Api
import { TWITTER_AUTH } from 'api/auth/mutations';
// Types
import {
  TwitterAuth,
  TwitterAuth_twitterAuth_AuthV2SignInResponse,
  TwitterAuth_twitterAuth_SocialSignUpResponse,
  TwitterAuthVariables,
} from 'api/auth/types/TwitterAuth';
// Helpers
import { getProperErrorMessage } from 'helpers/errors';
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
} from 'helpers/storage';
import { handleFirstLogin } from 'helpers/user';
// Hooks
import { useAppContext } from 'hooks';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';

const TwitterSdkWatcher = (): null | JSX.Element => {
  const { setToken, setIsSocialSignUp, setSocialSignUpData } = useAppContext();
  const { push, query } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  const [twitterAuth] = useMutation<TwitterAuth, TwitterAuthVariables>(
    TWITTER_AUTH
  );
  const oauthToken = query.oauth_token as string;
  const oauthVerifier = query.oauth_verifier as string;

  useEffect(() => {
    if (oauthToken && oauthVerifier) {
      setLoading(true);

      const login = async (): Promise<void> => {
        try {
          const { data } = await twitterAuth({
            variables: {
              input: {
                oauth_token: oauthToken,
                oauth_verifier: oauthVerifier,
              },
            },
          });

          if (data?.twitterAuth) {
            const isSignup =
              data.twitterAuth.__typename === 'SocialSignUpResponse';
            const redirectPath = getRedirectFromLocalStorage();

            if (isSignup) {
              setIsSocialSignUp(true);
              setSocialSignUpData(
                data.twitterAuth as TwitterAuth_twitterAuth_SocialSignUpResponse
              );
              setLoading(false);
            } else {
              const response = data.twitterAuth as TwitterAuth_twitterAuth_AuthV2SignInResponse;

              setToken({
                id: response.userId,
                accessToken: response.tokens.accessToken,
                refreshToken: response.tokens.refreshToken,
              });

              handleFirstLogin(response.user.role);
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
              });
            } else {
              push({
                query: '',
              });
            }
          }

          setLoading(false);
        } catch (error) {
          push({
            query: '',
          });

          showToast({
            message: getProperErrorMessage(
              error,
              'Looks like something went wrong. Please try again later.'
            ),
            type: 'error',
          });
          console.log('error', error);
          Sentry.captureMessage(`Twitter sign up: ${JSON.stringify(error)}`, {
            tags: {
              section: 'auth',
            },
          });
        }

        setLoading(false);
      };

      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauthToken, oauthVerifier]);

  if (loading) {
    return <FourDotsLoader />;
  }

  return null;
};

export default TwitterSdkWatcher;
