import cn from 'classnames';
// Hooks
import { useOnboardingContext } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
// Styles
import styles from './EcommerceInitialStep.module.scss';
import { MemorabiliaProductType } from 'api/graphql-global-types';

const EcommerceInitialStep = () => {
  const {
    requestMerchDone,
    createProductDone,
    createMemorabiliaDone,
    createExperienceDone,
    setShowRequestMerchModal,
    setShowCreateProductMemorabiliaModal,
    setShowCreateExperienceModal,
  } = useOnboardingContext();

  return (
    <div className={styles.root}>
      <div className={styles.textContainer}>
        <Text textAlign="center" className={styles.title}>
          Create on MILLIONS
        </Text>

        <Text
          className={styles.subtitle}
          color="lights-medium"
          textAlign="center"
        >
          Start your e-commerce by creating your first product.
        </Text>
      </div>

      <div className={styles.buttonsContainer}>
        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: requestMerchDone,
          })}
          disabled={requestMerchDone}
          onClick={() => setShowRequestMerchModal(true)}
        >
          <img src={'/images/merch.png'} alt="Merch" />
          <Text variant="h6" uppercase>
            Merch
          </Text>
        </button>

        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: createMemorabiliaDone,
          })}
          disabled={createMemorabiliaDone}
          onClick={() =>
            setShowCreateProductMemorabiliaModal(
              true,
              MemorabiliaProductType.Memorabilia
            )
          }
        >
          <img src={'/images/memorabilia.png'} alt="Memorabilia" />
          <Text variant="h6" uppercase>
            Memorabilia
          </Text>
        </button>

        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: createProductDone,
          })}
          disabled={createProductDone}
          onClick={() =>
            setShowCreateProductMemorabiliaModal(
              true,
              MemorabiliaProductType.Product
            )
          }
        >
          <img src={'/images/product.png'} alt="Products" />
          <Text variant="h6" uppercase>
            Products
          </Text>
        </button>

        <button
          type="button"
          className={cn(styles.button, {
            [styles.finished]: createExperienceDone,
          })}
          disabled={createExperienceDone}
          onClick={() => setShowCreateExperienceModal(true)}
        >
          <img src={'/images/experience.png'} alt="Experiences" />
          <Text variant="h6" uppercase>
            Experiences
          </Text>
        </button>
      </div>
    </div>
  );
};

export default EcommerceInitialStep;
