import { gql } from '@apollo/client';
// Fragments
import { AddressFields } from './fragments';

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      ...AddressFields
    }
  }
  ${AddressFields}
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      ...AddressFields
    }
  }
  ${AddressFields}
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`;
