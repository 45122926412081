// Icons

import Text from 'ui3/Text/Text';
// Styles
import styles from './DLMerchRequestSuccess.module.scss';

const DLMerchRequestSuccess = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <img
        className={styles.image}
        src="/images/gears.png"
        alt="Merch request success"
      />

      <Text variant="h2" uppercase textAlign="center">
        We are on it!
      </Text>

      <Text variant="body1Regular16" color="lights-medium" textAlign="center">
        Our team will get to work on your new design. We’`ll be in touch as soon
        as we have some things to show you for feedback! Expect to hear back
        within the next 5 business days with your first design concept.
      </Text>

      <Text variant="body1Regular16" color="lights-medium" textAlign="center">
        If you want to create your own merch with an existing logo, please visit
        MILLIONS.co, please visit{' '}
        <a className={styles.link} href="https://millions.co">
          MILLIONS.co
        </a>
        .
      </Text>
    </div>
  );
};

export default DLMerchRequestSuccess;
