import cn from 'classnames';
// Types
import { AddressFields } from 'api/address/types/AddressFields';
// Helpers
import { joinStrings } from 'helpers/address';
// UI
import Text from 'ui3/Text/Text';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './AddressCard.module.scss';

type CardProps = {
  address: AddressFields;
  onSelect: (address: AddressFields) => void;
  isSelected?: boolean;
  showPhoneAndEmail?: boolean;
};

const AddressCard = ({
  address,
  isSelected = false,
  showPhoneAndEmail = false,
  onSelect,
}: CardProps) => {
  const {
    firstName,
    lastName,
    country,
    state,
    city,
    addressLine1,
    addressLine2,
    zipCode,
    phone,
    email,
  } = address;
  const handleAddressSelect = () => {
    onSelect(address);
    showToast({
      type: 'success',
      message: 'Shipping address saved.',
    });
  };

  const fullName = `${firstName} ${lastName}`;

  const cityStateCode = joinStrings([city, state, zipCode]);
  const addressLine = joinStrings([addressLine1, addressLine2]);

  const addressListing = [addressLine, cityStateCode, country];

  return (
    <button
      type="button"
      onClick={handleAddressSelect}
      className={cn(styles.root, {
        [styles.isSelected]: isSelected,
      })}
    >
      <Text variant="body2Regular">{fullName}</Text>

      <Text variant="body2Regular" color="lights-low">
        {addressListing.join(', ')}
      </Text>

      {showPhoneAndEmail && (
        <>
          <Text variant="body2Regular" color="lights-low">
            {email}
          </Text>

          <Text variant="body2Regular" color="lights-low">
            {phone}
          </Text>
        </>
      )}
    </button>
  );
};

export default AddressCard;
