import { useEffect, useRef } from 'react';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
import cn from 'classnames';
// UI
import Text from 'ui3/Text/Text';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Button, { ButtonProps } from 'ui3/Button/Button';
// Styles
import 'react-responsive-modal/styles.css';
import styles from './FullScreenStepperModal.module.scss';

export type FullScreenStepperModalFooter = {
  stepNumber?: number;
  totalStepsNumber?: number;
  leftSection?: JSX.Element;
  showPrevButton?: boolean;
  prevButtonText?: string;
  prevButtonProps?: ButtonProps;
  secondaryButtonText?: string;
  secondaryButtonProps?: ButtonProps;
  submitButtonText?: string;
  submitButtonProps?: ButtonProps;
};

type FullScreenStepperModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  footer?: FullScreenStepperModalFooter;
  children?: JSX.Element;
  closable?: boolean;
};

const FullScreenStepperModal = ({
  open,
  title,
  onClose,
  footer,
  children,
  closable = true,
}: FullScreenStepperModalProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    leftSection,
    showPrevButton,
    prevButtonText,
    prevButtonProps,
    submitButtonText,
    submitButtonProps,
  } = footer || {};

  /**
   * Scroll to the top of a modal on title change
   */
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [title]);

  return (
    <ResponsiveModal
      center
      open={open}
      classNames={{
        root: styles.root,
        overlay: styles.overlay,
        modal: styles.modal,
        modalContainer: styles.modalContainer,
      }}
      onClose={onClose}
      showCloseIcon={false}
      closeOnEsc={closable}
      closeOnOverlayClick={closable}
    >
      <div className={styles.header}>
        <div className={styles.headerBody}>
          <Text variant="h5" uppercase>
            {title}
          </Text>
          {closable && (
            <button
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
            >
              <MillionsIcon name="close" className={styles.closeIcon} />
            </button>
          )}
        </div>
      </div>
      {/* Used to scroll to the very top of the modal */}
      <div
        className={styles.scrollRef}
        data-testid="scrollRef"
        ref={scrollRef}
      ></div>

      <div
        className={cn(styles.body, {
          [styles.withFooter]: Boolean(footer),
        })}
      >
        {children}
      </div>

      {footer && (
        <div className={styles.footer}>
          {showPrevButton ? (
            <Button
              color="transparent"
              className={styles.prevButton}
              {...prevButtonProps}
            >
              {prevButtonText || 'Back'}
            </Button>
          ) : leftSection ? (
            leftSection
          ) : (
            <div className={styles.prevButtonPlaceholder} />
          )}

          {footer.totalStepsNumber && (
            <div className={styles.stepContainer}>
              <Text variant="body1Regular16" color="lights-medium">
                {footer.stepNumber} of {footer.totalStepsNumber} steps
              </Text>
            </div>
          )}

          <div className={styles.buttonsContainer}>
            {footer.secondaryButtonText && (
              <Button
                color="black"
                className={styles.secondaryButton}
                {...footer.secondaryButtonProps}
              >
                {footer.secondaryButtonText}
              </Button>
            )}
            <Button
              color="black"
              className={styles.submitButton}
              {...submitButtonProps}
            >
              {submitButtonText || 'Next'}
            </Button>
          </div>
        </div>
      )}
    </ResponsiveModal>
  );
};

export default FullScreenStepperModal;
