import { useEffect, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
//Api
import { GET_MERCH_CART_ITEM_LIST } from 'api/merch/queries';
// Types
import { GetMerchCartItemsList } from 'api/merch/types/GetMerchCartItemsList';
// Constants
import { HOME } from 'constants/routes';
// Context
import { SearchBarContextProvider } from 'components/HomePage/components/V3/SearchBar/hook/useSearchBarContext';
// Helper
import { withCDN } from 'helpers/cdn';
import { getCorrectCartCount } from 'helpers/cart';
import {
  isActiveStore,
  isUserOrganization,
  isUserContentCreator,
  isUserAthlete,
  isUserCustomer,
} from 'helpers/user';
import {
  getMerchShoppingCartFromLocalStorage,
  setFullOnboardingLocalStorageFlag,
} from 'helpers/storage';
// Hooks
import { useAppContext, useGetCurrUser, useOnboardingContext } from 'hooks';
// Ui
import Image from 'ui/Image/Image';
import Dropdown from 'ui3/Dropdown/Dropdown';
import LinkButton from 'ui3/LinkButton/LinkButton';
import Button from 'ui3/Button/Button';
// Components
import HeaderSearchBar from './HeaderSearchBar/HeaderSearchBar';
import GoLiveButton from './GoLiveButton/GoLiveButton';
import ShoppingCartButton from '../ShoppingCartButton/ShoppingCartButton';
import UserDropdown from '../UserDropdown/UserDropdown';
// Styles
import styles from './DesktopHeader.module.scss';

type DesktopHeaderProps = {
  fixed?: boolean;
};

export const DesktopHeader = ({ fixed }: DesktopHeaderProps) => {
  const { data: currentUser } = useGetCurrUser();
  const isLoggedIn = Boolean(currentUser?.me?.id);
  const { guestToken } = useAppContext();
  const { setShowOnboardingModal, setFullOnboarding } = useOnboardingContext();

  const [isShortWidth, setIsShortWidth] = useState(false);

  useEffect(() => {
    const calculateLayout = () => {
      const viewportWidth = window.innerWidth;

      const shortWidth = viewportWidth >= 1024 && viewportWidth <= 1114;

      if (shortWidth) {
        setIsShortWidth(true);
      } else {
        setIsShortWidth(false);
      }
    };

    calculateLayout();
    window.addEventListener('resize', calculateLayout);

    return () => window.removeEventListener('resize', calculateLayout);
  }, []);

  const {
    setLoginModalVisibility,
    setSignUpModalVisibility,
    setSignUpStep,
    setForgotPasswordModalVisibility,
  } = useAppContext();

  // used to get shopping cart size for unlogged user
  const merchShoppingCart = getMerchShoppingCartFromLocalStorage();
  // used to get shopping cart size for guest user
  const { data: merchShoppingCartData } = useQuery<GetMerchCartItemsList>(
    GET_MERCH_CART_ITEM_LIST,
    {
      skip: !guestToken,
    }
  );

  const handleLoginClick = () => {
    setLoginModalVisibility(true);
    setSignUpModalVisibility(false);
    setForgotPasswordModalVisibility(false);
  };

  const onConfirmClick = () => {
    if (isLoggedIn) {
      setShowOnboardingModal(true);
    } else {
      setSignUpStep(0);
      setSignUpModalVisibility(true);
      setFullOnboarding(true);
      // For Social Sign up
      setFullOnboardingLocalStorageFlag('true');
    }
  };

  const isOrganization = isUserOrganization(currentUser);
  const isContentCreator = isUserContentCreator(currentUser);
  const isAthlete = isUserAthlete(currentUser);
  const isActive = isActiveStore(currentUser);
  const isCustomer = isUserCustomer(currentUser);
  const canGoLive =
    isActive && (isAthlete || isOrganization || isContentCreator);

  const anonymousCartCount = getCorrectCartCount(merchShoppingCart);
  const guestCartCount = getCorrectCartCount(
    merchShoppingCartData?.getMerchCartItemsList
  );

  const shoppingCartCounter = isLoggedIn
    ? currentUser?.me?.cartSize
    : guestToken
    ? guestCartCount
    : anonymousCartCount;

  return (
    <div
      className={cn(styles.root, {
        [styles.fixed]: fixed,
      })}
    >
      <div
        className={cn(styles.leftPart, {
          [styles.shortLeftPart]: isShortWidth,
        })}
      >
        <div className={styles.logoWrapper}>
          <Link href={HOME} passHref prefetch={false}>
            <a>
              <Image
                className={styles.logo}
                src={withCDN('/images/logos/millions-logo.svg')}
                alt="MILLIONS logo"
                priority
                unoptimized
              />
            </a>
          </Link>
        </div>

        <div className={styles.linksWrapper}>
          <Link href="/athletes" passHref>
            <a className={styles.link}>Athletes</a>
          </Link>
          <Link href="/organizations" passHref>
            <a className={styles.link}>Creators</a>
          </Link>

          <Dropdown
            label="Shop"
            options={[
              { label: 'Merch', url: '/merch', internalLink: true },
              {
                label: 'Personal Videos',
                url: '/personal-videos',
                internalLink: true,
              },
              {
                label: 'Products',
                url: '/products?productType=Product',
                internalLink: true,
              },
              {
                label: 'Memorabilia',
                url: '/products?productType=Memorabilia',
                internalLink: true,
              },
              { label: 'Experiences', url: '/experiences', internalLink: true },
            ]}
          />

          <LinkButton
            type="button"
            variant="primary"
            size="medium"
            link="/streams"
          >
            Watch
          </LinkButton>

          <div className={styles.searchWrapper}>
            <SearchBarContextProvider>
              <HeaderSearchBar />
            </SearchBarContextProvider>
          </div>
        </div>
      </div>

      <div className={styles.rightPart}>
        {isLoggedIn ? (
          <>
            {canGoLive ? (
              <GoLiveButton />
            ) : (
              isCustomer && (
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={onConfirmClick}
                >
                  Go Pro
                </Button>
              )
            )}

            <ShoppingCartButton merchCounter={shoppingCartCounter} />

            <UserDropdown />
          </>
        ) : (
          <>
            <ShoppingCartButton merchCounter={shoppingCartCounter} />
            <Button variant="secondary" size="medium" onClick={onConfirmClick}>
              Go Pro
            </Button>
            <Button variant="primary" size="medium" onClick={handleLoginClick}>
              Login
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default DesktopHeader;
