// Hooks
import { useOnboardingContext } from 'hooks';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
import BasicInfoForm from './BasicInfoForm/BasicInfoForm';
// Styles
import styles from './BasicInfoStep.module.scss';

const BasicInfoStep = () => {
  const { onboardingStep } = useOnboardingContext();

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          <StepChip step={onboardingStep} />

          <Text variant="h2">Tell Us More About Yourself</Text>

          <Text variant="body1Regular16" color="lights-medium">
            Help fans and brands find you, and enable us to connect you with the
            right audience.
          </Text>
        </div>
      }
      right={<BasicInfoForm />}
    />
  );
};

export default BasicInfoStep;
