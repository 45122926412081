import { useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import router from 'next/router';
// Types
import {
  EarthCoordinateInput,
  StoreAthleticLevel,
  StoreGender,
} from 'api/graphql-global-types';
import { EditStore, EditStoreVariables } from 'api/store/types/EditStore';
import {
  SetBrandDealSettings,
  SetBrandDealSettingsVariables,
} from 'api/brandDeals/types/SetBrandDealSettings';
// Hooks
import { useOnboardingContext } from 'hooks';
import { useGetCurrUser } from 'hooks';
// Constants
import { DASHBOARD } from 'constants/routes';
// Components
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import BrandDealBudgetStep from './BrandDealsSteps/BrandDealBudgetStep';
import { showToast } from 'components/common/Toast/Toast';
// Mutations
import { EDIT_STORE } from 'api/store/mutations';
import { SET_BRAND_DEAL_SETTINGS } from 'api/brandDeals/mutations';
// Styles
import styles from './BrandDealsSetup.module.scss';

interface RestrictedCategories {
  tobacco: boolean;
  alcohol: boolean;
  crypto: boolean;
  gambling: boolean;
  'CBD/Cannabis': boolean;
  [key: string]: boolean;
}

interface FormData {
  viewAllDeals: boolean;
  minimumPrice: number | null;
  ugcPrice: number | null;
  streamPrice: number | null;
  appearancePrice: number | null;
  sponsoredArticles: boolean;
  acceptFreeProducts: boolean;
  restrictedCategories: RestrictedCategories;
  gender: string;
  athleticLevel?: { value: StoreAthleticLevel | string; label: string } | null;
  birthday: string | null;
  hometownSearch: string;
  location?: {
    city: string | null;
    state?: string | null;
    stateCode?: string | null;
    country: string | null;
    countryCode: string | null;
    coordinates: EarthCoordinateInput | null;
  };
  hometown?: {
    city: string | null;
    state?: string | null;
    stateCode?: string | null;
    country: string | null;
    countryCode: string | null;
    coordinates: EarthCoordinateInput | null;
  };
  league?: { id: string; name: string } | null;
  [key: string]: any;
}

const step1Schema = yup.object().shape({
  viewAllDeals: yup.boolean().required('Please select an option'),
  minimumPrice: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .min(0, 'Price cannot be negative'),
  streamPrice: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .min(0, 'Price cannot be negative'),
  ugcPrice: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .min(0, 'Price cannot be negative'),
  appearancePrice: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .min(0, 'Price cannot be negative'),
});

const step2Schema = yup.object().shape({
  sponsoredArticles: yup.boolean(),
  acceptFreeProducts: yup.boolean(),
  restrictedCategories: yup.object({
    tobacco: yup.boolean(),
    alcohol: yup.boolean(),
    crypto: yup.boolean(),
    gambling: yup.boolean(),
    'CBD/Cannabis': yup.boolean(),
  }),
});

const step3Schema = yup.object().shape({
  gender: yup.string().required('Please select a gender'),
  athleticLevel: yup.string().required('Please select your athletic level'),
  birthday: yup
    .date()
    .required('Please select a date')
    .typeError('Please enter a valid date'),
  location: yup.string().required('Please enter your current city'),
  hometown: yup.string().required('Please enter your hometown'),
  leagues: yup.string().nullable(),
});

const BrandDealsSetup = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const {
    showBrandDealsSetupModal,
    setBrandDealsSetupDone,
    setShowBrandDealsSetupModal,
  } = useOnboardingContext();

  const { data: userData, refetch } = useGetCurrUser();

  const [editStore] = useMutation<EditStore, EditStoreVariables>(EDIT_STORE);

  const [setBrandDealSettings] = useMutation<
    SetBrandDealSettings,
    SetBrandDealSettingsVariables
  >(SET_BRAND_DEAL_SETTINGS);

  const getValidationSchema = useCallback(() => {
    switch (step) {
      case 1:
        return step1Schema;
      case 2:
        return step2Schema;
      case 3:
        return step3Schema;
      default:
        return yup.object().shape({});
    }
  }, [step]);

  const formMethods = useForm<FormData>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      viewAllDeals: true,
      minimumPrice: null,
      ugcPrice: null,
      streamPrice: null,
      appearancePrice: null,

      sponsoredArticles: true,
      acceptFreeProducts: true,
      restrictedCategories: {
        tobacco: true,
        alcohol: true,
        crypto: true,
        gambling: true,
        'CBD/Cannabis': true,
      },

      gender: '',
      athleticLevel: null,
      birthday: null,
      location: undefined,
      hometown: undefined,
    },
  });

  const { handleSubmit, trigger, getValues, errors } = formMethods;

  const handleClose = useCallback(() => {
    setShowBrandDealsSetupModal(false);
    setStep(1);
  }, [setShowBrandDealsSetupModal]);

  const handleSuccess = useCallback(() => {
    handleClose();
    setBrandDealsSetupDone(true);
    router.push(DASHBOARD);
  }, [handleClose, setBrandDealsSetupDone]);

  const handleNextStepClick = useCallback(() => {
    trigger().then((isStepValid) => {
      if (isStepValid) {
        if (step === 1) {
          setStep(2);
        } else if (step === 2) {
          setStep(3);
        }
      } else {
        console.log('Form has errors:', errors);
      }
    });
  }, [trigger, step, errors]);

  const handlePrevStepClick = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
    }
  }, [step]);

  const processDisabledCategories = useCallback(
    (formData: FormData): string[] => {
      const categoryMapping: Record<string, string> = {
        tobacco: 'Tobacco',
        alcohol: 'Alcohol',
        crypto: 'Crypto',
        gambling: 'Gambling',
        'CBD/Cannabis': 'CBDCannabis',
      };
      const result: string[] = [];

      Object.entries(formData.restrictedCategories || {}).forEach(
        ([key, value]) => {
          const apiCategory = categoryMapping[key];
          if (apiCategory && !value) {
            // If category is disabled (false)
            result.push(apiCategory);
          }
        }
      );

      return result;
    },
    []
  );

  const onSubmit = useCallback(async () => {
    try {
      const allValues = getValues();

      let athleticLevelValue: StoreAthleticLevel | null = null;
      if (
        allValues.athleticLevel &&
        typeof allValues.athleticLevel === 'object'
      ) {
        if (
          Object.values(StoreAthleticLevel).includes(
            allValues.athleticLevel.value as StoreAthleticLevel
          )
        ) {
          athleticLevelValue = allValues.athleticLevel
            .value as StoreAthleticLevel;
        }
      }

      let genderValue: StoreGender | null = null;
      if (allValues.gender && typeof allValues.gender === 'string') {
        if (
          Object.values(StoreGender).includes(allValues.gender as StoreGender)
        ) {
          genderValue = allValues.gender as StoreGender;
        }
      }

      let birthDateString: string | null = null;
      if (allValues.birthday) {
        birthDateString = allValues.birthday;
      }

      const storeDetailsInput = {
        ...(allValues.location && {
          country: allValues.location.countryCode,
          city: allValues.location.city,
          ...(allValues.location.state && {
            state: allValues.location.stateCode,
          }),
          location: allValues.location.coordinates,
        }),
        ...(allValues.hometown && {
          hometownCountry: allValues.hometown.countryCode,
          hometown: allValues.hometown.city,
          ...(allValues.hometown.state && {
            hometownState: allValues.hometown.stateCode,
          }),
          location: allValues.hometown.coordinates,
        }),
        gender: genderValue,
        birthDate: birthDateString,
        athleticLevel: athleticLevelValue,
        leagueId: allValues.league?.id || null,
      };

      await editStore({
        variables: {
          input: {
            storeId: userData?.me?.id,
            storeDetails: storeDetailsInput,
          },
        },
      });

      const disabledCategories = processDisabledCategories(allValues);

      await setBrandDealSettings({
        variables: {
          input: {
            ownerId: userData?.me?.id,
            isAcceptingFreeProducts: allValues.acceptFreeProducts,
            isArticlesPrEnabled: allValues.sponsoredArticles,
            disabledCategories,

            minFlatPrice: allValues.minimumPrice
              ? Number(allValues.minimumPrice)
              : null,
            minSocialPrice: allValues.ugcPrice
              ? Number(allValues.ugcPrice)
              : null,
            minSponsoredLivePrice: allValues.streamPrice
              ? Number(allValues.streamPrice)
              : null,
            minBookMePrice: allValues.appearancePrice
              ? Number(allValues.appearancePrice)
              : null,
          },
        },
      });

      await refetch();

      showToast({
        message: 'Brand deals settings saved successfully!',
        type: 'success',
      });

      handleSuccess();
    } catch (error) {
      showToast({
        type: 'error',
        message:
          error?.message ||
          'Something went wrong while saving your settings. Please try again.',
      });
    }
  }, [
    editStore,
    setBrandDealSettings,
    getValues,
    userData,
    refetch,
    handleSuccess,
    processDisabledCategories,
  ]);

  const footer: FullScreenStepperModalFooter | undefined = useMemo(() => {
    if (step < 3) {
      return {
        stepNumber: step,
        totalStepsNumber: 3,
        showPrevButton: step > 1,
        prevButtonText: 'Back',
        prevButtonProps: {
          onClick: handlePrevStepClick,
          variant: 'secondary',
        },
        submitButtonText: 'Next',
        submitButtonProps: {
          onClick: handleNextStepClick,
          variant: 'primary',
          loading: false,
        },
      };
    }

    return {
      stepNumber: 3,
      totalStepsNumber: 3,
      showPrevButton: true,
      prevButtonText: 'Back',
      prevButtonProps: {
        onClick: handlePrevStepClick,
        variant: 'secondary',
      },
      submitButtonText: 'Submit',
      submitButtonProps: {
        onClick: handleSubmit(onSubmit),
        variant: 'primary',
      },
    };
  }, [step, handlePrevStepClick, handleNextStepClick, handleSubmit, onSubmit]);

  return (
    <FullScreenStepperModal
      title="BRAND DEALS SETTINGS"
      open={showBrandDealsSetupModal}
      onClose={handleClose}
      footer={footer}
    >
      <FormProvider {...formMethods}>
        <form className={styles.form}>
          <BrandDealBudgetStep step={step} />
        </form>
      </FormProvider>
    </FullScreenStepperModal>
  );
};

export default BrandDealsSetup;
