import React, { useRef, ReactNode } from 'react';
import Link from 'next/link';
import cn from 'classnames';
// Components
import CardDescription from '../CardDescription/CardDescription';
// UI
import Image from 'ui3/Image/Image';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import style from './BaseCard.module.scss';
import CardVideo from './components/CardVideo';

export type ContentType = 'interview' | 'stream' | 'image' | 'video';
export type ContentStatus =
  | 'hidden'
  | 'cancelled'
  | 'live'
  | 'upcoming'
  | 'past'
  | 'weekly';
export type CardSize = 'small' | 'medium' | 'large';

export interface BasicBaseCardProps {
  size?: CardSize;
  title?: string;
  user?: string;
  dateTime?: string; // TODO convert this to dayjs and have 2 formats for description and label
  imageUrl: string;
  linkUrl?: string;
  userUrl?: string;
  titleUrl?: string;
  isPlay?: boolean;
  isProductCard?: boolean;
  isArticleCard?: boolean;
  topLabels?: React.ReactNode;
  bottomLabels?: React.ReactNode;
  className?: string;
  descriptionClassName?: string;
  imageWrapperClassname?: string;
  videoAttributes?: {
    videoURL?: string;
    videoId: string;
  };
  priorityImageLoading?: boolean;
  showTitle?: boolean;
  userLineClamp?: number;
  onLinkClick?: (
    e?:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
}

export type BaseCardProps = React.ComponentPropsWithoutRef<'img'> &
  BasicBaseCardProps;

const WithLink = ({
  link,
  onClick,
  children,
}: {
  link?: string;
  onClick?: (
    e?:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
  children: ReactNode;
}) =>
  link ? (
    <Link href={link} prefetch={false}>
      <a
        href={link}
        {...(onClick && { onClick: onClick, onKeyPress: onClick })}
      >
        {children}
      </a>
    </Link>
  ) : (
    <>{children}</>
  );

const BaseCard: React.FC<BaseCardProps> = ({
  size,
  title,
  user,
  dateTime,
  imageUrl,
  linkUrl,
  userUrl,
  titleUrl,
  isPlay,
  isProductCard,
  isArticleCard,
  topLabels,
  bottomLabels,
  width,
  height,
  className,
  descriptionClassName,
  imageWrapperClassname,
  videoAttributes,
  priorityImageLoading,
  showTitle = true,
  userLineClamp = 2,
  onLinkClick,
}) => {
  const imageWrapperRef = useRef<HTMLDivElement | null>(null);
  const sizeClass = size ?? 'large';

  const handleLinkClick = (
    e:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    onLinkClick?.(e);
  };

  return (
    <div
      className={cn(
        'baseCardRoot', // Set specific class name for CarouselSlider
        style.root,
        {
          [style[sizeClass]]: size && !width && !height,
          [style.articleCard]: isArticleCard,
        },
        className
      )}
    >
      <div
        className={cn(style.imageContainer, {
          [style[sizeClass]]: size && !width && !height,
        })}
        style={{ ...(width && { width }), ...(height && { height }) }}
      >
        <WithLink link={linkUrl} onClick={handleLinkClick}>
          <div
            className={cn(
              style.imageWrapper,
              {
                [style.articleCard]: isArticleCard,
              },
              imageWrapperClassname
            )}
            ref={imageWrapperRef}
          >
            {videoAttributes ? (
              <CardVideo
                videoThumbnailURL={imageUrl}
                videoURL={videoAttributes.videoURL}
                videoId={videoAttributes.videoId}
                priorityImageLoading={priorityImageLoading}
                imageWrapperRef={imageWrapperRef}
                hasRedirect={!!linkUrl}
              />
            ) : (
              <Image
                src={imageUrl}
                alt={title}
                priority={priorityImageLoading ?? false}
                sizes="(max-width: 344px) 50vw, (max-width: 344px) 33vw, 20vw"
                placeholder="blur"
              />
            )}
          </div>
        </WithLink>

        {/* Top labels */}
        {topLabels ? (
          <div
            className={cn(style.labelWrapper, {
              [style.productCard]: isProductCard,
              [style[sizeClass]]: size && !width && !height,
            })}
          >
            {topLabels}
          </div>
        ) : null}
        {/* Play icon */}
        {isPlay && (
          <MillionsIcon
            name="playSolid"
            size={48}
            className={style.playIconOverlay}
          />
        )}
        {/* Bottom labels */}
        {bottomLabels ? (
          <div
            className={cn(style.labelWrapper, style.bottom, {
              [style[sizeClass]]: size && !width && !height,
            })}
          >
            {bottomLabels}
          </div>
        ) : null}
      </div>
      <div className={descriptionClassName}>
        <CardDescription
          className={cn(style.cardDescription, {
            [style[sizeClass]]: size && !width && !height,
          })}
          title={title}
          user={user}
          userUrl={userUrl}
          titleUrl={titleUrl}
          date={dateTime}
          size={size}
          showDate={!isProductCard}
          showTitle={showTitle}
          userLineClamp={userLineClamp}
        />
      </div>
    </div>
  );
};

export default BaseCard;
