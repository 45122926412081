import { useState } from 'react';
// Ui
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Modal from 'ui3/Modal/Modal';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './ShareHostCalendar.module.scss';
import Text from 'ui3/Text/Text';

type ShareHostCalendarProps = {
  show: boolean;
  onClose: () => void;
  shareLink: string;
};

const ShareHostCalendar = ({
  shareLink,
  show,
  onClose,
}: ShareHostCalendarProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyButtonText = isCopied ? 'Copied' : 'Copy Link';

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);

      showToast({
        message: 'Copied',
        autoClose: 2000,
      });
      if (!isCopied) {
        setIsCopied(true);
      }
    } catch (error) {
      showToast({
        message: 'Could not copy text',
        type: 'error',
        autoClose: 2000,
      });
    }
  };

  return (
    <Modal size="medium" open={show} onClose={onClose} focusTrapped={false}>
      <div className={styles.modalRoot}>
        <img
          className={styles.image}
          src="/images/calendar.png"
          alt="calendar"
        />

        <div className={styles.texContainer}>
          <Text variant="h4" textAlign="center">
            Share Your Calendar
          </Text>

          <Text
            variant="body1Regular16"
            color="lights-medium"
            textAlign="center"
          >
            Anybody using this link will be able to book an interview with you
            directly
          </Text>
        </div>

        <div className={styles.copyWrapper}>
          <div className={styles.copyTitle}>Your share link</div>
          <div className={styles.buttonWrapper}>
            <button className={styles.copyButton} onClick={handleCopyLink}>
              <div className={styles.linkWrapper}>
                <span className={styles.linkPreview}>{shareLink}</span>
              </div>
              <div className={styles.iconWrapper}>
                <MillionsIcon
                  className={styles.copyButtonIcon}
                  name="copy"
                  withoutFill
                />
                <span className={styles.buttonText}>{copyButtonText}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareHostCalendar;
