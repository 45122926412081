import * as yup from 'yup';

let usernameAvailability = true;

export const getUsernameAvailability = () => usernameAvailability;

export const setUsernameAvailability = (value: boolean) => {
  usernameAvailability = value;
};

yup.addMethod<yup.StringSchema>(yup.string, 'available', function () {
  return this.test({
    name: 'available',
    message: 'Username is already taken',
    test: () => getUsernameAvailability(),
  });
});

declare module 'yup' {
  interface StringSchema {
    available(): yup.StringSchema;
  }
}

export const createUsernameValidation = () => {
  return yup
    .string()
    .matches(
      /^[a-z0-9._-]*$/,
      'Username can only contain lowercase letters, numbers, periods, hyphens, and underscores'
    )
    .available();
};
