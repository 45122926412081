import { useContext } from 'react';
// Context
import {
  OnboardingContext,
  OnboardingContextType,
} from 'context/OnboardingContext';

export const useOnboardingContext = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error(
      'useOnboardingContext must be used within an OnboardingContext Provider'
    );
  }

  return context;
};
