import { gql } from '@apollo/client';

export const CREATE_PRESIGNED_AVATAR_URL = gql`
  mutation CreatePresignedAvatarUrl($input: CreateUserAvatarUrlInput!) {
    createPresignedAvatarUrl(input: $input) {
      fields
      key
      url
    }
  }
`;
