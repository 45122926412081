// Types
import { AddressFields } from 'api/address/types/AddressFields';
import { OptionTypeBase } from 'react-select';
import { GetOrders_getOrders_entities_subOrders } from 'api/merch/types/GetOrders';
import {
  GetCountryList_getCountryList as Country,
  GetCountryList_getCountryList,
} from 'api/address/types/GetCountryList';
import { GetAnyProductSales_getAnyProductSales_entities_order_subOrders } from 'api/merch/types/GetAnyProductSales';
import { GetMyAddresses_getMyAddresses } from 'api/address/types/GetMyAddresses';
import { GetMyCampaignOfferByCampaignId_getMyCampaignOfferByCampaignId_recipientAddress } from 'api/campaignV2/types/GetMyCampaignOfferByCampaignId';

type AllStatesOptions = {
  [key: string]: OptionTypeBase[] | [];
};

export const getAllStatesOptions = (
  countries: Country[] | undefined
): AllStatesOptions[] => {
  const allStatesOptions = [];

  countries?.forEach((country) => {
    allStatesOptions[`${country.code}`] =
      country.states?.map((state) => ({
        value: state.code,
        label: state.name,
      })) || [];
  });

  return allStatesOptions;
};

export const joinStrings = (
  params: (string | number | null | undefined)[]
): string => {
  return params
    .filter((item) => {
      if (typeof item === 'string') {
        return item.trim();
      }

      return !!item;
    })
    .join(', ');
};

export const getCommonAddressString = (
  address:
    | AddressFields
    | GetMyAddresses_getMyAddresses
    | GetOrders_getOrders_entities_subOrders
    | GetAnyProductSales_getAnyProductSales_entities_order_subOrders
    | GetMyCampaignOfferByCampaignId_getMyCampaignOfferByCampaignId_recipientAddress
    | undefined
    | null
): string => {
  return joinStrings([
    address?.addressLine1,
    address?.addressLine2,
    address?.city,
    address?.state,
    address?.zipCode,
    address?.country,
  ]);
};

export const findStateByName = (
  stateName: string,
  data: GetCountryList_getCountryList[]
) =>
  data.reduce(
    (foundState, country) =>
      foundState || country?.states?.find((state) => state.name === stateName),
    null
  );

export const findStateByCode = (
  stateCode: string,
  data: GetCountryList_getCountryList[]
) =>
  data.reduce(
    (foundState, country) =>
      foundState || country?.states?.find((state) => state.code === stateCode),
    null
  );
