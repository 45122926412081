import { useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import dayjs from 'dayjs';
import router from 'next/router';
// Api
import { SCHEDULE_USER_INITIATED_INTERVIEW_ATTEMPT } from 'api/interview/mutations';
import { GET_STORES_BY_HOST_ID } from 'api/interview/queries';
// Types
import {
  CreateUserInitiatedInterview,
  CreateUserInitiatedInterviewVariables,
} from 'api/interview/types/CreateUserInitiatedInterview';
import {
  GetStoresByHostId,
  GetStoresByHostIdVariables,
} from 'api/interview/types/GetStoresByHostId';
// Hooks
import { useGetCurrUser, useOnboardingContext } from 'hooks';
// Constants
import { DASHBOARD } from 'constants/routes';
// Components
import FullScreenStepperModal, {
  FullScreenStepperModalFooter,
} from 'components/common3/FullScreenStepperModal/FullScreenStepperModal';
import { Interviewer } from 'components/UserScheduleInterview/helpers/localTypes';
import { showToast } from 'components/common/Toast/Toast';
import CalendarStep from 'components/UserScheduleInterview/CalendarStep/CalendarStep';
import InterviewScheduledStep from 'components/UserScheduleInterview/InterviewScheduledStep/InterviewScheduledStep';

type ScheduleInterviewFormValues = {
  selectedDate: Date;
  selectedTimeSlot: string;
  selectedUserId?: string;
  description: string;
};

const schemaValidation = yup.object().shape({
  selectedDate: yup.date().required('Please select a date'),
  selectedTimeSlot: yup.string().required('Please select a time slot'),
  selectedUserId: yup.string(),
  description: yup.string(),
});

const CreateInterviewSetup = () => {
  const {
    showCreateInterviewModal,
    setCreateInterviewDone,
    setShowCreateInterviewModal,
  } = useOnboardingContext();

  const { data } = useGetCurrUser();

  const [interviewer, setInterviewer] = useState<Interviewer | null>(null);
  const [step, setStep] = useState(1);

  const methods = useForm<ScheduleInterviewFormValues>({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      selectedDate: dayjs().startOf('day').toDate(),
      selectedTimeSlot: '',
      selectedUserId: '',
      description: '',
    },
    shouldUnregister: false,
  });

  const [
    createUserInitiatedInterview,
    { loading: loadingCreateUserInitiatedInterview },
  ] = useMutation<
    CreateUserInitiatedInterview,
    CreateUserInitiatedInterviewVariables
  >(SCHEDULE_USER_INITIATED_INTERVIEW_ATTEMPT);

  const [getStoresByHostId, { loading: hostLoading }] = useLazyQuery<
    GetStoresByHostId,
    GetStoresByHostIdVariables
  >(GET_STORES_BY_HOST_ID);

  const handleClose = useCallback(() => {
    setShowCreateInterviewModal(false);
  }, [setShowCreateInterviewModal]);

  const handleSuccess = () => {
    setCreateInterviewDone(true);
    handleClose();
    router.push(DASHBOARD);
  };

  const handleSubmit = methods.handleSubmit(async (values) => {
    try {
      if (step === 1) {
        const localTzDate = dayjs(values.selectedTimeSlot);
        const utcDate = localTzDate.tz(dayjs.tz.guess()).utc();
        const formattedUtcDate = utcDate.format();

        // Schedule interview through dashboard case (any available interviewer)
        const { data: hostData } = await getStoresByHostId({
          variables: {
            input: {
              storeIds: [values.selectedUserId as string],
            },
          },
        });

        if (hostData?.stores?.entities?.length) {
          setInterviewer(hostData.stores.entities[0] as Interviewer);
        }

        await createUserInitiatedInterview({
          variables: {
            input: {
              interviewerId: values.selectedUserId as string,
              startDateUtc: formattedUtcDate,
              guestNotes: values.description,
              guestTimezone: dayjs.tz.guess(),
            },
          },
        });

        setStep(2);
      }

      if (step === 2) {
        handleSuccess();
      }
    } catch (error) {
      console.error(error);
      showToast({
        type: 'error',
        message: error?.message || 'An error occurred',
      });
    }
  });

  const loading = loadingCreateUserInitiatedInterview || hostLoading;

  const modalFooter: FullScreenStepperModalFooter = useMemo(() => {
    return {
      showPrevButton: step == 1 ? true : false,
      prevButtonText: 'Back',
      prevButtonProps: {
        variant: 'secondary',
        onClick: handleClose,
        loading,
      },
      submitButtonText: step === 1 ? 'Next' : 'Done',
      submitButtonProps: {
        onClick: handleSubmit,
        variant: 'primary',
      },
    };
  }, [handleClose, handleSubmit, loading, step]);

  return (
    <FullScreenStepperModal
      title="SCHEDULE AN INTERVIEW"
      open={showCreateInterviewModal}
      onClose={handleClose}
      footer={modalFooter}
    >
      <FormProvider {...methods}>
        {step === 1 && (
          <CalendarStep
            interviewerName={
              interviewer
                ? `${interviewer.firstName} ${interviewer.lastName}`
                : ''
            }
            isLoggedIn
            interviewId={null}
            hostId={null}
            loggedUserId={data?.me?.id || ''}
            onInterviewerChange={setInterviewer}
          />
        )}

        {step === 2 && (
          <InterviewScheduledStep interviewer={interviewer as Interviewer} />
        )}
      </FormProvider>
    </FullScreenStepperModal>
  );
};

export default CreateInterviewSetup;
