import { yupResolver } from '@hookform/resolvers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import cn from 'classnames';
import dayjs from 'dayjs';
// Types
import { OptionTypeBase } from 'react-select';
// Hooks
import { useGetScheduleTimeZones } from 'hooks';
// Helpers
import {
  CheckboxState,
  createDateTimeObject,
  Weekdays,
  TimeRanges,
  getTimezoneOptions,
} from '../helpers';
// UI
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Input from 'ui3/Input/Input';
import DatePicker from 'ui3/DatePicker/DatePicker';
import Select from 'ui3/Select/Select';
import Text from 'ui3/Text/Text';
// Styles
import styles from './WeeklyHoursForm.module.scss';

const validationSchema = yup.object().shape({
  minimumNoticeMinutes: yup
    .number()
    .required('Please add number')
    .typeError('Must be a number greater than 0'),
  durationMinutes: yup.object({
    value: yup.string(),
    label: yup.string(),
  }),
});

type FormInputs = {
  minimumNoticeMinutes: number;
  durationMinutes: OptionTypeBase;
};

const DURATION_MINUTES: OptionTypeBase[] = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '60 minutes', value: 60 },
];

type WeeklyHoursFormProps = {
  checkboxState: CheckboxState;
  timeRanges: TimeRanges;
  minimumNotice: number;
  durationMinutes: number;
  selectedTimezone: string;
  setCheckboxState: (value: CheckboxState) => void;
  setTimezone: (value: string) => void;
  setTimeRanges: (value: TimeRanges) => void;
  setMinimumNotice: (value: number) => void;
  setDurationMinutes: (value: number) => void;
};

const WeeklyHoursForm = ({
  selectedTimezone,
  checkboxState,
  timeRanges,
  minimumNotice,
  durationMinutes,
  setCheckboxState,
  setTimeRanges,
  setTimezone,
  setMinimumNotice,
  setDurationMinutes,
}: WeeklyHoursFormProps): JSX.Element => {
  const handleTimeChange = (
    day: keyof CheckboxState,
    index: number,
    value: [dayjs.Dayjs | null, dayjs.Dayjs | null]
  ) => {
    const [from, to] = value || [null, null];
    const newRanges = [...timeRanges[day]];
    newRanges[index] = { from, to };
    setTimeRanges({ ...timeRanges, [day]: newRanges });
  };
  const { data: timeZonesData } = useGetScheduleTimeZones();
  const timezoneOptions = getTimezoneOptions(timeZonesData);

  const formattedTimezoneOptions = timezoneOptions.map((item) => ({
    ...item,
    oldTzName: item.label,
    label: item.label.replace('_', ' '),
  }));

  const defaultValueTz = formattedTimezoneOptions.find((option) => {
    if (option.oldTzName === selectedTimezone) {
      return option.label;
    }
  });

  const handleAddTimeRange = (day: keyof CheckboxState) => {
    setTimeRanges({
      ...timeRanges,
      [day]: [...timeRanges[day], { from: null, to: null }],
    });
  };

  const handleRemoveTimeRange = (day: keyof CheckboxState, index: number) => {
    setTimeRanges({
      ...timeRanges,
      [day]: timeRanges[day].filter((_, i) => i !== index),
    });
  };

  const handleTimezoneChange = (e) => {
    const newTimezone = e.oldTzName;
    setTimezone(newTimezone);
  };

  const handleSetCheckboxesState = (
    day: keyof CheckboxState,
    value: boolean
  ) => {
    setCheckboxState({
      ...checkboxState,
      [day]: value,
    });
  };

  const durationMinutesDefaultValue = DURATION_MINUTES.find(
    (option) => Number(option.value) === durationMinutes
  );

  const { register, errors: formErrors, setValue } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (durationMinutesDefaultValue) {
      setValue('durationMinutes', durationMinutesDefaultValue);
    }
  }, [durationMinutesDefaultValue, setValue]);

  useEffect(() => {
    if (minimumNotice) {
      setValue('minimumNoticeMinutes', minimumNotice);
    }
  }, [minimumNotice, setValue]);

  const handleDurationChange = (e) => {
    setDurationMinutes(e.value);
  };

  return (
    <div className={styles.root}>
      <Text variant="subtitle1">Weekly Hours</Text>

      <form className={styles.formWrapper}>
        <Input
          label="Minimum notice in minutes"
          name="minimumNoticeMinutes"
          id="minimumNoticeMinutes"
          ref={register}
          type="number"
          error={formErrors?.minimumNoticeMinutes?.message}
          onChange={(e) => setMinimumNotice(Number(e.target.value))}
        />

        <Select
          label="Duration in minutes"
          labelClassName={styles.labelStyle}
          placeholder="Select interview duration"
          options={DURATION_MINUTES}
          value={durationMinutesDefaultValue}
          id="durationMinutes"
          name="durationMinutes"
          inputId="durationMinutes"
          fieldSize="large"
          onChange={(e) => handleDurationChange(e)}
        />

        <Select
          id="timeZone"
          name="timeZone"
          inputId="timeZone"
          label="Time Zone"
          value={defaultValueTz}
          className={styles.timeZoneSelect}
          options={formattedTimezoneOptions}
          onChange={handleTimezoneChange}
        />
      </form>

      <div className={styles.weekly}>
        <ul className={styles.daysCheckbox}>
          {(Object.keys(checkboxState) as Weekdays[]).map((day) => {
            return (
              <li key={'key_' + day}>
                {/* used regular button to evade unnecessary css that comes with premade ones */}
                <button
                  className={cn(styles.checkbox, {
                    [styles.checkedCheckbox]: checkboxState[day],
                  })}
                  name={'day_' + day}
                  color="god-grey"
                  onClick={() => {
                    const newState = !checkboxState[day];
                    handleSetCheckboxesState(day, newState);
                  }}
                >
                  {day.charAt(0).toUpperCase()}
                </button>
              </li>
            );
          })}
        </ul>

        {(Object.keys(checkboxState) as Weekdays[]).map((day) => {
          const lastItem = timeRanges[day].length - 1;

          return (
            <div key={day} className={styles.day}>
              {timeRanges[day].map((range, index) => {
                if (checkboxState[day]) {
                  const timeFrom = createDateTimeObject(range.from);
                  const minTime = timeFrom
                    ? dayjs(dayjs(timeFrom).add(15, 'minutes')).toDate()
                    : undefined;

                  const maxTime = dayjs().endOf('day').toDate();

                  return (
                    <div
                      key={'day_' + index}
                      className={styles.timeRangeWrapper}
                    >
                      <p className={styles.dayName}>
                        {index === 0
                          ? day.charAt(0).toUpperCase() + day.slice(1)
                          : ''}
                      </p>
                      <div className={styles.timeRange}>
                        <DatePicker
                          className={styles.timeInput}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={timeFrom}
                          onChange={(e) =>
                            handleTimeChange(day, index, [
                              dayjs(e as Date),
                              range.to,
                            ])
                          }
                          placeholderText="Start time"
                          customInput={
                            <Input
                              className={styles.timeInput}
                              name="fromInput"
                            />
                          }
                        />
                        <p className={styles.timeSeparator}>to</p>

                        <DatePicker
                          className={styles.timeInput}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={createDateTimeObject(range.to)}
                          onChange={(e) =>
                            handleTimeChange(day, index, [
                              range.from,
                              dayjs(e as Date),
                            ])
                          }
                          placeholderText="End time"
                          minTime={minTime}
                          maxTime={maxTime}
                          disabled={!(minTime && maxTime)}
                          customInput={
                            <Input
                              className={styles.timeInput}
                              name="toInput"
                            />
                          }
                        />
                      </div>
                      <div className={styles.iconWrapper}>
                        {index === lastItem && (
                          <MillionsIcon
                            className={styles.icon}
                            name="plusCircleOutline"
                            onClick={() => handleAddTimeRange(day)}
                          />
                        )}
                        {index > 0 && (
                          <MillionsIcon
                            className={styles.icon}
                            name="minusCircle"
                            onClick={() => handleRemoveTimeRange(day, index)}
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeeklyHoursForm;
